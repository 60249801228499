<template>
  <menu class="Toolbar" :class="{ 'Toolbar-centered': isSubmitted }">
    <template v-if="!isSubmitted">
      <li class="ToolbarItem">
        <BaseAction color="textSecondary" size="lg" @click="$emit('cancel')">
          {{ $t('actionText.cancel') }}
        </BaseAction>
      </li>
      <li class="ToolbarItem">
        <BaseAction type="submit" color="primary" size="lg" :form="formId" :disabled="!hasFeedback">
          {{ $t('actionText.send') }}
        </BaseAction>
      </li>
    </template>
    <li v-else class="ToolbarItem">
      <BaseAction color="secondary" size="lg" @click="$emit('close')">
        {{ $t('actionText.close') }}
      </BaseAction>
    </li>
  </menu>
</template>

<script setup lang="ts">
export type Props = {
  hasFeedback?: boolean;
  isSubmitted?: boolean;
  formId: string;
};

defineProps<Props>();

defineEmits<{
  close: [];
  cancel: [];
}>();
</script>

<style scoped lang="scss">
.Toolbar {
  &-centered {
    justify-content: center;
  }
}
</style>

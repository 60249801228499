import type { ILogObj } from 'tslog';

import { Logger } from 'tslog';

const consoleLogger = new Logger<ILogObj>({
  type: import.meta.env.VITE_LOGGER_ENABLED === 'true' ? 'pretty' : 'hidden',
  stylePrettyLogs: true,
  hideLogPositionForProduction: import.meta.env.VITE_LOGGER_ENABLED !== 'true',
  prettyLogTemplate: '{{dateIsoStr}}\t{{logLevelName}}\t',
  prettyErrorTemplate: '\n{{errorName}} {{errorMessage}}\nerror stack:\n{{errorStack}}',
  prettyErrorStackTemplate: '  • {{fileNameWithLine}}\t{{method}}\n\t{{fullFilePath}}',
});

export default consoleLogger;

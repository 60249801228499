/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HeaderExportResponseV0
 */
export interface HeaderExportResponseV0 {
    /**
     * Number of rows in the CSV file.
     * @type {number}
     * @memberof HeaderExportResponseV0
     */
    rowCount: number;
    /**
     * 
     * @type {number}
     * @memberof HeaderExportResponseV0
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof HeaderExportResponseV0
     */
    url: string;
}

/**
 * Check if a given object implements the HeaderExportResponseV0 interface.
 */
export function instanceOfHeaderExportResponseV0(value: object): value is HeaderExportResponseV0 {
    if (!('rowCount' in value) || value['rowCount'] === undefined) return false;
    if (!('size' in value) || value['size'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    return true;
}

export function HeaderExportResponseV0FromJSON(json: any): HeaderExportResponseV0 {
    return HeaderExportResponseV0FromJSONTyped(json, false);
}

export function HeaderExportResponseV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): HeaderExportResponseV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'rowCount': json['row_count'],
        'size': json['size'],
        'url': json['url'],
    };
}

export function HeaderExportResponseV0ToJSON(json: any): HeaderExportResponseV0 {
    return HeaderExportResponseV0ToJSONTyped(json, false);
}

export function HeaderExportResponseV0ToJSONTyped(value?: HeaderExportResponseV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'row_count': value['rowCount'],
        'size': value['size'],
        'url': value['url'],
    };
}


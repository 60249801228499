export interface UsageState {
  usage: number;
  maxUsage: number;
  isUsageActive: boolean;
}

export const useUsageStore = defineStore('usage', {
  state: (): UsageState => ({
    usage: 253570,
    maxUsage: 500000,
    isUsageActive: false,
  }),

  getters: {
    usageStatus(): 'warning' | 'critical' | 'ok' {
      if (this.usage >= this.maxUsage * 0.9) {
        return 'critical';
      } else if (this.usage >= this.maxUsage * 0.5) {
        return 'warning';
      } else {
        return 'ok';
      }
    },
  },

  actions: {
    initWithLocalStorage() {
      const localUsageStatus = localStorage.getItem('usage-status') ?? 'ok';
      const usageWarningOpenValue = localStorage.getItem('is-usage-warning-open') ?? 'false';

      this.isUsageActive =
        usageWarningOpenValue === 'true' ||
        (localUsageStatus !== this.usageStatus &&
          (this.usageStatus === 'warning' || this.usageStatus === 'critical'))
          ? true
          : false;

      logger.info(this.usageStatus);

      localStorage.setItem('usage-status', this.usageStatus);
      localStorage.setItem('is-usage-warning-open', this.isUsageActive ? 'true' : 'false');
    },

    toggleUsageBar(): void {
      this.isUsageActive = !this.isUsageActive;
      localStorage.setItem('is-usage-warning-open', this.isUsageActive ? 'true' : 'false');
    },
  },
});

export default useUsageStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUsageStore, import.meta.hot));
}

import type { ConfigurationParameters, UserInfoV0 } from '@/api';
import type { NobitaLocale } from '@/locales';

import { Permission } from '@/api';

export type TenantStore = ReturnType<typeof useTenantStore>;

const { setHtmlLang } = useNobitaHead();
const { stored_locale, stored_loginLocale, stored_accessToken } = useNobitaLocalStorage();
const { updateUserInfo } = useAuthHelper();

const authorizedPages = {
  // Email Archive:
  archiveSearch: false,
  archiveStatistics: false,
  searchHistory: false,
  archiveSettings: false,
  bulkDownload: false,

  // Settings:
  userSettings: false,
  searchPolicySettings: false,
  consoleSettings: false,
  operationLog: false,

  // Other:
  emailView: false,
};

const authorizedActions = {
  // Archive Search
  searchArchive: false,
  headerDownload: false,
  downloadSelection: false,
  downloadBulk: false,
  downloadSingle: false,
  downloadAttachment: false,
  previewAttachment: false,
  defrostSingle: false,
  viewReadableOriginal: false,
  openMailInTab: false,

  // Archive Statistics
  viewPeriodSettings: false,

  // Archive Period Settings
  viewStatistics: false,

  // User Settings
  viewUsers: false,
  createUsers: false,
  editUsers: false,
  deleteUsers: false,
  editSelf: false,

  // General Settings
  viewSettings: false,
  editSettings: false,

  // Search Policy Settings
  viewAccessPolicies: false,
  viewSearchPolicies: false,
  viewSearchPolicyDetails: false,
  editSearchPolicies: false,
  createSearchPolicies: false,
  deleteSearchPolicies: false,
  fetchSearchPolicyUsers: false,

  // Operation Log
  viewOperationLog: false,
  downloadOperationLog: false,
};

// Store definition

type TenantStoreType = Omit<UserInfoV0, 'enabledFeatures' | 'permissions'> & {
  // API spec overrides
  enabledFeatures: Set<FEATURE_FLAG>;
  permissions: Set<Permission>;

  // others
  _locale: NobitaLocale | null;
  isLoggedIn: boolean;
  authorizedPages: typeof authorizedPages;
  authorizedActions: typeof authorizedActions;
};

export const useTenantStore = defineStore('tenant', () => {
  const state = reactive<TenantStoreType>({
    // members of UserInfoV0
    email: '',
    expiry: new Date(0),
    enabledFeatures: new Set<FEATURE_FLAG>(),
    id: '',
    ipAddress: '',
    loginDomainName: '',
    permissions: new Set<Permission>(),
    searchFilter: false,
    headerExportLimit: 0,
    timezone: 'Asia/Tokyo',

    // Additional
    _locale: null,
    isLoggedIn: false,
    authorizedPages,
    authorizedActions,
  });

  const initAuthorizedActions = () => {
    const actions = state.authorizedActions;

    // Archive Search
    actions.searchArchive = state.permissions.has(Permission.Archivemailsearch);
    actions.headerDownload =
      state.permissions.has(Permission.ArchivemailexportHeaders) &&
      state.enabledFeatures.has(FEATURE_FLAG.search_result_csv_export);
    actions.downloadSingle = state.permissions.has(Permission.ArchivemaildownloadRaw);
    actions.viewReadableOriginal = state.permissions.has(Permission.ArchivemaildownloadReadable);
    actions.downloadSelection =
      state.permissions.has(Permission.ArchivemaildownloadCsv) ||
      state.permissions.has(Permission.ArchivemaildownloadMbox) ||
      state.permissions.has(Permission.ArchivemaildownloadPst);
    actions.downloadBulk = false; // not implemented in backend yet
    actions.downloadAttachment = state.permissions.has(Permission.Archivemailattachmentdownload);
    actions.previewAttachment = state.permissions.has(Permission.Archivemailattachmentpreview);
    actions.defrostSingle = state.permissions.has(Permission.Archivemaildefrost);
    actions.openMailInTab =
      state.permissions.has(Permission.ArchivemailgetDetails) &&
      state.permissions.has(Permission.ArchivemailgetHeaders);

    // Archive Statistics
    actions.viewStatistics = state.permissions.has(Permission.ArchivestatsgetRecent);

    // Archive Period Settings
    actions.viewPeriodSettings = state.permissions.has(Permission.Archivesettingsget);

    // User Settings
    actions.viewUsers = state.permissions.has(Permission.UsersgetAll);
    actions.editUsers = state.permissions.has(Permission.Usersupdate);
    actions.createUsers = state.permissions.has(Permission.Userscreate);
    actions.deleteUsers = state.permissions.has(Permission.Usersdelete);
    actions.editSelf = state.permissions.has(Permission.UsersupdateSelf);

    // General Settings
    actions.viewSettings = state.permissions.has(Permission.Domainget);
    actions.editSettings = state.permissions.has(Permission.Domainupdate);

    actions.viewAccessPolicies = state.permissions.has(Permission.AccessPoliciesgetAll);

    // Search Policy Settings
    actions.viewSearchPolicies = state.permissions.has(Permission.SearchPoliciesgetAll);
    actions.viewSearchPolicyDetails = state.permissions.has(Permission.SearchPoliciesget);
    actions.editSearchPolicies = state.permissions.has(Permission.SearchPoliciesupdate);
    actions.createSearchPolicies = state.permissions.has(Permission.SearchPoliciescreate);
    actions.deleteSearchPolicies = state.permissions.has(Permission.SearchPoliciesdelete);
    actions.fetchSearchPolicyUsers =
      state.permissions.has(Permission.SearchPoliciesget) &&
      state.permissions.has(Permission.Usersget);
    //what about SearchPoliciesget: 'search-policies:get'?

    // Operation Log
    actions.viewOperationLog = state.permissions.has(Permission.OperationLogsgetAll);
    actions.downloadOperationLog = state.permissions.has(Permission.OperationLogsdownload);

    state.authorizedActions = actions;
  };

  const initAuthorizedPages = () => {
    const pages = state.authorizedPages;

    pages.archiveSearch = state.permissions.has(Permission.Archivemailsearch);
    pages.archiveStatistics = state.permissions.has(Permission.ArchivestatsgetRecent);

    pages.searchHistory =
      state.permissions.has(Permission.Archivemailsearchhistory) &&
      state.permissions.has(Permission.Archivemailsearch);

    pages.bulkDownload = false; // Always hidden for now: Awaiting spec - phase 5
    pages.archiveSettings = state.permissions.has(Permission.Archivesettingsget);
    pages.consoleSettings = state.permissions.has(Permission.Domainget);
    pages.userSettings =
      state.permissions.has(Permission.UsersgetAll) &&
      state.permissions.has(Permission.SearchPoliciesgetAll) &&
      state.permissions.has(Permission.AccessPoliciesgetAll); // needs to be able to fetch all the search policies and access policies to render this page
    pages.searchPolicySettings =
      state.permissions.has(Permission.SearchPoliciesgetAll) &&
      state.permissions.has(Permission.SearchPoliciesget); // needs 'get' to be able to click rows
    pages.operationLog = state.permissions.has(Permission.OperationLogsgetAll);
    pages.emailView =
      state.permissions.has(Permission.ArchivemailgetDetails) &&
      state.permissions.has(Permission.ArchivemailgetHeaders);

    state.authorizedPages = pages;
  };

  const initEnabledFeatures = (features: string[]) => {
    for (const maybeFeature of features) {
      if (isFeatureFlag(maybeFeature)) {
        state.enabledFeatures.add(maybeFeature);
      }
    }
  };

  const getLoginLocale = () =>
    isValidLocale(stored_loginLocale.value) ? stored_loginLocale.value : null;

  const setUILocale = (newLocale: NobitaLocale) => {
    // save locally in this store
    state._locale = newLocale;
    // set locale in vue-i18n
    i18n.global.locale.value = newLocale;
    // set locale in HTML lang attribute
    setHtmlLang(newLocale);
  };

  const saveLocaleToDatabase = async (newLocale: NobitaLocale) => {
    if (stored_accessToken.value) {
      // save locale to database
      await updateUserInfo({ locale: newLocale });
    }
  };

  // getters
  const apiConfigParameters = computed<ConfigurationParameters>(() => {
    const { stored_accessToken } = useNobitaLocalStorage();

    const params: ConfigurationParameters = buildBaseApiConfigParameters(stored_accessToken.value);

    // reference the state of the tenant so this config is tied to the logged in tenant since we are referencing the token above.
    logger.info(`Caching api config parameters for ${state.id}`);

    return params;
  });

  const locale = computed<NobitaLocale>(
    () =>
      // if the locale is set here in the store, return that first
      state._locale ??
      // if not, check for a stored language setting in LocalStorage
      normalizeLocale(stored_locale.value) ??
      // otherwise, try negotiating through the accept-language header values
      navigator.languages.map(normalizeLocale).find((locale) => locale !== null) ??
      // otherwise fallback to American English
      'en-US',
  );

  const initLocale = async (backendLocale: string | null | undefined) => {
    const loginLocale = getLoginLocale();
    if (loginLocale) {
      // If we have a pending locale change from login page, set the UI to that locale
      setUILocale(loginLocale);
      // and make sure to save it to local storage and database
      if (backendLocale !== loginLocale) {
        stored_locale.value = loginLocale;
        try {
          await saveLocaleToDatabase(loginLocale);
        } catch (error) {
          logger.error('Failed to save locale', error);
        }
      }
    } else if (backendLocale && isValidLocale(backendLocale)) {
      // If there's saved locale in the database, set the UI to that locale
      setUILocale(backendLocale);
      // and save to local storage
      stored_locale.value = backendLocale;
    } else {
      // If locale is null from the backend
      if (backendLocale === null || backendLocale === undefined) {
        state._locale = null;
        stored_locale.value = null;
      }

      // Fallback to auto-negotiation
      setUILocale(locale.value);
    }

    // Clear any pending locale change from login page
    stored_loginLocale.value = null;
  };

  return {
    ...toRefs(state),

    // getters
    apiConfigParameters,

    // actions
    setLoggedInStatus(status: boolean) {
      state.isLoggedIn = status;
    },

    updateTimezone(timezone: string) {
      state.timezone = timezone;
    },

    async setTenant(tenant: UserInfoV0) {
      state.email = tenant.email;
      state.expiry = tenant.expiry;
      state.id = tenant.id;
      state.ipAddress = tenant.ipAddress;
      state.loginDomainName = tenant.loginDomainName;
      state.permissions = new Set(tenant.permissions);
      state.searchFilter = tenant.searchFilter;
      state.timezone = tenant.timezone;

      const localePromise = initLocale(tenant.locale);
      initEnabledFeatures(tenant.enabledFeatures ?? []);
      initAuthorizedActions();
      initAuthorizedPages();

      state.isLoggedIn = true;
      await localePromise;
    },

    locale,
    setUILocale,
    async setLocale(newLocale: NobitaLocale, routeName?: string) {
      // set UI locale
      setUILocale(newLocale);

      // save locale in local storage
      stored_locale.value = newLocale;
      if (routeName === 'Login') {
        stored_loginLocale.value = newLocale;
      } else {
        // and in the database
        try {
          await saveLocaleToDatabase(newLocale);
        } catch (error) {
          logger.error('Failed to save locale', error);
        }
      }
    },
  };
});

export default useTenantStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTenantStore, import.meta.hot));
}

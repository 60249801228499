/* eslint-disable @typescript-eslint/no-explicit-any */

import type { ApiModuleName, ApiStore } from '@/store/api';
import type { ComputedRef, Ref, ShallowRef } from 'vue';

import { useApiStore } from '@/store/api';

import { ErrorResponsePayloadV0FromJSON, JSONApiResponse, ResponseError } from '@/api';
import { NobitaError } from '@/errors';

/**
 * A decorative wrapper function to empower an API endpoint with state management and error handling tools.
 *
 * **Usage Example**
 * ```ts
 * const { invoke, reset } = useWrappedApi('usersApi', 'createUser');
 *
 * reset();
 * const resolution = invoke();
 *
 * if (resolution.success) {
 *   resolution.result;
 * } else {
 *   resolution.error;
 * }
 * ```
 *
 * @param apiModuleName The name of the API module, equivalent to the name of an API derived class, e.g. `accessPoliciesApi`
 * @param apiEndpointName The name of an async fn endpoint that exists within the API object specified by `apiModuleName`
 * @returns A readonly set of reactive values that handle the async function execution, its state, and any errors that may arise.
 */
export function useWrappedApi<
  ModuleName extends ApiModuleName,
  EndpointName extends ApiMethodNames<ApiStore[ModuleName]>,
  Endpoint extends (...args: any) => any = ApiStore[ModuleName][EndpointName] extends (
    ...args: any
  ) => any
    ? ApiStore[ModuleName][EndpointName]
    : never,
  Response extends Awaited<ReturnType<Endpoint>> = Awaited<ReturnType<Endpoint>>,
>(
  apiModuleName: ModuleName,
  apiEndpointName: EndpointName,
): WrappedAPI<ModuleName, EndpointName, Endpoint> {
  // State
  const result = shallowRef<Response | undefined>(undefined);
  const error = shallowRef<Error | NobitaError | undefined>(undefined);
  const status = ref<ApiStatus>(ApiStatus.NOT_EXECUTED);

  function reset() {
    // Reset to Unexecuted state
    status.value = ApiStatus.NOT_EXECUTED;
    error.value = undefined;
    result.value = undefined;
  }

  const api = useApiStore();
  const module = api[apiModuleName];
  const method = module[apiEndpointName];

  if (typeof method !== 'function')
    throw Error(`API endpoint ${apiModuleName}.${String(apiEndpointName)} is not a function.`);

  const invoke = async (...args: Parameters<Endpoint>): Promise<ExecutedAPIResult<Response>> => {
    if (status.value === ApiStatus.NOT_EXECUTED) {
      // Set to Processing state
      status.value = ApiStatus.PROCESSING;

      try {
        const response: Response = await method.apply(module, args);

        // Set to Success state
        status.value = ApiStatus.SUCCESS;
        result.value = response;

        return shallowReadonly({ success: true, result: response } as const);
      } catch (err: unknown) {
        const newError = await handleError(err, method);

        // Set to Failure state
        status.value = ApiStatus.FAILURE;
        error.value = newError;

        return shallowReadonly({ success: false, error: newError } as const);
      }
    } else throw new Error('API has already been invoked.');
  };

  return {
    // state
    result: shallowReadonly(result),
    error: shallowReadonly(error),
    status: readonly(status),

    // computeds
    processing: computed<boolean>(() => status.value === ApiStatus.PROCESSING),
    succeeded: computed<boolean>(() => status.value === ApiStatus.SUCCESS),
    failed: computed<boolean>(() => status.value === ApiStatus.FAILURE),

    // functions
    invoke,
    reset,
  } satisfies WrappedAPI<ModuleName, EndpointName, Endpoint>;
}

// Helper Functions

export async function handleError(
  err: unknown,
  method: CallableFunction | string,
): Promise<Error | NobitaError> {
  try {
    if (isResponseError(err)) {
      const parsedResponseError = await new JSONApiResponse(err.response, (jsonValue) =>
        ErrorResponsePayloadV0FromJSON(jsonValue),
      ).value();

      // This function could throw if ResponseError is not convertable to a valid NobitaError
      // e.g. if it has an invalid Nobita Error Code
      const nobitaError = NobitaError.createFromErrorInfoObj(parsedResponseError.error, err);

      logger.info(
        `Nobita error caught from request ${typeof method === 'string' ? method : method.name}`,
        nobitaError.toJSON(),
      );

      return nobitaError;
    } else throw err;
  } catch (caught: unknown) {
    // It is a standard error or another unknown type of error
    const error = caught instanceof Error ? caught : new Error(String(caught));

    logger.error(
      `Non-Nobita error logged in request ${typeof method === 'string' ? method : method.name}`,
      error,
    );

    return error;
  }
}

function isResponseError(err: unknown): err is ResponseError {
  return err instanceof ResponseError;
}

// Types

export enum ApiStatus {
  NOT_EXECUTED = 'not_executed',
  PROCESSING = 'processing',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export type ExecutedAPIResult<Response> =
  | { success: true; result: Response }
  | { success: false; error: NobitaError | Error };

export type ApiMethodNames<T> = {
  [K in keyof T]: T[K] extends CallableFunction
    ? // Exclude meta methods:
      K extends 'withMiddleware' | 'withPreMiddleware' | 'withPostMiddleware'
      ? never
      : K
    : never;
}[keyof T];

export type WrappedAPI<
  ModuleName extends ApiModuleName,
  EndpointName extends ApiMethodNames<ApiStore[ModuleName]>,
  Endpoint extends (...args: any) => any = ApiStore[ModuleName][EndpointName] extends (
    ...args: any
  ) => any
    ? ApiStore[ModuleName][EndpointName]
    : never,
  Response extends Awaited<ReturnType<Endpoint>> = Awaited<ReturnType<Endpoint>>,
> = {
  /**
   * Invoke the wrapped async function with its original parameters.
   *
   * When invoked, the status of the wrapper will change to `ApiStatus.PROCESSING`.
   * After awaiting the invocation promise, the status will only be either `ApiStatus.SUCCESS` or `ApiStatus.FAILED`.
   *
   * @throws Does not throw on API errors. Instead, API errors are caught and the status is assigned to `ApiStatus.FAILED`.
   *
   * This **will** throw if invoke is called while the wrapper is not in a ready state for invocation (NOT_EXECUTED).
   * E.g. if invoke has already been called once, and the wrapper has not been reset after.
   */
  invoke: (...args: Parameters<Endpoint>) => Promise<ExecutedAPIResult<Response>>;

  /**
   * Resets the state of this API wrapper to align with `ApiStatus.NOT_EXECUTED`, ready to be invoked again.
   */
  reset: () => void;

  /**
   * The status of this wrapped API endpoint handler. Possible statuses are:
   * - `not_executed` - The API has not been invoked yet.
   * - `processing` - The API is currently being invoked.
   * - `success` - The API has been successfully invoked and has returned a result, which will be available on the `result` property.
   * - `failure` - The API has been invoked and has thrown an error, which will be available on the `error` property.
   */
  status: Ref<ApiStatus>;

  /**
   * The result of the API invocation, if any. If the API invocation has not been successful, this will be `undefined`.
   */
  result: ShallowRef<Response | undefined>;

  /**
   * The error thrown by the API invocation, if any. If the API invocation was successful, this will be `undefined`.
   */
  error: ShallowRef<Error | NobitaError | undefined>;

  /**
   * True when `status === "processing"`.
   */
  processing: ComputedRef<boolean>;

  /**
   * True when `status === "success"`.
   */
  succeeded: ComputedRef<boolean>;

  /**
   * True when `status === "failure"`.
   */
  failed: ComputedRef<boolean>;
};

/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessPolicyV0 } from './AccessPolicyV0';
import {
    AccessPolicyV0FromJSON,
    AccessPolicyV0FromJSONTyped,
    AccessPolicyV0ToJSON,
    AccessPolicyV0ToJSONTyped,
} from './AccessPolicyV0';

/**
 * Used when creating User.
 * @export
 * @interface UserCreateV0
 */
export interface UserCreateV0 {
    /**
     * 
     * @type {AccessPolicyV0}
     * @memberof UserCreateV0
     */
    accessPolicy?: AccessPolicyV0;
    /**
     * 
     * @type {string}
     * @memberof UserCreateV0
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateV0
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateV0
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCreateV0
     */
    locale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateV0
     */
    searchPolicyId?: string | null;
}

/**
 * Check if a given object implements the UserCreateV0 interface.
 */
export function instanceOfUserCreateV0(value: object): value is UserCreateV0 {
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function UserCreateV0FromJSON(json: any): UserCreateV0 {
    return UserCreateV0FromJSONTyped(json, false);
}

export function UserCreateV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCreateV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'accessPolicy': json['access_policy'] == null ? undefined : AccessPolicyV0FromJSON(json['access_policy']),
        'description': json['description'] == null ? undefined : json['description'],
        'email': json['email'],
        'enabled': json['enabled'] == null ? undefined : json['enabled'],
        'locale': json['locale'] == null ? undefined : json['locale'],
        'searchPolicyId': json['search_policy_id'] == null ? undefined : json['search_policy_id'],
    };
}

export function UserCreateV0ToJSON(json: any): UserCreateV0 {
    return UserCreateV0ToJSONTyped(json, false);
}

export function UserCreateV0ToJSONTyped(value?: UserCreateV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'access_policy': AccessPolicyV0ToJSON(value['accessPolicy']),
        'description': value['description'],
        'email': value['email'],
        'enabled': value['enabled'],
        'locale': value['locale'],
        'search_policy_id': value['searchPolicyId'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DomainUpdateV0,
  DomainV0,
  ErrorResponsePayloadV0,
} from '../models/index';
import {
    DomainUpdateV0FromJSON,
    DomainUpdateV0ToJSON,
    DomainV0FromJSON,
    DomainV0ToJSON,
    ErrorResponsePayloadV0FromJSON,
    ErrorResponsePayloadV0ToJSON,
} from '../models/index';

export interface UpdateDomainRequest {
    domainUpdateV0: DomainUpdateV0;
}

/**
 * 
 */
export class DomainApi extends runtime.BaseAPI {

    /**
     * This will return the domain settings for the current user  The user requires the permission `domain:get` to be able to call this endpoint.  Error Codes:  - N/A
     * Get user\'s domain settings
     */
    async getDomainRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DomainV0>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/domain`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DomainV0FromJSON(jsonValue));
    }

    /**
     * This will return the domain settings for the current user  The user requires the permission `domain:get` to be able to call this endpoint.  Error Codes:  - N/A
     * Get user\'s domain settings
     */
    async getDomain(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DomainV0> {
        const response = await this.getDomainRaw(initOverrides);
        return await response.value();
    }

    /**
     * This will update the domain settings for the current user  The user requires the permission `domain:update` to be able to call this endpoint.  Error Codes:  - HTTP 422    - 1002 => nothing to update.
     * Update user\'s domain settings
     */
    async updateDomainRaw(requestParameters: UpdateDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DomainV0>> {
        if (requestParameters['domainUpdateV0'] == null) {
            throw new runtime.RequiredError(
                'domainUpdateV0',
                'Required parameter "domainUpdateV0" was null or undefined when calling updateDomain().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/domain`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DomainUpdateV0ToJSON(requestParameters['domainUpdateV0']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DomainV0FromJSON(jsonValue));
    }

    /**
     * This will update the domain settings for the current user  The user requires the permission `domain:update` to be able to call this endpoint.  Error Codes:  - HTTP 422    - 1002 => nothing to update.
     * Update user\'s domain settings
     */
    async updateDomain(requestParameters: UpdateDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DomainV0> {
        const response = await this.updateDomainRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

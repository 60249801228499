// CONSTANTS
// This must be in its own subfolder in order to be auto-imported.

export const MAX_CONDITIONS = 9;
export const MAX_EMAIL_SELECTION = 200;
export const MAX_DOWNLOAD_FILE_SIZE = 104857600;

export const EMPTY_DASH = '\u2014';

// Maximum string lengths
export const DESCRIPTION_MAX_LENGTH = 200;
export const POLICY_NAME_MAX_LENGTH = 100;

// Transition speeds (ms)
export const TRANSITION_MS_SLOW = 500;
export const TRANSITION_MS_MEDIUM = 300;
export const TRANSITION_MS = 200;

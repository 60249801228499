<template>
  <i class="BaseIcon" :class="`BaseIcon-${size}`">
    <slot />
  </i>
</template>

<script setup lang="ts">
export type Props = {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

withDefaults(defineProps<Props>(), { size: 'md' });

defineSlots<{
  default(): void;
}>();
</script>

<style scoped lang="scss">
.BaseIcon {
  display: flex;

  // size
  &-xs {
    width: 16px;
    height: 16px;
  }
  &-sm {
    width: 20px;
    height: 20px;
  }
  &-md {
    width: 24px;
    height: 24px;
  }
  &-lg {
    width: 32px;
    height: 32px;
  }
  &-xl {
    width: 40px;
    height: 40px;
  }
}
</style>

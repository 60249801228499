/**
 * Feedback helper
 *
 * @desc sends user's feedback to the feedback-api
 */

import type { Feedback } from '@/types/Feedback';

export function useFeedbackHelper() {
  const { stored_accessToken } = useNobitaLocalStorage();

  const sendFeedback = async (params: Feedback) => {
    const r = await fetch(`${import.meta.env.VITE_APP_FEEDBACK_API_PATH}/v1/feedback/`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${stored_accessToken.value}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    if (!r.ok) throw new Error('Failed to send feedback');
    const res = await r.json();
    logger.info('Feedback repsonse', res);
    return;
  };

  return {
    sendFeedback,
  };
}

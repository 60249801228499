/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessPolicyOperationLogOptionV0
 */
export interface AccessPolicyOperationLogOptionV0 {
    /**
     * 
     * @type {string}
     * @memberof AccessPolicyOperationLogOptionV0
     */
    id: AccessPolicyOperationLogOptionV0IdEnum;
    /**
     * 
     * @type {string}
     * @memberof AccessPolicyOperationLogOptionV0
     */
    value: AccessPolicyOperationLogOptionV0ValueEnum;
}


/**
 * @export
 */
export const AccessPolicyOperationLogOptionV0IdEnum = {
    OperationLog: 'operation_log'
} as const;
export type AccessPolicyOperationLogOptionV0IdEnum = typeof AccessPolicyOperationLogOptionV0IdEnum[keyof typeof AccessPolicyOperationLogOptionV0IdEnum];

/**
 * @export
 */
export const AccessPolicyOperationLogOptionV0ValueEnum = {
    Read: 'read'
} as const;
export type AccessPolicyOperationLogOptionV0ValueEnum = typeof AccessPolicyOperationLogOptionV0ValueEnum[keyof typeof AccessPolicyOperationLogOptionV0ValueEnum];


/**
 * Check if a given object implements the AccessPolicyOperationLogOptionV0 interface.
 */
export function instanceOfAccessPolicyOperationLogOptionV0(value: object): value is AccessPolicyOperationLogOptionV0 {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function AccessPolicyOperationLogOptionV0FromJSON(json: any): AccessPolicyOperationLogOptionV0 {
    return AccessPolicyOperationLogOptionV0FromJSONTyped(json, false);
}

export function AccessPolicyOperationLogOptionV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessPolicyOperationLogOptionV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'value': json['value'],
    };
}

export function AccessPolicyOperationLogOptionV0ToJSON(json: any): AccessPolicyOperationLogOptionV0 {
    return AccessPolicyOperationLogOptionV0ToJSONTyped(json, false);
}

export function AccessPolicyOperationLogOptionV0ToJSONTyped(value?: AccessPolicyOperationLogOptionV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'value': value['value'],
    };
}


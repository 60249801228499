import type { DownloadMode } from '@/types/Download';

export interface DownloadDialogState {
  mode: DownloadMode;
  ids: string[];
}

export const useDownloadDialogStore = defineStore('downloadDialog', {
  state: (): DownloadDialogState => ({
    mode: 'bulk',
    ids: [],
  }),

  actions: {
    setMode(mode: DownloadMode) {
      this.mode = mode;
    },

    setIds(ids: string[]) {
      this.ids = ids;
    },
  },
});

export default useDownloadDialogStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDownloadDialogStore, import.meta.hot));
}

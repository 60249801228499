<template>
  <div class="TextareaHolder">
    <textarea
      v-bind="$attrs"
      :id="name"
      v-model="validatedValue"
      :name
      class="Textarea"
      :class="{
        'Textarea-error': !!errorMessage,
        'Textarea-success': meta.valid,
        'Textarea-alt': alt,
      }"
      :placeholder
      :rows
      :disabled
      @input="handleChange"
      @blur="handleBlur"
    />
    <CharactersCounter v-if="maxlength" :value="model" :maxlength />
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';

import { toTypedSchema } from '@vee-validate/zod';
import { z, ZodString } from 'zod';

export type Props = {
  name: string;
  placeholder?: string | undefined;
  rows?: number;
  maxlength?: number;
  alt?: boolean;
  disabled?: boolean;
  schema?: ZodString;
};

const props = withDefaults(defineProps<Props>(), {
  rows: 4,
  maxlength: 1000,
  schema: undefined,
  placeholder: undefined,
});

defineOptions({
  inheritAttrs: false,
});

const schema = computed(() => {
  const s = props.schema ?? z.string();
  if (props.maxlength) return toTypedSchema(s.max(props.maxlength));
  else return toTypedSchema(s);
});

const model = defineModel<string | null>({ default: '' });

const {
  value: validatedValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(() => props.name, schema, {
  initialValue: model,
  syncVModel: true,
});
</script>

<style scoped lang="scss">
.TextareaHolder {
  width: 100%;
}

.Textarea {
  border: 1px solid black(12);
  border-radius: 4px;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  resize: vertical;
  background-color: neutral(50);
  outline: none;

  @include setTransition(border);

  &:hover {
    border: 1px solid black(60);
  }
  &:focus {
    border: 1px solid primary(700);
  }
  &:disabled {
    border: 1px solid neutral(50);
    background-color: neutral(50);
    color: neutral(350);

    &::placeholder {
      color: neutral(350);
    }
  }

  &-error {
    border: 1px solid red(400);
  }

  &-success {
    background-color: white();
  }

  &-alt {
    background-color: white();
  }
}
</style>

import type { NobitaLocale } from '@/locales';

import { createHead } from '@unhead/vue';

export const nobitaHead = createHead();

export function useNobitaHead() {
  function setHtmlLang(locale: NobitaLocale) {
    nobitaHead.push({
      htmlAttrs: {
        lang: locale,
      },
    });
  }

  return {
    setHtmlLang,
  };
}

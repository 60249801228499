/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IdpV0 } from './IdpV0';
import {
    IdpV0FromJSON,
    IdpV0FromJSONTyped,
    IdpV0ToJSON,
    IdpV0ToJSONTyped,
} from './IdpV0';

/**
 * Values returned by api - all domain settings.
 * @export
 * @interface DomainV0
 */
export interface DomainV0 {
    /**
     * 
     * @type {Set<string>}
     * @memberof DomainV0
     */
    allowedIps: Set<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DomainV0
     */
    domainNames: Array<string>;
    /**
     * 
     * @type {IdpV0}
     * @memberof DomainV0
     */
    idp: IdpV0;
    /**
     * 
     * @type {string}
     * @memberof DomainV0
     */
    idpTenantId: string;
    /**
     * Timezone configured for the domain. This must be a valid entry from the tz database (also known as the IANA time zone database), for example Asia/Tokyo or Europe/London.
     * @type {string}
     * @memberof DomainV0
     */
    timezone: string;
}



/**
 * Check if a given object implements the DomainV0 interface.
 */
export function instanceOfDomainV0(value: object): value is DomainV0 {
    if (!('allowedIps' in value) || value['allowedIps'] === undefined) return false;
    if (!('domainNames' in value) || value['domainNames'] === undefined) return false;
    if (!('idp' in value) || value['idp'] === undefined) return false;
    if (!('idpTenantId' in value) || value['idpTenantId'] === undefined) return false;
    if (!('timezone' in value) || value['timezone'] === undefined) return false;
    return true;
}

export function DomainV0FromJSON(json: any): DomainV0 {
    return DomainV0FromJSONTyped(json, false);
}

export function DomainV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): DomainV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'allowedIps': new Set(json['allowed_ips']),
        'domainNames': json['domain_names'],
        'idp': IdpV0FromJSON(json['idp']),
        'idpTenantId': json['idp_tenant_id'],
        'timezone': json['timezone'],
    };
}

export function DomainV0ToJSON(json: any): DomainV0 {
    return DomainV0ToJSONTyped(json, false);
}

export function DomainV0ToJSONTyped(value?: DomainV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'allowed_ips': Array.from(value['allowedIps'] as Set<any>),
        'domain_names': value['domainNames'],
        'idp': IdpV0ToJSON(value['idp']),
        'idp_tenant_id': value['idpTenantId'],
        'timezone': value['timezone'],
    };
}


<template>
  <div class="BaseLoader" :class="{ 'BaseLoader-fullscreen': fullscreen }">
    <ul class="LoaderPulseHolder" role="presentation">
      <li class="LoaderPulse LoaderPulseLeft" role="presentation" />
      <li class="LoaderPulse LoaderPulseCenter" role="presentation" />
      <li class="LoaderPulse LoaderPulseRight" role="presentation" />
    </ul>
    <span class="LoaderText">Loading...</span>
  </div>
</template>

<script setup lang="ts">
export type Props = {
  fullscreen?: boolean;
};

defineProps<Props>();
</script>

<style scoped lang="scss">
$pulse_size: 14px;

.BaseLoader {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  background-color: black(38);
  border-radius: 5px;

  &-fullscreen {
    position: fixed;
  }
}

.LoaderText {
  color: white();
  font-size: 12px;
  margin-top: 20px;
}

.LoaderPulseHolder {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.LoaderPulse {
  display: inline-block;
  width: $pulse_size;
  height: $pulse_size;
  border-radius: 50px;
  background-color: white();
  transform: scale(1);
  transform-origin: center;
  margin: 0 8px;
}

.LoaderPulseLeft {
  animation: dotPulse 1.5s ease-in-out 0s alternate infinite none running;
}
.LoaderPulseCenter {
  animation: dotPulse 1.5s ease-in-out 0.25s alternate infinite none running;
}
.LoaderPulseRight {
  animation: dotPulse 1.5s ease-in-out 0.5s alternate infinite none running;
}

@keyframes dotPulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
}
</style>

<template>
  <div class="DomainFieldHolder">
    <section class="DomainField">
      <LoginHeaderTitle />

      <h2 class="DomainFieldTitle">{{ $t('login.title') }}</h2>
      <p class="DomainFieldSubtitle">{{ $t('login.enterDomainName') }}</p>

      <div class="DomainFieldInner">
        <TextField
          v-model="domain"
          data-testid="DomainFieldInput"
          class="DomainInput"
          :error="errorMessage"
          name="domainName"
          type="text"
          size="lg"
          placeholder="placeholders.inputDomainNamePlaceholder"
          @submit="submitHandler"
        />

        <BaseAction type="submit" data-testid="DomainFieldAction" size="lg" @click="submitHandler">
          {{ $t('actionText.continue') }}
        </BaseAction>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import type { TranslationKey } from '@/locales';

const emit = defineEmits<{
  submit: [domain: string];
}>();

const domain = defineModel<string>({ required: true });

const errorMessage = ref<TranslationKey | undefined>();

function validateDomain(domain: string) {
  const reg = /^([a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;
  return reg.test(String(domain).toLowerCase());
}

const isValidDomain = computed<boolean>(() => validateDomain(domain.value));

function submitHandler() {
  if (isValidDomain.value) {
    errorMessage.value = undefined;
    emit('submit', domain.value);
  } else {
    errorMessage.value = 'validation.domainError';
  }
}
</script>

<style scoped lang="scss">
.DomainFieldHolder {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 16px;
}

.DomainField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white();
  border-radius: 8px;
  padding: 40px 24px;
  width: 100%;
  max-width: 526px;
  box-sizing: border-box;

  @include sm-and-up {
    padding: 40px;
  }
}

.DomainFieldTitle {
  color: black();
  font-size: 40px;
  font-weight: $font-weight-bold;
  letter-spacing: -0.8px;
  line-height: 1.2;
  margin-bottom: 16px;
  margin-top: 32px;
}

.DomainFieldSubtitle {
  color: black();
  font-size: 16px;
  font-weight: $font-weight-normal;
  line-height: 1.2;
  margin: 0 0 16px;
}

.DomainFieldInner {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;

  @include sm-and-up {
    flex-flow: row;
  }
}

:deep(.DomainInput) {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 320px;
}

:deep(.HelpMessage) {
  position: absolute;
}
</style>

/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AccessPolicyArchivePeriodOptionV0 } from './AccessPolicyArchivePeriodOptionV0';
import {
    instanceOfAccessPolicyArchivePeriodOptionV0,
    AccessPolicyArchivePeriodOptionV0FromJSON,
    AccessPolicyArchivePeriodOptionV0FromJSONTyped,
    AccessPolicyArchivePeriodOptionV0ToJSON,
} from './AccessPolicyArchivePeriodOptionV0';
import type { AccessPolicyArchiveSearchOptionV0 } from './AccessPolicyArchiveSearchOptionV0';
import {
    instanceOfAccessPolicyArchiveSearchOptionV0,
    AccessPolicyArchiveSearchOptionV0FromJSON,
    AccessPolicyArchiveSearchOptionV0FromJSONTyped,
    AccessPolicyArchiveSearchOptionV0ToJSON,
} from './AccessPolicyArchiveSearchOptionV0';
import type { AccessPolicyConsoleSettingsOptionV0 } from './AccessPolicyConsoleSettingsOptionV0';
import {
    instanceOfAccessPolicyConsoleSettingsOptionV0,
    AccessPolicyConsoleSettingsOptionV0FromJSON,
    AccessPolicyConsoleSettingsOptionV0FromJSONTyped,
    AccessPolicyConsoleSettingsOptionV0ToJSON,
} from './AccessPolicyConsoleSettingsOptionV0';
import type { AccessPolicyOperationLogOptionV0 } from './AccessPolicyOperationLogOptionV0';
import {
    instanceOfAccessPolicyOperationLogOptionV0,
    AccessPolicyOperationLogOptionV0FromJSON,
    AccessPolicyOperationLogOptionV0FromJSONTyped,
    AccessPolicyOperationLogOptionV0ToJSON,
} from './AccessPolicyOperationLogOptionV0';
import type { AccessPolicySearchPoliciesOptionV0 } from './AccessPolicySearchPoliciesOptionV0';
import {
    instanceOfAccessPolicySearchPoliciesOptionV0,
    AccessPolicySearchPoliciesOptionV0FromJSON,
    AccessPolicySearchPoliciesOptionV0FromJSONTyped,
    AccessPolicySearchPoliciesOptionV0ToJSON,
} from './AccessPolicySearchPoliciesOptionV0';
import type { AccessPolicySelfEditOptionV0 } from './AccessPolicySelfEditOptionV0';
import {
    instanceOfAccessPolicySelfEditOptionV0,
    AccessPolicySelfEditOptionV0FromJSON,
    AccessPolicySelfEditOptionV0FromJSONTyped,
    AccessPolicySelfEditOptionV0ToJSON,
} from './AccessPolicySelfEditOptionV0';
import type { AccessPolicyUserSettingsOptionV0 } from './AccessPolicyUserSettingsOptionV0';
import {
    instanceOfAccessPolicyUserSettingsOptionV0,
    AccessPolicyUserSettingsOptionV0FromJSON,
    AccessPolicyUserSettingsOptionV0FromJSONTyped,
    AccessPolicyUserSettingsOptionV0ToJSON,
} from './AccessPolicyUserSettingsOptionV0';

/**
 * @type AccessPolicyOptionV0
 * 
 * @export
 */
export type AccessPolicyOptionV0 = { id: 'archive_period' } & AccessPolicyArchivePeriodOptionV0 | { id: 'archive_search' } & AccessPolicyArchiveSearchOptionV0 | { id: 'console_settings' } & AccessPolicyConsoleSettingsOptionV0 | { id: 'operation_log' } & AccessPolicyOperationLogOptionV0 | { id: 'search_policies' } & AccessPolicySearchPoliciesOptionV0 | { id: 'self_edit' } & AccessPolicySelfEditOptionV0 | { id: 'user_settings' } & AccessPolicyUserSettingsOptionV0;

export function AccessPolicyOptionV0FromJSON(json: any): AccessPolicyOptionV0 {
    return AccessPolicyOptionV0FromJSONTyped(json, false);
}

export function AccessPolicyOptionV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessPolicyOptionV0 {
    if (json == null) {
        return json;
    }
    switch (json['id']) {
        case 'archive_period':
            return Object.assign({}, AccessPolicyArchivePeriodOptionV0FromJSONTyped(json, true), { id: 'archive_period' } as const);
        case 'archive_search':
            return Object.assign({}, AccessPolicyArchiveSearchOptionV0FromJSONTyped(json, true), { id: 'archive_search' } as const);
        case 'console_settings':
            return Object.assign({}, AccessPolicyConsoleSettingsOptionV0FromJSONTyped(json, true), { id: 'console_settings' } as const);
        case 'operation_log':
            return Object.assign({}, AccessPolicyOperationLogOptionV0FromJSONTyped(json, true), { id: 'operation_log' } as const);
        case 'search_policies':
            return Object.assign({}, AccessPolicySearchPoliciesOptionV0FromJSONTyped(json, true), { id: 'search_policies' } as const);
        case 'self_edit':
            return Object.assign({}, AccessPolicySelfEditOptionV0FromJSONTyped(json, true), { id: 'self_edit' } as const);
        case 'user_settings':
            return Object.assign({}, AccessPolicyUserSettingsOptionV0FromJSONTyped(json, true), { id: 'user_settings' } as const);
        default:
            throw new Error(`No variant of AccessPolicyOptionV0 exists with 'id=${json['id']}'`);
    }
}

export function AccessPolicyOptionV0ToJSON(json: any): any {
    return AccessPolicyOptionV0ToJSONTyped(json, false);
}

export function AccessPolicyOptionV0ToJSONTyped(value?: AccessPolicyOptionV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['id']) {
        case 'archive_period':
            return Object.assign({}, AccessPolicyArchivePeriodOptionV0ToJSON(value), { id: 'archive_period' } as const);
        case 'archive_search':
            return Object.assign({}, AccessPolicyArchiveSearchOptionV0ToJSON(value), { id: 'archive_search' } as const);
        case 'console_settings':
            return Object.assign({}, AccessPolicyConsoleSettingsOptionV0ToJSON(value), { id: 'console_settings' } as const);
        case 'operation_log':
            return Object.assign({}, AccessPolicyOperationLogOptionV0ToJSON(value), { id: 'operation_log' } as const);
        case 'search_policies':
            return Object.assign({}, AccessPolicySearchPoliciesOptionV0ToJSON(value), { id: 'search_policies' } as const);
        case 'self_edit':
            return Object.assign({}, AccessPolicySelfEditOptionV0ToJSON(value), { id: 'self_edit' } as const);
        case 'user_settings':
            return Object.assign({}, AccessPolicyUserSettingsOptionV0ToJSON(value), { id: 'user_settings' } as const);
        default:
            throw new Error(`No variant of AccessPolicyOptionV0 exists with 'id=${value['id']}'`);
    }

}


import type { TranslationKey } from '@/locales';

export interface SnackbarList {
  items: SnackbarItem[];
}

export enum SNACKBAR_POSITION {
  topRight = 'topRight',
  topCenter = 'topCenter',
  bottomRight = 'bottomRight',
}

export interface SnackbarItem {
  id: string;
  isActive: boolean;
  isClosable: boolean;
  hasAction: boolean;
  status: 'standard' | 'warning';
  icon?: 'info' | 'check' | 'loader';
  actionText?: string;
  content: string;
  duration: 0 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  position: SNACKBAR_POSITION;
  callback?: () => unknown;
}

export interface SnackbarParams {
  id?: string;
  content: TranslationKey;
  status?: 'standard' | 'warning';
  isClosable?: boolean;
  hasAction?: boolean;
  icon?: 'info' | 'check' | 'loader';
  actionText?: TranslationKey;
  duration?: 0 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  position?: SNACKBAR_POSITION;
  callback?: () => unknown;
}

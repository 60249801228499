<template>
  <Transition name="fadein">
    <article v-show="isLoading" v-bind="$attrs" class="SkeletonNav">
      <ul class="SkeletonNavMenu">
        <li
          v-for="(group, i) in params.navGroups"
          :key="`nav-skeleton-group-${i}`"
          class="SkeletonNavSection"
        >
          <dl>
            <dt class="SkeletonNavSectionHeader">
              <SkeletonIcon />
              <SkeletonNavItem header />
            </dt>
            <dd
              v-for="(_, idx) in Math.floor(group.navItems)"
              :key="`nav-skeleton-item-${i}-${idx}`"
              class="SkeletonNavSectionContent"
            >
              <SkeletonNavItem />
            </dd>
          </dl>
        </li>
      </ul>
    </article>
  </Transition>

  <Transition name="fadein">
    <div v-show="!isLoading" class="SlotContainer">
      <slot />
    </div>
  </Transition>
</template>

<script setup lang="ts">
import type { SkeletonNavBuilder } from '@/types/Skeleton';

export type Props = {
  params: SkeletonNavBuilder;
  isLoading: boolean;
};

defineProps<Props>();

defineSlots<{
  default(): void;
}>();

defineOptions({
  inheritAttrs: false,
});
</script>

<style scoped lang="scss">
.SkeletonNav {
  position: absolute;
}

.SkeletonNavMenu {
  list-style: none;
  padding-left: 20px;
}

.SkeletonNavSection {
  margin-bottom: 32px;
}

.SkeletonNavSectionHeader {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 4px;
}

.SkeletonNavSectionContent {
  margin-left: 28px;
  margin-bottom: 16px;
}

.SlotContainer {
  height: 100%;
}

// transitions
@include transitionFadein(medium);
</style>

<template>
  <li>
    <RouterLink
      v-if="item.route"
      class="Link"
      exact-active-class="Link-active"
      :to="{ name: item.route }"
    >
      {{ $t(item.title) }}
      <NavBadge v-if="item.badge" :badge="item.badge" />
    </RouterLink>
    <span v-else class="Link Link-disabled">
      {{ $t(item.title) }}
      <NavBadge v-if="item.badge" :badge="item.badge" />
    </span>
  </li>
</template>

<script setup lang="ts">
import type { NavItem } from '@/types/Nav';

export type Props = {
  item: NavItem;
};

defineProps<Props>();
</script>

<style scoped lang="scss">
.Link {
  display: block;
  color: black(60);
  font-weight: $font-weight-normal;
  font-size: 14px;
  line-height: 1;
  text-decoration: none;
  padding: 9px 16px 9px 48px;
  white-space: nowrap;
  cursor: pointer;
  background-color: transparent;

  @include setTransition(background-color, color);

  &:hover {
    background-color: black(5);
  }

  &:focus {
    background-color: alpha-primary(20);
  }

  &-active {
    background-color: alpha-primary(20);
    color: black(87);
  }

  &-disabled {
    color: neutral(350);
    cursor: default;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}
</style>

<template>
  <a
    class="BaseLink"
    :href
    :title
    :target
    :rel="target === '_blank' ? 'noopener noreferrer' : undefined"
  >
    <slot />
  </a>
</template>

<script setup lang="ts">
export type Props = {
  href: string;
  title: string;
  target?: string;
};

withDefaults(defineProps<Props>(), {
  target: '_blank',
});

defineSlots<{
  default(): void;
}>();
</script>

<style scoped lang="scss">
.BaseLink {
  position: relative;
  font-size: inherit;
  line-height: inherit;
  color: primary(700);
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  text-decoration: none;

  @include setTransition(color);

  &:hover {
    color: color.adjust(primary(700), $lightness: -20%);
  }
}
</style>

import App from './App.vue';

// plugins
import createBootstrap from './plugins/bootstrap-vue';
import router from './router';
import { piniaInstance as pinia } from './store';

import { DatePicker, setupCalendar } from 'v-calendar';
import VueGtagPlugin from 'vue-gtag';

import './plugins/dayjs';

import { vFocusTrap, vOnClickOutside, vScrollLock } from '@/directives';
import i18n, { vCalendarConfig } from './locales';

// Sentry
import * as Sentry from '@sentry/vue';

// styles
import 'normalize.css';
import 'v-calendar/style.css';

const app = createApp(App)
  .use(pinia) // Install the store before everything else! including router
  .use(router)
  .use(nobitaHead)
  .use(i18n)
  .use(
    VueGtagPlugin,
    {
      enabled: !!import.meta.env.VITE_NOBITA_GA,
      disableScriptLoad: !import.meta.env.VITE_NOBITA_GA,
      config: {
        id: import.meta.env.VITE_NOBITA_GA,
      },
    },
    router,
  )
  .use(createBootstrap())
  .use(setupCalendar, vCalendarConfig)
  .component('VDatePicker', DatePicker)
  .directive('click-outside', vOnClickOutside)
  .directive('scroll-lock', vScrollLock)
  .directive('focus-trap', vFocusTrap);

/**
 * Sentry instrumentation
 */

const tracesSampleRateParsed = parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE);
const tracesSampleRate = Number.isNaN(tracesSampleRateParsed) ? 0.01 : tracesSampleRateParsed;

Sentry.init({
  app,

  // This can be `null` for certain build modes, which means it will not end up sending reports to Sentry,
  // even when this tooling is added to the Vue app.
  dsn: import.meta.env.VITE_SENTRY_DSN,

  // Use the build mode as the environment.
  // See https://github.com/HENNGE/nobita2-ui/blob/3c7abbdf38a6931d41ce5a8de930cca931284944/vite.config.ts#L44-L57
  environment: import.meta.env.MODE,

  /**
   * The below env vars will be set by the CI/CD pipeline, only on builds which are thereafter deployed.
   * For connecting built bundles to their correct release/deploy information on Sentry,
   *   it's necessary to have the same values here as are defined in the vite.config.ts at build time.
   * If `release` is undefined it will fallback to the Git SHA.
   */
  release: import.meta.env.NOBITA_V2_SENTRY_RELEASE_TAG || undefined,
  dist: import.meta.env.NOBITA_V2_SENTRY_DIST_TAG || undefined,

  integrations: [
    Sentry.browserTracingIntegration({
      router,
    }),
  ],

  tracesSampleRate,

  tracePropagationTargets: [
    'localhost',
    'archive.nobita.prd.demodesu.com',
    'staging.nobita.demodesu.com',
    'archive.hennge.com',
    /^\//,
  ],
});

app.mount('#app');

logger.info(`Running app in mode '${import.meta.env.MODE}'`);

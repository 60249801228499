/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserInfoUpdateV0
 */
export interface UserInfoUpdateV0 {
    /**
     * 
     * @type {string}
     * @memberof UserInfoUpdateV0
     */
    locale?: string | null;
}

/**
 * Check if a given object implements the UserInfoUpdateV0 interface.
 */
export function instanceOfUserInfoUpdateV0(value: object): value is UserInfoUpdateV0 {
    return true;
}

export function UserInfoUpdateV0FromJSON(json: any): UserInfoUpdateV0 {
    return UserInfoUpdateV0FromJSONTyped(json, false);
}

export function UserInfoUpdateV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfoUpdateV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'locale': json['locale'] == null ? undefined : json['locale'],
    };
}

export function UserInfoUpdateV0ToJSON(json: any): UserInfoUpdateV0 {
    return UserInfoUpdateV0ToJSONTyped(json, false);
}

export function UserInfoUpdateV0ToJSONTyped(value?: UserInfoUpdateV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'locale': value['locale'],
    };
}


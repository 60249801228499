/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Permission } from './Permission';
import {
    PermissionFromJSON,
    PermissionFromJSONTyped,
    PermissionToJSON,
    PermissionToJSONTyped,
} from './Permission';

/**
 * 
 * @export
 * @interface UserInfoV0
 */
export interface UserInfoV0 {
    /**
     * 
     * @type {string}
     * @memberof UserInfoV0
     */
    email: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserInfoV0
     */
    enabledFeatures?: Array<string> | null;
    /**
     * expiration time of the current session in date-time notation as defined by RFC 3339
     * @type {Date}
     * @memberof UserInfoV0
     */
    expiry: Date;
    /**
     * maximum number of headers that can be exported at once per request
     * @type {number}
     * @memberof UserInfoV0
     */
    headerExportLimit: number;
    /**
     * 
     * @type {string}
     * @memberof UserInfoV0
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoV0
     */
    ipAddress: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoV0
     */
    locale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfoV0
     */
    loginDomainName: string;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof UserInfoV0
     */
    permissions: Array<Permission>;
    /**
     * indicates if the user has any archive search filter configured
     * @type {boolean}
     * @memberof UserInfoV0
     */
    searchFilter: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserInfoV0
     */
    timezone: string;
}

/**
 * Check if a given object implements the UserInfoV0 interface.
 */
export function instanceOfUserInfoV0(value: object): value is UserInfoV0 {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('expiry' in value) || value['expiry'] === undefined) return false;
    if (!('headerExportLimit' in value) || value['headerExportLimit'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('ipAddress' in value) || value['ipAddress'] === undefined) return false;
    if (!('loginDomainName' in value) || value['loginDomainName'] === undefined) return false;
    if (!('permissions' in value) || value['permissions'] === undefined) return false;
    if (!('searchFilter' in value) || value['searchFilter'] === undefined) return false;
    if (!('timezone' in value) || value['timezone'] === undefined) return false;
    return true;
}

export function UserInfoV0FromJSON(json: any): UserInfoV0 {
    return UserInfoV0FromJSONTyped(json, false);
}

export function UserInfoV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfoV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'enabledFeatures': json['enabled_features'] == null ? undefined : json['enabled_features'],
        'expiry': (new Date(json['expiry'])),
        'headerExportLimit': json['header_export_limit'],
        'id': json['id'],
        'ipAddress': json['ip_address'],
        'locale': json['locale'] == null ? undefined : json['locale'],
        'loginDomainName': json['login_domain_name'],
        'permissions': ((json['permissions'] as Array<any>).map(PermissionFromJSON)),
        'searchFilter': json['search_filter'],
        'timezone': json['timezone'],
    };
}

export function UserInfoV0ToJSON(json: any): UserInfoV0 {
    return UserInfoV0ToJSONTyped(json, false);
}

export function UserInfoV0ToJSONTyped(value?: UserInfoV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
        'enabled_features': value['enabledFeatures'],
        'expiry': ((value['expiry']).toISOString()),
        'header_export_limit': value['headerExportLimit'],
        'id': value['id'],
        'ip_address': value['ipAddress'],
        'locale': value['locale'],
        'login_domain_name': value['loginDomainName'],
        'permissions': ((value['permissions'] as Array<any>).map(PermissionToJSON)),
        'search_filter': value['searchFilter'],
        'timezone': value['timezone'],
    };
}


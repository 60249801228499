import type { StoreDefinition } from '.';
import type { UserV0 } from '@/api';
import type { DateRange } from '@/types/DateRange';
import type { JobSearchSettingsState } from '@/types/JobSearchSettings';
import type { SearchRangeOption } from '@/types/SearchRange';

import { cloneDeep } from 'lodash-es';

const getInitialState = (): JobSearchSettingsState => {
  const tenant = useTenantStore();

  return {
    // date
    dates: SearchRanges[DEFAULT_SEARCH_RANGE_OPTION].getDateRange(tenant.timezone),
    rangeIndex: DEFAULT_SEARCH_RANGE_OPTION,
    // users
    users: [],
  };
};

const storeDefiniton: StoreDefinition<JobSearchSettingsState> = {
  state: getInitialState,

  actions: {
    // dates
    setDates(params: DateRange): void {
      this.dates = { ...params };
    },

    setRangeIndex(index: SearchRangeOption): void {
      this.rangeIndex = index;
    },

    // users
    setUsers(params: UserV0[]): void {
      this.users = cloneDeep(params);
    },
  },
};

export const useJobSearchSettingsStore = defineStore('jobSearchSettings', storeDefiniton);

export default useJobSearchSettingsStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useJobSearchSettingsStore, import.meta.hot));
}

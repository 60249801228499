/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessPolicyV0 } from './AccessPolicyV0';
import {
    AccessPolicyV0FromJSON,
    AccessPolicyV0FromJSONTyped,
    AccessPolicyV0ToJSON,
    AccessPolicyV0ToJSONTyped,
} from './AccessPolicyV0';
import type { Permission } from './Permission';
import {
    PermissionFromJSON,
    PermissionFromJSONTyped,
    PermissionToJSON,
    PermissionToJSONTyped,
} from './Permission';

/**
 * Values returned by api.
 * @export
 * @interface UserV0
 */
export interface UserV0 {
    /**
     * 
     * @type {AccessPolicyV0}
     * @memberof UserV0
     */
    accessPolicy: AccessPolicyV0;
    /**
     * 
     * @type {Date}
     * @memberof UserV0
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof UserV0
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserV0
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserV0
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserV0
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof UserV0
     */
    lastActivity?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UserV0
     */
    locale?: string | null;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof UserV0
     */
    permissions: Array<Permission>;
    /**
     * 
     * @type {string}
     * @memberof UserV0
     */
    searchPolicyId: string;
    /**
     * 
     * @type {Date}
     * @memberof UserV0
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the UserV0 interface.
 */
export function instanceOfUserV0(value: object): value is UserV0 {
    if (!('accessPolicy' in value) || value['accessPolicy'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('enabled' in value) || value['enabled'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('permissions' in value) || value['permissions'] === undefined) return false;
    if (!('searchPolicyId' in value) || value['searchPolicyId'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function UserV0FromJSON(json: any): UserV0 {
    return UserV0FromJSONTyped(json, false);
}

export function UserV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): UserV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'accessPolicy': AccessPolicyV0FromJSON(json['access_policy']),
        'createdAt': (new Date(json['created_at'])),
        'description': json['description'] == null ? undefined : json['description'],
        'email': json['email'],
        'enabled': json['enabled'],
        'id': json['id'],
        'lastActivity': json['last_activity'] == null ? undefined : (new Date(json['last_activity'])),
        'locale': json['locale'] == null ? undefined : json['locale'],
        'permissions': ((json['permissions'] as Array<any>).map(PermissionFromJSON)),
        'searchPolicyId': json['search_policy_id'],
        'updatedAt': (new Date(json['updated_at'])),
    };
}

export function UserV0ToJSON(json: any): UserV0 {
    return UserV0ToJSONTyped(json, false);
}

export function UserV0ToJSONTyped(value?: UserV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'access_policy': AccessPolicyV0ToJSON(value['accessPolicy']),
        'created_at': ((value['createdAt']).toISOString()),
        'description': value['description'],
        'email': value['email'],
        'enabled': value['enabled'],
        'id': value['id'],
        'last_activity': value['lastActivity'] == null ? undefined : ((value['lastActivity'] as any).toISOString()),
        'locale': value['locale'],
        'permissions': ((value['permissions'] as Array<any>).map(PermissionToJSON)),
        'search_policy_id': value['searchPolicyId'],
        'updated_at': ((value['updatedAt']).toISOString()),
    };
}


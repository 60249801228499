import type { ConfigurationParameters } from '@/api';
import { isDateRange } from '@/types/DateRange';

import dayjs from 'dayjs';

/**
 * Generalized comparison function that compares two values for equivalency, ignoring the order of their Object keys or Array values.
 *
 * When comparing Objects, determines equivalency by comparing the keys and values of each Object, not by whether the instances are the same.
 *
 * Will also compare Set instances for equivalency, determining that each element in Set A has an equivalent element in Set B.
 *
 * @param x a value to compare against `y`
 * @param y a value to compare against `x`
 * @returns `true` if the two objects represent the same data, regardless of the order of their Object keys or Array values. `false` otherwise
 */
export function isEquivalentUnsorted(x: unknown, y: unknown): boolean {
  if (typeof x !== typeof y) return false;
  if (typeof x !== 'object' || typeof y !== 'object') return x === y;
  if (x === null || y === null) return x === y;
  if (Array.isArray(x) !== Array.isArray(y)) return false;

  function compareArrays(x: unknown[], y: unknown[]) {
    if (x.length !== y.length) return false;
    const yCopy = [...y];
    for (const xElem of x) {
      const idx = yCopy.findIndex((yElem) => isEquivalentUnsorted(xElem, yElem));
      if (idx === -1) return false;
      yCopy.splice(idx, 1);
    }
    return yCopy.length === 0;
  }

  if (Array.isArray(x) && Array.isArray(y)) {
    return compareArrays(x, y);
  }

  if (isDateRange(x) || isDateRange(y)) {
    if (!isDateRange(x) || !isDateRange(y)) return false;

    return dayjs(x.start).isSame(y.start) && dayjs(x.end).isSame(y.end);
  }

  if (x instanceof Set || y instanceof Set) {
    if (!(x instanceof Set) || !(y instanceof Set)) return false;

    if (x.size !== y.size) return false;

    // Do this instead of using Set.has() because we want to deep-compare the set elements for equivalency as well
    return compareArrays([...x], [...y]);
  }

  if (Object.keys(x).length !== Object.keys(y).length) return false;

  for (const k in x) {
    if (!(k in y) || !isEquivalentUnsorted(x[k as keyof typeof x], y[k as keyof typeof y]))
      return false;
  }
  return true;
}

export function buildBaseApiConfigParameters(accessToken: string | null): ConfigurationParameters {
  const params: ConfigurationParameters = {
    basePath: import.meta.env.VITE_APP_API_BASE_PATH || '',
  };

  if (accessToken) {
    params.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return params;
}

/**
 * Tests for the presence of a value in a TS Enum object.
 * Useful for type guards.
 *
 * @param enumObj A TS Enum object
 * @param value a value to find in the enum (not the key/name, but the value)
 * @returns whether the value is present in the enum
 */
export function isInEnum<T extends Record<string, string | number>>(
  enumObj: T,
  value: string | number,
): value is T[keyof T] {
  return Object.values(enumObj).includes(value as T[keyof T]);
}

/**
 * An async/await friendly version of `setTimeout`.
 * @param ms number of milliseconds to delay
 * @returns a Promise that resolves after `ms` milliseconds
 */
export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AnySearchConditionV0 } from './AnySearchConditionV0';
import {
    type instanceOfAnySearchConditionV0,
    AnySearchConditionV0FromJSON,
    AnySearchConditionV0FromJSONTyped,
    AnySearchConditionV0ToJSON,
} from './AnySearchConditionV0';
import type { AttachmentSearchConditionV0 } from './AttachmentSearchConditionV0';
import {
    type instanceOfAttachmentSearchConditionV0,
    AttachmentSearchConditionV0FromJSON,
    AttachmentSearchConditionV0FromJSONTyped,
    AttachmentSearchConditionV0ToJSON,
} from './AttachmentSearchConditionV0';
import type { BodySearchConditionV0 } from './BodySearchConditionV0';
import {
    type instanceOfBodySearchConditionV0,
    BodySearchConditionV0FromJSON,
    BodySearchConditionV0FromJSONTyped,
    BodySearchConditionV0ToJSON,
} from './BodySearchConditionV0';
import type { CcSearchConditionV0 } from './CcSearchConditionV0';
import {
    type instanceOfCcSearchConditionV0,
    CcSearchConditionV0FromJSON,
    CcSearchConditionV0FromJSONTyped,
    CcSearchConditionV0ToJSON,
} from './CcSearchConditionV0';
import type { EnvelopeFromEnvelopeToSearchConditionV0 } from './EnvelopeFromEnvelopeToSearchConditionV0';
import {
    type instanceOfEnvelopeFromEnvelopeToSearchConditionV0,
    EnvelopeFromEnvelopeToSearchConditionV0FromJSON,
    EnvelopeFromEnvelopeToSearchConditionV0FromJSONTyped,
    EnvelopeFromEnvelopeToSearchConditionV0ToJSON,
} from './EnvelopeFromEnvelopeToSearchConditionV0';
import type { EnvelopeFromSearchConditionV0 } from './EnvelopeFromSearchConditionV0';
import {
    type instanceOfEnvelopeFromSearchConditionV0,
    EnvelopeFromSearchConditionV0FromJSON,
    EnvelopeFromSearchConditionV0FromJSONTyped,
    EnvelopeFromSearchConditionV0ToJSON,
} from './EnvelopeFromSearchConditionV0';
import type { EnvelopeToSearchConditionV0 } from './EnvelopeToSearchConditionV0';
import {
    type instanceOfEnvelopeToSearchConditionV0,
    EnvelopeToSearchConditionV0FromJSON,
    EnvelopeToSearchConditionV0FromJSONTyped,
    EnvelopeToSearchConditionV0ToJSON,
} from './EnvelopeToSearchConditionV0';
import type { FromSearchConditionV0 } from './FromSearchConditionV0';
import {
    type instanceOfFromSearchConditionV0,
    FromSearchConditionV0FromJSON,
    FromSearchConditionV0FromJSONTyped,
    FromSearchConditionV0ToJSON,
} from './FromSearchConditionV0';
import type { StorageStatusSearchConditionV0 } from './StorageStatusSearchConditionV0';
import {
    type instanceOfStorageStatusSearchConditionV0,
    StorageStatusSearchConditionV0FromJSON,
    StorageStatusSearchConditionV0FromJSONTyped,
    StorageStatusSearchConditionV0ToJSON,
} from './StorageStatusSearchConditionV0';
import type { SubjectBodySearchConditionV0 } from './SubjectBodySearchConditionV0';
import {
    type instanceOfSubjectBodySearchConditionV0,
    SubjectBodySearchConditionV0FromJSON,
    SubjectBodySearchConditionV0FromJSONTyped,
    SubjectBodySearchConditionV0ToJSON,
} from './SubjectBodySearchConditionV0';
import type { SubjectSearchConditionV0 } from './SubjectSearchConditionV0';
import {
    type instanceOfSubjectSearchConditionV0,
    SubjectSearchConditionV0FromJSON,
    SubjectSearchConditionV0FromJSONTyped,
    SubjectSearchConditionV0ToJSON,
} from './SubjectSearchConditionV0';
import type { ToCcBccSearchConditionV0 } from './ToCcBccSearchConditionV0';
import {
    type instanceOfToCcBccSearchConditionV0,
    ToCcBccSearchConditionV0FromJSON,
    ToCcBccSearchConditionV0FromJSONTyped,
    ToCcBccSearchConditionV0ToJSON,
} from './ToCcBccSearchConditionV0';
import type { ToSearchConditionV0 } from './ToSearchConditionV0';
import {
    type instanceOfToSearchConditionV0,
    ToSearchConditionV0FromJSON,
    ToSearchConditionV0FromJSONTyped,
    ToSearchConditionV0ToJSON,
} from './ToSearchConditionV0';

/**
 * @type SearchConditionV0
 * 
 * @export
 */
export type SearchConditionV0 = { field: 'any' } & AnySearchConditionV0 | { field: 'body' } & BodySearchConditionV0 | { field: 'cc' } & CcSearchConditionV0 | { field: 'envelope_from' } & EnvelopeFromSearchConditionV0 | { field: 'envelope_from_envelope_to' } & EnvelopeFromEnvelopeToSearchConditionV0 | { field: 'envelope_to' } & EnvelopeToSearchConditionV0 | { field: 'from' } & FromSearchConditionV0 | { field: 'has_attachment' } & AttachmentSearchConditionV0 | { field: 'storage_status' } & StorageStatusSearchConditionV0 | { field: 'subject' } & SubjectSearchConditionV0 | { field: 'subject_body' } & SubjectBodySearchConditionV0 | { field: 'to' } & ToSearchConditionV0 | { field: 'to_cc_bcc' } & ToCcBccSearchConditionV0;

export function SearchConditionV0FromJSON(json: any): SearchConditionV0 {
    return SearchConditionV0FromJSONTyped(json, false);
}

export function SearchConditionV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchConditionV0 {
    if (json == null) {
        return json;
    }
    switch (json['field']) {
        case 'any':
            return Object.assign({}, AnySearchConditionV0FromJSONTyped(json, true), { field: 'any' } as const);
        case 'body':
            return Object.assign({}, BodySearchConditionV0FromJSONTyped(json, true), { field: 'body' } as const);
        case 'cc':
            return Object.assign({}, CcSearchConditionV0FromJSONTyped(json, true), { field: 'cc' } as const);
        case 'envelope_from':
            return Object.assign({}, EnvelopeFromSearchConditionV0FromJSONTyped(json, true), { field: 'envelope_from' } as const);
        case 'envelope_from_envelope_to':
            return Object.assign({}, EnvelopeFromEnvelopeToSearchConditionV0FromJSONTyped(json, true), { field: 'envelope_from_envelope_to' } as const);
        case 'envelope_to':
            return Object.assign({}, EnvelopeToSearchConditionV0FromJSONTyped(json, true), { field: 'envelope_to' } as const);
        case 'from':
            return Object.assign({}, FromSearchConditionV0FromJSONTyped(json, true), { field: 'from' } as const);
        case 'has_attachment':
            return Object.assign({}, AttachmentSearchConditionV0FromJSONTyped(json, true), { field: 'has_attachment' } as const);
        case 'storage_status':
            return Object.assign({}, StorageStatusSearchConditionV0FromJSONTyped(json, true), { field: 'storage_status' } as const);
        case 'subject':
            return Object.assign({}, SubjectSearchConditionV0FromJSONTyped(json, true), { field: 'subject' } as const);
        case 'subject_body':
            return Object.assign({}, SubjectBodySearchConditionV0FromJSONTyped(json, true), { field: 'subject_body' } as const);
        case 'to':
            return Object.assign({}, ToSearchConditionV0FromJSONTyped(json, true), { field: 'to' } as const);
        case 'to_cc_bcc':
            return Object.assign({}, ToCcBccSearchConditionV0FromJSONTyped(json, true), { field: 'to_cc_bcc' } as const);
        default:
            throw new Error(`No variant of SearchConditionV0 exists with 'field=${json['field']}'`);
    }
}

export function SearchConditionV0ToJSON(json: any): any {
    return SearchConditionV0ToJSONTyped(json, false);
}

export function SearchConditionV0ToJSONTyped(value?: SearchConditionV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['field']) {
        case 'any':
            return Object.assign({}, AnySearchConditionV0ToJSON(value), { field: 'any' } as const);
        case 'body':
            return Object.assign({}, BodySearchConditionV0ToJSON(value), { field: 'body' } as const);
        case 'cc':
            return Object.assign({}, CcSearchConditionV0ToJSON(value), { field: 'cc' } as const);
        case 'envelope_from':
            return Object.assign({}, EnvelopeFromSearchConditionV0ToJSON(value), { field: 'envelope_from' } as const);
        case 'envelope_from_envelope_to':
            return Object.assign({}, EnvelopeFromEnvelopeToSearchConditionV0ToJSON(value), { field: 'envelope_from_envelope_to' } as const);
        case 'envelope_to':
            return Object.assign({}, EnvelopeToSearchConditionV0ToJSON(value), { field: 'envelope_to' } as const);
        case 'from':
            return Object.assign({}, FromSearchConditionV0ToJSON(value), { field: 'from' } as const);
        case 'has_attachment':
            return Object.assign({}, AttachmentSearchConditionV0ToJSON(value), { field: 'has_attachment' } as const);
        case 'storage_status':
            return Object.assign({}, StorageStatusSearchConditionV0ToJSON(value), { field: 'storage_status' } as const);
        case 'subject':
            return Object.assign({}, SubjectSearchConditionV0ToJSON(value), { field: 'subject' } as const);
        case 'subject_body':
            return Object.assign({}, SubjectBodySearchConditionV0ToJSON(value), { field: 'subject_body' } as const);
        case 'to':
            return Object.assign({}, ToSearchConditionV0ToJSON(value), { field: 'to' } as const);
        case 'to_cc_bcc':
            return Object.assign({}, ToCcBccSearchConditionV0ToJSON(value), { field: 'to_cc_bcc' } as const);
        default:
            throw new Error(`No variant of SearchConditionV0 exists with 'field=${value['field']}'`);
    }

}


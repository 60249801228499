/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SystemSearchPolicyInListV0
 */
export interface SystemSearchPolicyInListV0 {
    /**
     * 
     * @type {string}
     * @memberof SystemSearchPolicyInListV0
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SystemSearchPolicyInListV0
     */
    type: SystemSearchPolicyInListV0TypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SystemSearchPolicyInListV0
     */
    userCount: number;
}


/**
 * @export
 */
export const SystemSearchPolicyInListV0TypeEnum = {
    System: 'system'
} as const;
export type SystemSearchPolicyInListV0TypeEnum = typeof SystemSearchPolicyInListV0TypeEnum[keyof typeof SystemSearchPolicyInListV0TypeEnum];


/**
 * Check if a given object implements the SystemSearchPolicyInListV0 interface.
 */
export function instanceOfSystemSearchPolicyInListV0(value: object): value is SystemSearchPolicyInListV0 {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('userCount' in value) || value['userCount'] === undefined) return false;
    return true;
}

export function SystemSearchPolicyInListV0FromJSON(json: any): SystemSearchPolicyInListV0 {
    return SystemSearchPolicyInListV0FromJSONTyped(json, false);
}

export function SystemSearchPolicyInListV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemSearchPolicyInListV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'userCount': json['user_count'],
    };
}

export function SystemSearchPolicyInListV0ToJSON(json: any): SystemSearchPolicyInListV0 {
    return SystemSearchPolicyInListV0ToJSONTyped(json, false);
}

export function SystemSearchPolicyInListV0ToJSONTyped(value?: SystemSearchPolicyInListV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'type': value['type'],
        'user_count': value['userCount'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperationTypeV0 } from './OperationTypeV0';
import {
    OperationTypeV0FromJSON,
    OperationTypeV0FromJSONTyped,
    OperationTypeV0ToJSON,
    OperationTypeV0ToJSONTyped,
} from './OperationTypeV0';

/**
 * 
 * @export
 * @interface OperationLogSearchQueryV0
 */
export interface OperationLogSearchQueryV0 {
    /**
     * skip results after and including this token
     * @type {string}
     * @memberof OperationLogSearchQueryV0
     */
    backtoken?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof OperationLogSearchQueryV0
     */
    end: Date;
    /**
     * maximum number of results to return
     * @type {number}
     * @memberof OperationLogSearchQueryV0
     */
    limit?: number;
    /**
     * 
     * @type {Set<OperationTypeV0>}
     * @memberof OperationLogSearchQueryV0
     */
    operationTypes: Set<OperationTypeV0>;
    /**
     * skip results up to and including this token
     * @type {string}
     * @memberof OperationLogSearchQueryV0
     */
    skiptoken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OperationLogSearchQueryV0
     */
    sortByTimestamp?: OperationLogSearchQueryV0SortByTimestampEnum;
    /**
     * 
     * @type {Date}
     * @memberof OperationLogSearchQueryV0
     */
    start: Date;
    /**
     * 
     * @type {Set<string>}
     * @memberof OperationLogSearchQueryV0
     */
    userEmails?: Set<string> | null;
}


/**
 * @export
 */
export const OperationLogSearchQueryV0SortByTimestampEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type OperationLogSearchQueryV0SortByTimestampEnum = typeof OperationLogSearchQueryV0SortByTimestampEnum[keyof typeof OperationLogSearchQueryV0SortByTimestampEnum];


/**
 * Check if a given object implements the OperationLogSearchQueryV0 interface.
 */
export function instanceOfOperationLogSearchQueryV0(value: object): value is OperationLogSearchQueryV0 {
    if (!('end' in value) || value['end'] === undefined) return false;
    if (!('operationTypes' in value) || value['operationTypes'] === undefined) return false;
    if (!('start' in value) || value['start'] === undefined) return false;
    return true;
}

export function OperationLogSearchQueryV0FromJSON(json: any): OperationLogSearchQueryV0 {
    return OperationLogSearchQueryV0FromJSONTyped(json, false);
}

export function OperationLogSearchQueryV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationLogSearchQueryV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'backtoken': json['backtoken'] == null ? undefined : json['backtoken'],
        'end': (new Date(json['end'])),
        'limit': json['limit'] == null ? undefined : json['limit'],
        'operationTypes': (new Set((json['operation_types'] as Array<any>).map(OperationTypeV0FromJSON))),
        'skiptoken': json['skiptoken'] == null ? undefined : json['skiptoken'],
        'sortByTimestamp': json['sort_by_timestamp'] == null ? undefined : json['sort_by_timestamp'],
        'start': (new Date(json['start'])),
        'userEmails': json['user_emails'] == null ? undefined : new Set(json['user_emails']),
    };
}

export function OperationLogSearchQueryV0ToJSON(json: any): OperationLogSearchQueryV0 {
    return OperationLogSearchQueryV0ToJSONTyped(json, false);
}

export function OperationLogSearchQueryV0ToJSONTyped(value?: OperationLogSearchQueryV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'backtoken': value['backtoken'],
        'end': ((value['end']).toISOString()),
        'limit': value['limit'],
        'operation_types': (Array.from(value['operationTypes'] as Set<any>).map(OperationTypeV0ToJSON)),
        'skiptoken': value['skiptoken'],
        'sort_by_timestamp': value['sortByTimestamp'],
        'start': ((value['start']).toISOString()),
        'user_emails': value['userEmails'] == null ? undefined : Array.from(value['userEmails'] as Set<any>),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessPolicyIdV0 } from './AccessPolicyIdV0';
import {
    AccessPolicyIdV0FromJSON,
    AccessPolicyIdV0FromJSONTyped,
    AccessPolicyIdV0ToJSON,
    AccessPolicyIdV0ToJSONTyped,
} from './AccessPolicyIdV0';
import type { AccessPolicyOptionV0 } from './AccessPolicyOptionV0';
import {
    AccessPolicyOptionV0FromJSON,
    AccessPolicyOptionV0FromJSONTyped,
    AccessPolicyOptionV0ToJSON,
    AccessPolicyOptionV0ToJSONTyped,
} from './AccessPolicyOptionV0';

/**
 * 
 * @export
 * @interface NamedAccessPolicyWithOptionsV0
 */
export interface NamedAccessPolicyWithOptionsV0 {
    /**
     * 
     * @type {AccessPolicyIdV0}
     * @memberof NamedAccessPolicyWithOptionsV0
     */
    id: AccessPolicyIdV0;
    /**
     * 
     * @type {Array<AccessPolicyOptionV0>}
     * @memberof NamedAccessPolicyWithOptionsV0
     */
    options: Array<AccessPolicyOptionV0>;
    /**
     * 
     * @type {string}
     * @memberof NamedAccessPolicyWithOptionsV0
     */
    type: NamedAccessPolicyWithOptionsV0TypeEnum;
}


/**
 * @export
 */
export const NamedAccessPolicyWithOptionsV0TypeEnum = {
    Named: 'named'
} as const;
export type NamedAccessPolicyWithOptionsV0TypeEnum = typeof NamedAccessPolicyWithOptionsV0TypeEnum[keyof typeof NamedAccessPolicyWithOptionsV0TypeEnum];


/**
 * Check if a given object implements the NamedAccessPolicyWithOptionsV0 interface.
 */
export function instanceOfNamedAccessPolicyWithOptionsV0(value: object): value is NamedAccessPolicyWithOptionsV0 {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('options' in value) || value['options'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function NamedAccessPolicyWithOptionsV0FromJSON(json: any): NamedAccessPolicyWithOptionsV0 {
    return NamedAccessPolicyWithOptionsV0FromJSONTyped(json, false);
}

export function NamedAccessPolicyWithOptionsV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): NamedAccessPolicyWithOptionsV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'id': AccessPolicyIdV0FromJSON(json['id']),
        'options': ((json['options'] as Array<any>).map(AccessPolicyOptionV0FromJSON)),
        'type': json['type'],
    };
}

export function NamedAccessPolicyWithOptionsV0ToJSON(json: any): NamedAccessPolicyWithOptionsV0 {
    return NamedAccessPolicyWithOptionsV0ToJSONTyped(json, false);
}

export function NamedAccessPolicyWithOptionsV0ToJSONTyped(value?: NamedAccessPolicyWithOptionsV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': AccessPolicyIdV0ToJSON(value['id']),
        'options': ((value['options'] as Array<any>).map(AccessPolicyOptionV0ToJSON)),
        'type': value['type'],
    };
}


import type { SearchConditionV0 } from '@/api/models';
import type { DateRange } from '@/types/DateRange';
import type { RowLimitOption } from '@/types/Pagination';
import type { SearchRangeOption } from '@/types/SearchRange';
import type { SearchConditionWithId, SearchReferrerOptions } from '@/types/SearchSettings';
import { DEFAULT_LIMIT_VALUE } from '@/types/Pagination';

import { schemas } from '@/api/zod';

import '@/plugins/dayjs';

import dayjs from 'dayjs';
import qs from 'qs';
import { z } from 'zod';

type SearchConditionModel = {
  field: string;
  type: string;
  values: string[];
};

export type QueryParameterModel = {
  referrer?: SearchReferrerOptions;
  conditions: SearchConditionV0[] | SearchConditionWithId[];
  limit: RowLimitOption;
  sanitizeValues?: boolean;
} & (
  | {
      rangeIndex: 'custom';
      dates: DateRange;
    }
  | {
      rangeIndex: Exclude<SearchRangeOption, 'custom'>;
      dates?: DateRange;
    }
);

export interface ParseResult {
  referrer?: SearchReferrerOptions;
  conditions: SearchConditionWithId[];
  dates?: DateRange;
  limit?: RowLimitOption;
  rangeIndex: SearchRangeOption;
}

/**
 * Helper function to generate a URL query string for the Archive Search page.
 * @param dataModel The input data required to generate the query string
 * @param sanitizeValues Set to `true` to remove sensitive data from the query string.
 * @returns The serialized data as a query string
 */
export function generateQueryString(request: QueryParameterModel): string {
  type ValueType =
    | SearchRangeOption
    | RowLimitOption
    | Omit<SearchReferrerOptions, ''>
    | Date
    | Partial<SearchConditionModel>[];

  // Note: Object key order matters here as it will determine the order of the keys in the query string.
  const queryObject: Record<string, ValueType> = {};

  if (request.referrer) queryObject.referrer = request.referrer;

  queryObject.date_range = request.rangeIndex;

  if (request.rangeIndex === 'custom') {
    queryObject.start = request.dates.start;
    queryObject.end = request.dates.end;
  }

  if (request.limit !== DEFAULT_LIMIT_VALUE) queryObject.limit = request.limit;

  if (request.conditions.length) {
    queryObject.conditions = request.conditions.map((condition) => {
      if (request.sanitizeValues) {
        // Make sure we don't send "values" for sensitive fields
        if (condition.field === 'storage_status') {
          return {
            field: condition.field,
            values: condition.values,
          };
        } else {
          return {
            field: condition.field,
            type: condition.type,
          };
        }
      } else {
        if ('id' in condition) {
          const { id, ...rest } = condition;
          return rest;
        } else return condition;
      }
    });
  }

  function filter(prefix: string, value: ValueType) {
    if (/^conditions?\[\d+\]$/.test(prefix)) {
      return JSON.stringify(value, (_key, value) =>
        typeof value === 'string' ? encodeURIComponent(value) : value,
      );
    }

    if (value instanceof Date) {
      return value.toISOString();
    }

    return value;
  }

  const result = qs.stringify(queryObject, {
    encode: false,
    format: 'RFC1738',
    filter,
  });

  return result;
}

/**
 * This parses the query string into an internal, validated model for further processing.
 * General rule is to allow other unused keys to pass through,
 * but if there is a malformed value for a known key, we throw away the query string.
 * @param queryString
 * @returns the parse result if the query string was successfully validated, `null` otherwise.
 * @throws `ZodError` | `NobitaParseError` | `Error` if the query string could not be parsed for some reason.
 */
export function parseQueryString(queryString: string, timezone: string): ParseResult | null {
  if (queryString.length <= 1) return null; // accounts for possible '?' prefix

  let conditions: SearchConditionWithId[] = [];

  // Will throw on invalid data
  const params = qs.parse(queryString, { ignoreQueryPrefix: true });

  // Handle conditions array separately because of unique JSON parse logic
  if (params.conditions) {
    try {
      if (Array.isArray(params.conditions)) {
        params.conditions = params.conditions.map((item) =>
          // Will throw on invalid data
          typeof item === 'string' ? JSON.parse(item) : item,
        );
      }
    } catch (e: unknown) {
      logger.debug('A JSON parsing error occurred.', e);
      throw new NobitaParseError('A JSON parsing error occurred.'); // TODO: add { cause: e } if it's supported
    }

    // Per the API spec, this schema also guarantees max 10 conditions
    const ZConditionsSchema = schemas.ArchiveSearchQueryParameters.shape.conditions;

    const parsed = ZConditionsSchema.parse(params.conditions);

    // in future, if want to deviate between the zod types and the internal
    // state types, we do the transform here.

    // Assign an arbitrary incremented index to these conditions
    let num = 0;
    conditions = parsed.map((item) => ({ ...item, id: num++ }));
  }

  const earliestDatePossible = new Date(0);
  const endOfToday = dayjs().tz(timezone).endOf('day').toDate();

  const referrerSchema = z.enum(['', 'history', 'tadrill']).catch(''); // Will default to '' if invalid

  const limitSchema = z
    .string()
    .pipe(z.coerce.number().int().gte(0).lte(200))
    .pipe(ZRowLimitOptions)
    .catch(DEFAULT_LIMIT_VALUE); // Will default to DEFAULT_LIMIT_VALUE if invalid

  const ZQueryParamSchema = z
    .object({
      date_range: z.enum(SearchRangeOptions).exclude(['custom']),
      limit: limitSchema,
      referrer: referrerSchema,
    })
    .or(
      z.object({
        start: z.coerce.date().min(earliestDatePossible).max(endOfToday),
        end: z.coerce.date().min(earliestDatePossible).max(endOfToday),
        date_range: z.literal('custom').optional().default('custom'),
        limit: limitSchema,
        referrer: referrerSchema,
      }),
    );

  // Will throw on invalid data
  const datafromQueryString = ZQueryParamSchema.parse(params);

  const result: ParseResult = {
    conditions: conditions,
    limit: datafromQueryString.limit ? datafromQueryString.limit : undefined,
    rangeIndex: datafromQueryString.date_range,
  };

  if (datafromQueryString.referrer) {
    result.referrer = datafromQueryString.referrer;
  }

  if (datafromQueryString.date_range === 'custom') {
    if (datafromQueryString.start >= datafromQueryString.end) {
      throw new NobitaParseError('Start date cannot be after end date');
    }

    result.dates = {
      start: dayjs(datafromQueryString.start).tz(timezone).startOf('minute').toDate(),
      end: dayjs(datafromQueryString.end).tz(timezone).endOf('minute').toDate(),
    };
  }

  return result;
}

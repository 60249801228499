import type { SearchConditionV0 } from '@/api/models';
import type { DateRange } from '@/types/DateRange';
import type { SearchRangeOption } from '@/types/SearchRange';
import type {
  SearchConditionWithId,
  SearchReferrerOptions,
  SearchSettingsStoreType,
} from '@/types/SearchSettings';

import { generateQueryString, parseQueryString } from '@/utils/parameters/ArchiveSearch';

import { cloneDeep } from 'lodash-es';
import { ZodError } from 'zod';

const getInitialState = (): SearchSettingsStoreType => {
  const { tenant } = useStores('tenant');

  return {
    dates: SearchRanges[DEFAULT_SEARCH_RANGE_OPTION].getDateRange(tenant.timezone),
    rangeIndex: DEFAULT_SEARCH_RANGE_OPTION,
    referrer: '',
    anyCondition: '',
    conditions: [],
  };
};

export const useSearchSettingsStore = defineStore('searchSettings', () => {
  const state = reactive<SearchSettingsStoreType>(getInitialState());

  /**
   * This parses the query string for the Archive Search page's search settings,
   * and saves the values in the store.
   * General rule is to allow other unused keys to pass through,
   * but if there is a malformed value for a known key, we throw away the query string.
   * @param queryString Defaults to `location.search`
   * @returns `true` if the parsed query string requires opening the archive search modal; `false` otherwise.
   * @throws `ZodError` | `NobitaParseError` | `Error` if the query string could not be parsed for some reason.
   */
  function parseArchiveSearchQueryString(queryString: string = location.search): boolean {
    const { archiveSearch, tenant } = useStores('archiveSearch', 'tenant');

    logger.debug(`Parsing URL Query String: '${queryString}'`);

    try {
      // Will throw on invalid data
      const parseResult = parseQueryString(queryString, tenant.timezone);

      if (parseResult === null) {
        return false;
      }

      if (parseResult.conditions) {
        state.conditions = parseResult.conditions;

        // set anyCondition if it exists
        const index = state.conditions.findIndex((condition) => condition.field === 'any');
        if (index > -1 && isAnyTypeSearchCondition(state.conditions[index])) {
          state.anyCondition = state.conditions[index].values[0];
        }
      }

      if (parseResult.referrer) {
        state.referrer = parseResult.referrer;
      }

      if (parseResult.limit) {
        archiveSearch.setLimit(parseResult.limit);
      }

      state.rangeIndex = parseResult.rangeIndex;

      if (parseResult.dates) {
        state.dates = parseResult.dates;
      }

      // These are the conditions under which the archive search conditions modal should be auto-opened
      //  - if there are any non-"free text search" conditions
      //  - if there is a known referrer (previously confirmed above via the Zod schema)
      return (
        parseResult.conditions.length > 1 ||
        (parseResult.conditions.length === 1 && parseResult.conditions[0].field !== 'any') ||
        !!parseResult.referrer
      );
    } catch (e: unknown) {
      if (e instanceof ZodError) {
        logger.debug('A known Zod Error has occurred');
        logger.debug(e.message);
      } else if (e instanceof NobitaParseError) {
        logger.debug('Nobita Parse Error:', e.message);
      } else {
        // This was a real error we were not expecting, so throw it as such
        throw e;
      }

      throw new NobitaParseError('Could not parse query parameters.'); // include {cause: e} once supported
    }
  }

  return {
    ...toRefs(state),

    $reset: () => {
      Object.assign(state, getInitialState());
    },

    /**
     * This is the main query string generated for use in the user's browser URL.
     */
    queryString: computed<string>(() => {
      const archiveSearch = useArchiveSearchStore();

      const query = generateQueryString({
        referrer: state.referrer,
        conditions: state.conditions,
        dates: state.dates,
        limit: archiveSearch.limit,
        rangeIndex: state.rangeIndex,
        sanitizeValues: false,
      });

      return query;
    }),

    /**
     * This is to be used for analytics reporting. We don't want to include sensitive data from the query URL in that case.
     */
    sanitizedQueryString: computed<string>(() => {
      const archiveSearch = useArchiveSearchStore();

      const query = generateQueryString({
        referrer: state.referrer,
        conditions: state.conditions,
        dates: state.dates,
        limit: archiveSearch.limit,
        rangeIndex: state.rangeIndex,
        sanitizeValues: true,
      });

      return query;
    }),
    parseArchiveSearchQueryString,

    // dates & date range
    setDates(params: DateRange): void {
      state.dates = { ...params };
    },

    setDateRange(range: DateRange): void {
      state.dates = range;
    },

    setRangeIndex(index: SearchRangeOption): void {
      state.rangeIndex = index;
    },

    setReferrer(referrer: SearchReferrerOptions): void {
      state.referrer = referrer;
    },

    // conditions
    setCondition(index: number, params: SearchConditionV0): void {
      state.conditions[index] = { ...cloneDeep(params), id: state.conditions[index].id };
    },

    setConditions(params: SearchConditionWithId[]): void {
      state.conditions = cloneDeep(params);
    },

    setAnyCondition(value: string): void {
      state.anyCondition = value;

      const index = state.conditions.findIndex(isAnyTypeSearchCondition);
      if (index > -1 && isAnyTypeSearchCondition(state.conditions[index])) {
        if (value) {
          state.conditions[index].values = [value];
        } else {
          state.conditions.splice(index, 1);
        }
      } else if (value) {
        state.conditions.unshift({
          field: 'any',
          type: 'contain',
          values: [value],
          id: getNewConditionId(),
        });
      }
    },

    conditionsExcludingKeyword: computed<SearchConditionWithId[]>(() =>
      state.conditions.filter((condition) => !isAnyTypeSearchCondition(condition)),
    ),

    cleanConditions(): void {
      const temp: SearchConditionWithId[] = [];

      for (const condition of state.conditions) {
        if (condition.field === 'has_attachment' || condition.values.length) {
          temp.push(condition);
        }
      }

      state.conditions = cloneDeep(temp);
    },
  };
});

export default useSearchSettingsStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearchSettingsStore, import.meta.hot));
}

/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CcSearchConditionV0
 */
export interface CcSearchConditionV0 {
    /**
     * 
     * @type {string}
     * @memberof CcSearchConditionV0
     */
    field: CcSearchConditionV0FieldEnum;
    /**
     * 
     * @type {string}
     * @memberof CcSearchConditionV0
     */
    type: CcSearchConditionV0TypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CcSearchConditionV0
     */
    values: Array<string>;
}


/**
 * @export
 */
export const CcSearchConditionV0FieldEnum = {
    Cc: 'cc'
} as const;
export type CcSearchConditionV0FieldEnum = typeof CcSearchConditionV0FieldEnum[keyof typeof CcSearchConditionV0FieldEnum];

/**
 * @export
 */
export const CcSearchConditionV0TypeEnum = {
    Contain: 'contain',
    ContainNotOnly: 'contain_not_only',
    Match: 'match',
    NotContain: 'not_contain',
    NotMatch: 'not_match'
} as const;
export type CcSearchConditionV0TypeEnum = typeof CcSearchConditionV0TypeEnum[keyof typeof CcSearchConditionV0TypeEnum];


/**
 * Check if a given object implements the CcSearchConditionV0 interface.
 */
export function instanceOfCcSearchConditionV0(value: object): value is CcSearchConditionV0 {
    if (!('field' in value) || value['field'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function CcSearchConditionV0FromJSON(json: any): CcSearchConditionV0 {
    return CcSearchConditionV0FromJSONTyped(json, false);
}

export function CcSearchConditionV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): CcSearchConditionV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'field': json['field'],
        'type': json['type'],
        'values': json['values'],
    };
}

export function CcSearchConditionV0ToJSON(json: any): CcSearchConditionV0 {
    return CcSearchConditionV0ToJSONTyped(json, false);
}

export function CcSearchConditionV0ToJSONTyped(value?: CcSearchConditionV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'field': value['field'],
        'type': value['type'],
        'values': value['values'],
    };
}


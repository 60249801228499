<template>
  <h1 id="GlobalHeaderTitle" class="Title">
    <BaseIcon class="ProductIcon" size="lg">
      <NobitaIconLogo />
    </BaseIcon>

    <div>
      <BaseIcon class="SiteIcon">
        <NobitaIconHenngeOne class="HenngeLogo" />
      </BaseIcon>
      <BaseIcon class="ProductNameIcon">
        <NobitaIconEmailArchive class="HenngeLogo" />
      </BaseIcon>
    </div>
  </h1>
</template>

<style scoped lang="scss">
// See global.scss for .GlobalAction, .GlobalActionHolder styles

.Title {
  display: flex;
  align-items: center;
  color: black();
  font-weight: $font-weight-medium;
  font-size: 18px;
  line-height: 1;
  margin: 0;
  gap: 6px;
  text-decoration: none;
}

.SiteIcon {
  width: 66px;
  height: auto;
  margin-bottom: 4px;
}

.ProductNameIcon {
  width: auto;
  height: 14px;
}

.ProductIcon {
  background-color: primary(700);
  border-radius: 4px;
  color: white();
}
</style>

/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Values returned by list endpoint.
 * @export
 * @interface CustomSearchPolicyInListV0
 */
export interface CustomSearchPolicyInListV0 {
    /**
     * 
     * @type {Date}
     * @memberof CustomSearchPolicyInListV0
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof CustomSearchPolicyInListV0
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomSearchPolicyInListV0
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CustomSearchPolicyInListV0
     */
    type: CustomSearchPolicyInListV0TypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof CustomSearchPolicyInListV0
     */
    updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof CustomSearchPolicyInListV0
     */
    userCount: number;
}


/**
 * @export
 */
export const CustomSearchPolicyInListV0TypeEnum = {
    Custom: 'custom'
} as const;
export type CustomSearchPolicyInListV0TypeEnum = typeof CustomSearchPolicyInListV0TypeEnum[keyof typeof CustomSearchPolicyInListV0TypeEnum];


/**
 * Check if a given object implements the CustomSearchPolicyInListV0 interface.
 */
export function instanceOfCustomSearchPolicyInListV0(value: object): value is CustomSearchPolicyInListV0 {
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('userCount' in value) || value['userCount'] === undefined) return false;
    return true;
}

export function CustomSearchPolicyInListV0FromJSON(json: any): CustomSearchPolicyInListV0 {
    return CustomSearchPolicyInListV0FromJSONTyped(json, false);
}

export function CustomSearchPolicyInListV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomSearchPolicyInListV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'id': json['id'],
        'name': json['name'],
        'type': json['type'],
        'updatedAt': (new Date(json['updated_at'])),
        'userCount': json['user_count'],
    };
}

export function CustomSearchPolicyInListV0ToJSON(json: any): CustomSearchPolicyInListV0 {
    return CustomSearchPolicyInListV0ToJSONTyped(json, false);
}

export function CustomSearchPolicyInListV0ToJSONTyped(value?: CustomSearchPolicyInListV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'created_at': ((value['createdAt']).toISOString()),
        'id': value['id'],
        'name': value['name'],
        'type': value['type'],
        'updated_at': ((value['updatedAt']).toISOString()),
        'user_count': value['userCount'],
    };
}


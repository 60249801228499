import type { TenantStore } from '@/store/tenant';
import type {
  RouteComponent,
  RouteLocationNamedRaw,
  RouteLocationPathRaw,
  RouteRecordRaw,
} from 'vue-router';

// Custom Types

export type NobitaPrivilegedRouteName = keyof TenantStore['authorizedPages'];
export type NobitaSystemRouteName = '404' | 'NoPermissions' | 'Home' | 'Login' | 'Authorisation';

// All known route names:
export type NobitaRouteName = NobitaPrivilegedRouteName | NobitaSystemRouteName;

export type NobitaRouteRecordRaw = RouteRecordRaw & {
  name: NobitaRouteName;
  // ensures we are doing dynamic imports for these routes
  component: RouteComponent | (() => Promise<typeof import('*.vue')>);
};

export type NobitaRouteLocationRaw =
  | string
  | RouteLocationPathRaw
  | (RouteLocationNamedRaw & {
      name: NobitaRouteName;
    });

// Possible Routes

export const Route_ArchiveSearch = {
  path: '/archive-search',
  name: 'archiveSearch',
  component: dynamic(() => import('@/views/email-archive/ArchiveSearch.vue')),
} as const satisfies NobitaRouteRecordRaw;

export const Route_ArchiveStatistics = {
  path: '/archive-statistics',
  name: 'archiveStatistics',
  component: dynamic(() => import('@/views/email-archive/ArchiveStatistics.vue')),
} as const satisfies NobitaRouteRecordRaw;

export const Route_SearchHistory = {
  path: '/search-history',
  name: 'searchHistory',
  component: dynamic(() => import('@/views/email-archive/SearchHistory.vue')),
} as const satisfies NobitaRouteRecordRaw;

export const Route_ArchiveSettings = {
  path: '/archive-settings',
  name: 'archiveSettings',
  component: dynamic(() => import('@/views/email-archive/ArchiveSettings.vue')),
} as const satisfies NobitaRouteRecordRaw;

export const Route_ConsoleSettings = {
  path: '/console-settings',
  name: 'consoleSettings',
  component: dynamic(() => import('@/views/console/ConsoleSettings.vue')),
} as const satisfies NobitaRouteRecordRaw;

export const Route_UserSettings = {
  path: '/user-settings',
  name: 'userSettings',
  component: dynamic(() => import('@/views/console/UserSettings.vue')),
} as const satisfies NobitaRouteRecordRaw;

export const Route_SearchPolicySettings = {
  path: '/search-policy-settings',
  name: 'searchPolicySettings',
  component: dynamic(() => import('@/views/console/SearchPolicySettings.vue')),
} as const satisfies NobitaRouteRecordRaw;

export const Route_BulkDownload = {
  path: '/bulk-download',
  name: 'bulkDownload',
  component: dynamic(() => import('@/views/job-list/BulkDownload.vue')),
} as const satisfies NobitaRouteRecordRaw;

export const Route_OperationLog = {
  path: '/operation-log',
  name: 'operationLog',
  component: dynamic(() => import('@/views/console/OperationLog.vue')),
} as const satisfies NobitaRouteRecordRaw;

export const Route_EmailView = {
  path: '/email/:uidl',
  name: 'emailView',
  component: dynamic(() => import('@/views/email-archive/EmailView.vue')),
} as const satisfies NobitaRouteRecordRaw;

// Fallback Routes
export const Route_NoPermissions = {
  path: '',
  name: 'NoPermissions',
  component: dynamic(() => import('@/views/ErrorView.vue')),
  props: { type: 'permission-required' },
} as const satisfies NobitaRouteRecordRaw;

export const Route_404 = {
  path: '/:unknownPage(.*)*',
  name: '404',
  component: dynamic(() => import('@/views/ErrorView.vue')),
} as const satisfies NobitaRouteRecordRaw;

// These are in order of FALLBACK preference, from top to bottom.
// If the first route is not available for a user, the second route will be tried, and so on.
// prettier-ignore
export const orderedPageRouteMap: Record<NobitaPrivilegedRouteName, NobitaRouteRecordRaw> = {
  archiveSearch:        Route_ArchiveSearch,
  userSettings:         Route_UserSettings,
  searchPolicySettings: Route_SearchPolicySettings,
  operationLog:         Route_OperationLog,
  archiveSettings:      Route_ArchiveSettings,
  searchHistory:        Route_SearchHistory,
  archiveStatistics:    Route_ArchiveStatistics,
  bulkDownload:         Route_BulkDownload,
  consoleSettings:      Route_ConsoleSettings,

  // Routes that are not visible in the Nav can go below here.
  emailView:            Route_EmailView,
} as const;

/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SearchEntryV0 } from './SearchEntryV0';
import {
    SearchEntryV0FromJSON,
    SearchEntryV0FromJSONTyped,
    SearchEntryV0ToJSON,
    SearchEntryV0ToJSONTyped,
} from './SearchEntryV0';

/**
 * 
 * @export
 * @interface SearchHistoryV0
 */
export interface SearchHistoryV0 {
    /**
     * 
     * @type {Array<SearchEntryV0>}
     * @memberof SearchHistoryV0
     */
    searches?: Array<SearchEntryV0>;
}

/**
 * Check if a given object implements the SearchHistoryV0 interface.
 */
export function instanceOfSearchHistoryV0(value: object): value is SearchHistoryV0 {
    return true;
}

export function SearchHistoryV0FromJSON(json: any): SearchHistoryV0 {
    return SearchHistoryV0FromJSONTyped(json, false);
}

export function SearchHistoryV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchHistoryV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'searches': json['searches'] == null ? undefined : ((json['searches'] as Array<any>).map(SearchEntryV0FromJSON)),
    };
}

export function SearchHistoryV0ToJSON(json: any): SearchHistoryV0 {
    return SearchHistoryV0ToJSONTyped(json, false);
}

export function SearchHistoryV0ToJSONTyped(value?: SearchHistoryV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'searches': value['searches'] == null ? undefined : ((value['searches'] as Array<any>).map(SearchEntryV0ToJSON)),
    };
}


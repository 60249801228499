<template>
  <header class="DomainTemplateHeader">
    <menu class="Holder" role="menubar" :aria-label="$t('globalHeader.mainMenu')">
      <li v-click-outside="() => hideDropdown('language')" role="none" class="GlobalActionHolder">
        <button
          type="button"
          role="menuitem"
          data-testid="LanguageAction"
          class="GlobalAction GlobalAction-alt MenuAction"
          :class="{ 'GlobalAction-active': isLanguageActive }"
          :aria-expanded="isLanguageActive"
          aria-haspopup="menu"
          tabindex="0"
          @click="() => toggleDropdown('language')"
        >
          <BaseIcon class="Icon">
            <NobitaIconLanguage />
          </BaseIcon>
          <span class="Name">
            {{ $t(`langs.${locale}`) }}
          </span>
          <BaseIcon class="CaretIcon">
            <NobitaIconCaretDown />
          </BaseIcon>
        </button>

        <LanguageMenu
          class="GlobalMenu"
          context="domain"
          :active="isLanguageActive"
          @close="() => hideDropdown('language')"
        />
      </li>
    </menu>
  </header>
</template>

<script setup lang="ts">
type MenuType = 'language';
const { locale } = useI18n();

const activeDropdown = ref<MenuType | null>(null);

const isLanguageActive = computed(() => activeDropdown.value === 'language');

function toggleDropdown(menu: MenuType) {
  if (activeDropdown.value === menu) {
    activeDropdown.value = null;
  } else {
    activeDropdown.value = menu;
  }
}

function hideDropdown(menu: MenuType) {
  if (activeDropdown.value === menu) activeDropdown.value = null;
}
</script>

<style scoped lang="scss">
.DomainTemplateHeader {
  display: flex;
  height: $global-header-height;
  position: absolute;
  top: 16px;
  right: 0;
  z-index: 700;
  width: 100%;
}

.Holder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;
  margin: 0;
  width: 100%;
  gap: 8px;
}

.Name {
  font-size: 14px;
  line-height: 1;
}

.MenuAction {
  width: auto;
  padding: 0 12px;
  gap: 8px;
}

.Icon {
  display: block;
  cursor: pointer;
}

.CaretIcon {
  display: block;
  cursor: pointer;
}

.GlobalMenu {
  display: block;
  padding-top: 8px;
}
</style>

export interface SearchSaveState {
  saves: string[];
  saveIndex: number;
}

const updateLocalStorage = (saves: string[]) => {
  localStorage.setItem('archiveSearchSaves', JSON.stringify(saves));
};

export const useSearchSaveStore = defineStore('searchSave', {
  state: (): SearchSaveState => ({
    saves: localStorage.archiveSearchSaves ? JSON.parse(localStorage.archiveSearchSaves) : [],
    saveIndex: 0,
  }),

  actions: {
    setSaveIndex(index: number): void {
      this.saveIndex = index;
    },

    addSave(settings: string): void {
      this.saves.push(settings);
      this.saveIndex = this.saveIndex !== null ? this.saveIndex + 1 : 0;
    },

    deleteSaveIndex(index: number): void {
      this.saves.splice(index, 1);
      updateLocalStorage(this.saves);
    },

    updateSave(index: number, settings: string): void {
      this.saves[index] = settings;
      updateLocalStorage(this.saves);
    },
  },
});

export default useSearchSaveStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearchSaveStore, import.meta.hot));
}

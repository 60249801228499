<template>
  <section class="FeedbackThankSection">
    <h3 class="FeedbackThankTitle">{{ $t('feedback.thankMessageTitle') }}</h3>

    <template v-if="isAngry">
      <NobitaIconPictogramPaperAirplane class="FeedbackImage" />
      <p class="FeedbackThankMessage">{{ $t('feedback.angryThankMessageContent') }}</p>
    </template>
    <template v-else>
      <NobitaIconPictogramPartyPopper class="FeedbackImage" />
      <p class="FeedbackThankMessage">{{ $t('feedback.happyThankMessageContent') }}</p>
    </template>
  </section>
  <p v-if="isAngry" class="FeedbackThankMessage">
    {{ $t('feedback.angryHelpMessageContent1') }}
    <BaseLink
      :title="$t('page.helpCenter')"
      href="https://support.hdeone.com/hc/categories/204651087-HENNGE-Email-Archive"
    >
      {{ $t('page.helpCenter') }}
    </BaseLink>
    {{ $t('feedback.angryHelpMessageContent2') }}
  </p>
</template>

<script setup lang="ts">
defineProps<{
  isAngry?: boolean;
}>();
</script>

<style scoped lang="scss">
.FeedbackThankSection {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.FeedbackImage {
  height: 120px;
  order: 0;
}

.FeedbackThankTitle {
  order: 1;
  color: black(87);
  font-size: 24px;
  font-weight: $font-weight-normal;
  line-height: 1.2;
  margin: 24px 0;
  text-align: center;
}

.FeedbackThankMessage {
  order: 3;
  color: black(60);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
  text-align: center;
}
</style>

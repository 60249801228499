<template>
  <Transition name="fadein">
    <div
      v-if="active"
      class="Dropdown"
      :class="[`Dropdown-${size}`, `position-${position}`, `align-${align}`, `justify-${justify}`]"
    >
      <ul class="List" role="menu">
        <slot name="items" />
      </ul>
    </div>
  </Transition>
</template>

<script setup lang="ts">
export type Props = {
  active?: boolean;
  position?: 'top' | 'bottom' | 'left' | 'right';
  align?: 'top' | 'center' | 'bottom';
  justify?: 'left' | 'center' | 'right';
  size?: 'default' | 'shrink';
};

withDefaults(defineProps<Props>(), {
  position: 'bottom',
  align: 'bottom',
  justify: 'center',
  size: 'default',
});

defineSlots<{
  items(): void;
}>();
</script>

<style scoped lang="scss">
.Dropdown {
  position: absolute;
  display: block;

  // size
  &-default {
    min-width: 180px;
  }
  &-shrink {
    min-width: none;
    white-space: nowrap;
  }

  // alignment
  &.justify-left {
    left: 0;
    &.position-left {
      left: calc(-100% - 8px);
      padding-right: 8px;
    }
  }
  &.justify-center {
    left: 50%;
    transform: translateX(-50%);
  }
  &.justify-right {
    right: 0;

    &.position-left {
      right: calc(100% + 8px);
      padding-left: 8px;
    }
  }

  &.align {
    &-top {
      top: 0;
    }
    &-center {
      top: 50%;
      transform: translateY(-50%);
    }
    &-bottom {
      top: 100%;
    }
  }

  & .Dropdown {
    z-index: 50;
  }
}

.List {
  list-style: none;
  padding: 8px 0;
  margin: 0;
  background-color: white();

  @include elevationMiddle;
}

@include transitionFadein(
  $styles: (
    (style: opacity, from: 0, to: 1),
    (style: z-index, from: -1, to: 500),
    (style: top, from: calc(100% - 10px), to: 100%),
  )
);
</style>

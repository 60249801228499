/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OperationTypeV0 = {
    CreateSearchPolicy: 'createSearchPolicy',
    CreateUser: 'createUser',
    DefrostMail: 'defrostMail',
    DeleteSearchPolicy: 'deleteSearchPolicy',
    DeleteUser: 'deleteUser',
    DownloadMail: 'downloadMail',
    DownloadMailAttachment: 'downloadMailAttachment',
    DownloadOperationLogs: 'downloadOperationLogs',
    GetAccessToken: 'getAccessToken',
    GetMailDetails: 'getMailDetails',
    GetOperationLogs: 'getOperationLogs',
    Logout: 'logout',
    PreviewMailAttachment: 'previewMailAttachment',
    SearchArchive: 'searchArchive',
    UpdateDomain: 'updateDomain',
    UpdateSearchPolicy: 'updateSearchPolicy',
    UpdateUser: 'updateUser'
} as const;
export type OperationTypeV0 = typeof OperationTypeV0[keyof typeof OperationTypeV0];


export function instanceOfOperationTypeV0(value: any): boolean {
    for (const key in OperationTypeV0) {
        if (Object.prototype.hasOwnProperty.call(OperationTypeV0, key)) {
            if (OperationTypeV0[key as keyof typeof OperationTypeV0] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OperationTypeV0FromJSON(json: any): OperationTypeV0 {
    return OperationTypeV0FromJSONTyped(json, false);
}

export function OperationTypeV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationTypeV0 {
    return json as OperationTypeV0;
}

export function OperationTypeV0ToJSON(value?: OperationTypeV0 | null): any {
    return value as any;
}

export function OperationTypeV0ToJSONTyped(value: any, ignoreDiscriminator: boolean): OperationTypeV0 {
    return value as OperationTypeV0;
}


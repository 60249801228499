import type { Dialog, DialogType } from '@/store/dialog';
import type { Drawer } from '@/store/drawer';

export type OverlayType = DialogType | 'drawer';

export enum OVERLAY_LOCK {
  DIRTY = 'dirty',
  LOADING = 'loading',
  TEMPORARY_ANIMATION_DELAY = 'animation-delay',
}

// Ensure stores that consume this store (e.g. drawer, dialog) at least expose these properties
type OverlayStoreProperty =
  | 'active'
  | 'obscured'
  | 'lock'
  | 'unlock'
  | 'attemptOpen'
  | 'attemptClose';

export type IOverlayStore = Record<OverlayStoreProperty, unknown> & {
  [others: string]: unknown;
};

export interface IOverlay {
  lock(id: OVERLAY_LOCK): boolean;
  unlock(id: OVERLAY_LOCK): boolean;
  readonly locks: Set<OVERLAY_LOCK>;
  readonly locked: boolean;
  readonly data: unknown;
}

export abstract class Overlay implements IOverlay {
  // Protected so that subclasses of Overlay can make use of it
  protected _locks = new Set<OVERLAY_LOCK>();

  lock(id: OVERLAY_LOCK): boolean {
    if (this._locks.has(id)) {
      return false;
    }
    this._locks.add(id);
    return true;
  }

  unlock(id: OVERLAY_LOCK): boolean {
    if (!this._locks.has(id)) {
      return false;
    }
    this._locks.delete(id);
    return true;
  }

  get locks() {
    return this._locks;
  }

  get locked() {
    return this._locks.size > 0;
  }

  abstract get data(): unknown;
}

// Private, localized state. Do not export.
// Pinia advises to use an "internal, second store" to create private state.
const useOverlayPrivateState = defineStore('overlay.private', () => {
  // Internally implement this as a Map.
  // JS guarantees that this Map remains insertion-ordered, so we can use it as a stack.
  // Our implementation will represent a unique stack map, where each key is unique.
  const stack = ref(new Map<OverlayType, Overlay>());
  return { stack };
});

export const useOverlayStore = defineStore('overlay', () => {
  const { stack } = useOverlayPrivateState();

  function attemptPush(overlayName: OverlayType, overlayObject: Overlay): boolean {
    // Do not allow duplicates in the stack
    if (stack.get(overlayName)) return false;

    stack.set(overlayName, overlayObject);
    return true;
  }

  function attemptPop(overlayName: OverlayType): boolean {
    // If the overlay is not on the top of the stack, we can't pop it
    if (topKey.value !== overlayName) return false;

    const isLocked = topObject.value?.locked;

    if (!isLocked) {
      // if so, pop from stack
      stack.delete(topKey.value);
      return true;
    }

    // We can't pop the overlay if it is locked for any reason
    else return false;
  }

  /**
   * Retrieves the topmost overlay in the stack.
   * @returns The top overlay in the stack, if it exists. `undefined` otherwise.
   */
  function peek(): [OverlayType, IOverlay] | undefined {
    return stack.size ? [...stack.entries()].slice(-1)[0] : undefined;
  }

  /**
   * The OverlayType of the topmost overlay in the stack.
   */
  const topKey = computed<OverlayType | undefined>(() => peek()?.[0]);

  /**
   * The class instance of the topmost overlay in the stack.
   */
  const topObject = computed<IOverlay | undefined>(() => peek()?.[1]);

  /**
   * Get the key of the overlay that is 1 lower in the stack than the given key.
   * @param key a string of type `DialogType`. Only dialogs would have overlays under them.
   * @returns Key of the overlay that is 1 lower in the stack than the given key. `undefined` if the key is not found or is at the bottom of the stack.
   */
  function under<T extends DialogType>(key: T): OverlayType | undefined {
    const keys = [...stack.keys()];
    const idx = keys.indexOf(key);

    if (idx <= 0) return undefined;
    else return keys[idx - 1];
  }

  /**
   * Fetch the object associated with the given overlay key from the stack, if it exists in the stack.
   * @param key a string of type `OverlayType`
   */
  function get<T extends DialogType>(key: T): Dialog<T> | undefined;
  function get(key: 'drawer'): Drawer | undefined;
  function get(key: OverlayType) {
    return stack.get(key);
  }

  /**
   * The number of overlays in the stack.
   */
  const count = computed<number>(() => stack.size);

  /**
   * Returns whether or not the overlay with the given key is obscured by another overlay.
   * I.e. this means the overlay with the given key is active, but not at the top of the stack.
   * @param key
   * @returns `true` if the overlay is obscured, `false` otherwise.
   */
  function obscured(key: OverlayType): boolean {
    return !!stack.get(key) && topKey.value !== key;
  }

  return {
    get,
    count,
    topKey,
    topObject,
    obscured,
    under,
    attemptPop,
    attemptPush,
  };
});

export default useOverlayStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOverlayStore, import.meta.hot));
}

<template>
  <GlobalHeader />
  <div id="content" v-scroll-lock="isScrollLocked" class="Wrapper">
    <BaseNav v-if="!hideNav" />
    <RouterView />
  </div>

  <BaseLoader v-if="loader.isLoading" fullscreen />
  <SnackbarsContainer />
  <FeedbackDialog />
</template>

<script setup lang="ts">
defineProps<{
  hideNav?: boolean;
}>();

const { overlay, loader } = useStores('overlay', 'loader');

// scroll locking
const isScrollLocked = computed(() => overlay.count > 0);
</script>

<style lang="scss" scoped>
.Wrapper {
  position: relative;
  height: calc(100vh - #{$header-height});
  padding-top: $header-height;
  display: flex;
  flex-flow: row nowrap;
}
</style>

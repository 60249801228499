/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PreservationPeriodWithBackupPeriodLt10YearsV0
 */
export interface PreservationPeriodWithBackupPeriodLt10YearsV0 {
    /**
     * A frozen period of 0 to 9 years.
     * @type {string}
     * @memberof PreservationPeriodWithBackupPeriodLt10YearsV0
     */
    frozenPeriod: PreservationPeriodWithBackupPeriodLt10YearsV0FrozenPeriodEnum;
    /**
     * A frozen period from 0 years to 9 years, in 1 year increments, expressed in months.
     * @type {number}
     * @memberof PreservationPeriodWithBackupPeriodLt10YearsV0
     */
    frozenPeriodMonths: number;
    /**
     * 
     * @type {number}
     * @memberof PreservationPeriodWithBackupPeriodLt10YearsV0
     */
    realtimePeriodMonths: number;
}


/**
 * @export
 */
export const PreservationPeriodWithBackupPeriodLt10YearsV0FrozenPeriodEnum = {
    Lt10years: 'lt_10years'
} as const;
export type PreservationPeriodWithBackupPeriodLt10YearsV0FrozenPeriodEnum = typeof PreservationPeriodWithBackupPeriodLt10YearsV0FrozenPeriodEnum[keyof typeof PreservationPeriodWithBackupPeriodLt10YearsV0FrozenPeriodEnum];


/**
 * Check if a given object implements the PreservationPeriodWithBackupPeriodLt10YearsV0 interface.
 */
export function instanceOfPreservationPeriodWithBackupPeriodLt10YearsV0(value: object): value is PreservationPeriodWithBackupPeriodLt10YearsV0 {
    if (!('frozenPeriod' in value) || value['frozenPeriod'] === undefined) return false;
    if (!('frozenPeriodMonths' in value) || value['frozenPeriodMonths'] === undefined) return false;
    if (!('realtimePeriodMonths' in value) || value['realtimePeriodMonths'] === undefined) return false;
    return true;
}

export function PreservationPeriodWithBackupPeriodLt10YearsV0FromJSON(json: any): PreservationPeriodWithBackupPeriodLt10YearsV0 {
    return PreservationPeriodWithBackupPeriodLt10YearsV0FromJSONTyped(json, false);
}

export function PreservationPeriodWithBackupPeriodLt10YearsV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): PreservationPeriodWithBackupPeriodLt10YearsV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'frozenPeriod': json['frozen_period'],
        'frozenPeriodMonths': json['frozen_period_months'],
        'realtimePeriodMonths': json['realtime_period_months'],
    };
}

export function PreservationPeriodWithBackupPeriodLt10YearsV0ToJSON(json: any): PreservationPeriodWithBackupPeriodLt10YearsV0 {
    return PreservationPeriodWithBackupPeriodLt10YearsV0ToJSONTyped(json, false);
}

export function PreservationPeriodWithBackupPeriodLt10YearsV0ToJSONTyped(value?: PreservationPeriodWithBackupPeriodLt10YearsV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'frozen_period': value['frozenPeriod'],
        'frozen_period_months': value['frozenPeriodMonths'],
        'realtime_period_months': value['realtimePeriodMonths'],
    };
}


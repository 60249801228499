<template>
  <div>
    <input
      v-bind="$attrs"
      :id="name"
      v-model="validatedValue"
      :aria-describedby="
        !showError && (showHint || (hasCounter && maxlength)) ? ariaLabel : undefined
      "
      :aria-valid="meta.touched ? meta.valid : undefined"
      :aria-invalid="meta.touched ? !meta.valid : undefined"
      :aria-errormessage="showError ? ariaLabel : undefined"
      :name
      class="Input"
      :class="[{ 'Input-error': showError, 'Input-success': meta.valid }, `Input-${size}`]"
      :type
      :placeholder="placeholder ? $t(placeholder) : undefined"
      @input="handleChange"
      @blur="handleBlur"
      @keyup.enter="$emit('submit', validatedValue)"
    />

    <HelpMessage
      v-if="showHint || (!hasCounter && showError)"
      :id="ariaLabel"
      :type="showError ? 'error' : 'info'"
    >
      <template v-if="hint">{{ $t(hint) }}</template>
      <template v-else-if="error">{{ $t(error) }}</template>
      <template v-else>{{ errorMessage }}</template>
    </HelpMessage>

    <CharactersCounter
      v-if="hasCounter && maxlength"
      :id="ariaLabel"
      :value="validatedValue"
      :maxlength
    />
  </div>
</template>

<script setup lang="ts">
import type { TranslationKey } from '@/locales';

import { useField } from 'vee-validate';

import { toTypedSchema } from '@vee-validate/zod';
import { z, ZodString } from 'zod';

export type Props = {
  name: string;
  placeholder?: TranslationKey | '';
  error?: TranslationKey | undefined;
  hint?: TranslationKey | undefined;
  type?: 'text' | 'email' | 'tel' | 'number' | 'date' | 'password' | 'search' | 'url' | 'time';
  size?: 'md' | 'lg';
  maxlength?: number | undefined;
  hasCounter?: boolean;
  schema?: ZodString;
};

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  size: 'md',
  maxlength: undefined,
  placeholder: '',
  schema: undefined,
  error: undefined,
  hint: undefined,
});

defineEmits<{
  submit: [val: string];
}>();

defineOptions({
  inheritAttrs: false,
});

const schema = computed(() => {
  const s = props.schema ?? z.string();
  if (props.maxlength) return toTypedSchema(s.max(props.maxlength));
  else return toTypedSchema(s);
});

const value = defineModel<string>({ default: '' });

const {
  value: validatedValue,
  handleBlur,
  handleChange,
  errorMessage,
  meta,
} = useField(() => props.name, schema, {
  initialValue: value,
  syncVModel: true,
});

const showError = computed(() => {
  return (
    !!props.error ||
    (props.hasCounter && props.maxlength ? validatedValue.value.length > props.maxlength : false) ||
    (!!errorMessage && meta.touched && !meta.valid)
  );
});

const showHint = computed(() => {
  return !!props.hint && !meta.valid;
});

const ariaLabel = computed(() => {
  return showHint ? `${props.name}-inputHelp` : showError ? `${props.name}-inputError` : '';
});
</script>

<style scoped lang="scss">
.Input {
  border: 1px solid black(12);
  border-radius: 4px;
  width: 100%;
  padding: 8px 16px;
  box-sizing: border-box;
  background-color: white();
  font-size: 14px;

  &:hover:not(:disabled) {
    border-color: black(60);
  }
  &:focus-visible {
    outline: none;
    border-color: primary(700);
  }
  &:disabled {
    background: neutral(50);
    color: neutral(350);
    border: 0;
  }

  // size
  &-md {
    height: 32px;
  }
  &-lg {
    height: 40px;
  }

  &-error {
    border-color: red(400);
  }
  &-success {
    background-color: white(100);
  }

  &::placeholder {
    color: black(60);
    font-size: 14px;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: normal;
  }
}
</style>

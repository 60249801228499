import type { DateRange } from '@/types/DateRange';

export enum JOB_STATUS {
  PROCESSING = 'processing',
  CANCELED = 'canceled',
  DONE = 'done',
}

export interface JobLink {
  name: string;
  url: string;
}

export interface Job {
  id: string;
  email: string;
  status: JOB_STATUS;
  registeredDate: string;
  expirationDate: string;
  searchRange: DateRange;
  timezone: string;
  format: string;
  elapsedTime: string;
  retrievedEmails: number;
  downloadLinks: Array<JobLink>;
  downloadPassword: string;
}

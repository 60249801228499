import type { ErrorInfoV0 } from '@/api';
import type { TranslationKey } from '@/locales';

import { ResponseError } from '@/api';

// prettier-ignore
/**
 * Enum of all possible Nobita Error Codes
 * This should be kept in manual sync with the spec found here:
 *   https://github.com/HENNGE/nobita2-core/blob/master/docs/specs/errors.md
 */
export enum NobitaErrorCode {

  /*------------------*
   * SYSTEM / GENERIC *
   *------------------*/

  /** Catch-all for unidentified or internal errors */
  INTERNAL_ERROR             = 1000,
  /** User requested an entity that does not exist */
  NOT_FOUND                  = 1001,
  /** User submitted malformed/unexpected input
   *
   * @context [Pydantic validation error]
   */
  VALIDATION_FAILED          = 1002,
  /** User requested entity exceeding max download size allowed */
  MAX_DOWNLOAD_SIZE_EXCEEDED = 1003,
  /** The system is undergoing maintenance and is unavailable. */
  SYSTEM_MAINTENANCE         = 1004,
  /** Timed out waiting for backend query (ES, Athena etc). */
  QUERY_TIMEOUT	             = 1005,

  /*---------------------------------------------*
   * SECURITY / AUTHENTICATION AND AUTHORIZATION *
   *---------------------------------------------*/

  /** Catch-all for unidentified authorization/authentication errors
   *
   * @context `onboarding_request: true` if this was the initial onboarding login for a tenant
  */
  AUTH_ERROR            = 1100,
  /** User is not authenticated
   *
   * @context `onboarding_request: true` if this was the initial onboarding login for a tenant
  */
  NOT_AUTHENTICATED     = 1101,
  /** User is not authorized
   *
   * @context `onboarding_request: true` if this was the initial onboarding login for a tenant
  */
  ACCESS_DENIED         = 1102,

  /*------------------*
   * ARCHIVE / SEARCH *
   *------------------*/

  /** Catch-all for unidentified archive search errors */
  SEARCH_FAILED         = 2000,
  /** There was an error parsing an archived mail
   *
   * @context [uidl]
  */
  MAIL_UNPARSABLE       = 2001,
  /** User tried to access a frozen mail */
  MAIL_FROZEN           = 2002,

  /*-------------------*
   * ARCHIVE / DEFROST *
   *-------------------*/

  /** Catch-all for unidentified defrost errors */
  DEFROST_FAILED        = 2100,
  /** User tried to defrost a non-frozen mail */
  DEFROST_INVALID_STATE = 2101,

  /*----------------------*
   * ARCHIVE / STATISTICS *
   *----------------------*/

  /** Catch-all for unidentified archive statistics errors */
  STATS_ERROR           = 2200,
  /** There's no archive statistics for this domain */
  STATS_NO_RECENT       = 2201,

  /*-------------------*
   * ARCHIVE / PREVIEW *
   *-------------------*/

  /** Catch-all for unidentified preview generation errors.
   *
   * @context [uidl, attachment_id]
  */
  ATTACHMENT_PREVIEW_FAILED      = 2300,
  /** The attachment cannot be previewed
   *
   * @context [uidl, attachment_id]
  */
  ATTACHMENT_PREVIEW_UNSUPPORTED = 2301,

  /*----------*
   * FEATURES *
   *----------*/

  /** Catch-all for unidentified feature errors */
  FEATURES_ERROR = 4000,

  /*-----------------*
   * SETTINGS / USER *
   *-----------------*/

  /** The search policy specified in payload context could not be found */
  SEARCH_POLICY_NOT_FOUND = 6000,

  /*--------------------------*
   * SETTINGS / SEARCH POLICY *
   *--------------------------*/

  /** Catch-all for unidentified search policy errors */
  SEARCH_POLICY_ERROR = 6100,

  /*---------------------------*
   * INTERNAL API / ONBOARDING *
   *---------------------------*/

  /** The tenant is not eligible for onboarding
   *
   * @context [eligibility_status]
   */
  TENANT_NOT_ELIGIBLE_FOR_ONBOARDING = 7001,
  /** The email cannot be used for onboarding
   *
   * @context [eligibility_status, email]
   */
  EMAIL_ADDRESS_NOT_ELIGIBLE_FOR_ONBOARDING = 7000,
}

export class NobitaError extends Error {
  override readonly name = 'NobitaError';
  readonly nobitaErrorCode: NobitaErrorCode;
  readonly httpErrorText: string | null;
  readonly httpErrorCode: number | null;

  /**
   *  **Note:** Throws if the code is not a valid Nobita Error Code
   */
  constructor(
    message: string,
    code: number,
    public readonly responseError: ResponseError,
    public readonly context?: object | null,
    public errorTranslationKey?: TranslationKey,
  ) {
    super(message);

    // Check if code is a proper Nobita code
    if (code in NobitaErrorCode) this.nobitaErrorCode = code as NobitaErrorCode;
    else throw new Error(`Invalid Nobita Error Code '${code}'`);

    // Get HTTP info from responseError if available
    this.httpErrorCode = this.responseError.response.status;
    this.httpErrorText = this.responseError.response.statusText;
  }

  /**
   *  **Note:** Throws if the code is not a valid Nobita Error Code
   */
  static createFromErrorInfoObj(
    nobitaErrorInfo: ErrorInfoV0,
    responseError: ResponseError,
    errorTranslationKey?: TranslationKey,
  ): NobitaError {
    return new NobitaError(
      nobitaErrorInfo.message,
      nobitaErrorInfo.code,
      responseError,
      nobitaErrorInfo.context,
      errorTranslationKey,
    );
  }

  toJSON() {
    return {
      name: this.name,
      message: this.message,
      context: this.context,
      nobitaErrorCode: this.nobitaErrorCode,
      nobitaErrorText: NobitaErrorCode[this.nobitaErrorCode],
      httpErrorCode: this.httpErrorCode,
      httpErrorText: this.httpErrorText,
      errorTranslationKey: this.errorTranslationKey,
    };
  }
}

export function isNobitaError(e: unknown): e is NobitaError {
  return e instanceof NobitaError;
}

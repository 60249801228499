import type { Job } from '@/types/Job';

import dayjs from 'dayjs';
import { cloneDeep } from 'lodash-es';

export interface BulkDownloadState {
  jobs: Job[];
  jobView: Job;
}

export const useBulkDownloadStore = defineStore('bulkDownload', {
  state: (): BulkDownloadState => ({
    jobs: [
      {
        id: 'bdl-20210311033100',
        email: 'Lorem',
        status: JOB_STATUS.DONE,
        registeredDate: dayjs().format('YYYY/MM/DD HH:mm'),
        expirationDate: dayjs().format('YYYY/MM/DD'),
        searchRange: {
          start: dayjs().second(0).millisecond(0).toDate(),
          end: dayjs().second(59).millisecond(999).toDate(),
        },
        timezone: 'JST (UTC +09:00)',
        format: 'mbox',
        elapsedTime: '03:13:33',
        retrievedEmails: 123,
        downloadLinks: [
          {
            name: 'test.example.com-3456-1.zip',
            url: 'test.example.com-3456-1.zip',
          },
        ],
        downloadPassword: 'zUBR4uaUMFgXj2LIMeOvAxcBeSDGF15O',
      },
      {
        id: 'bdl-20210311289327',
        email: 'Ipsum',
        status: JOB_STATUS.CANCELED,
        registeredDate: dayjs().format('YYYY/MM/DD HH:mm'),
        expirationDate: dayjs().format('YYYY/MM/DD'),
        searchRange: {
          start: dayjs().second(0).millisecond(0).toDate(),
          end: dayjs().second(59).millisecond(999).toDate(),
        },
        timezone: 'JST (UTC +09:00)',
        format: 'pst',
        elapsedTime: '04:10:13',
        retrievedEmails: 235,
        downloadLinks: [
          { name: 'test.example.com-3149-1.zip', url: 'test.example.com-3149-1.zip' },
          { name: 'test.example.com-3149-2.zip', url: 'test.example.com-3149-2.zip' },
        ],
        downloadPassword: 'qweASDqewqfd9867UMFgXj2LIMeOvAxcBeSDGF15O',
      },
      {
        id: 'bdl-20190311289345',
        email: 'Nobita',
        status: JOB_STATUS.DONE,
        registeredDate: dayjs('2019-09-03 13:03').format('YYYY/MM/DD HH:mm'),
        expirationDate: dayjs('2019-11-03').format('YYYY/MM/DD'),
        searchRange: {
          start: dayjs('2018-09-03').second(0).millisecond(0).toDate(),
          end: dayjs('2019-11-03').second(59).millisecond(999).toDate(),
        },
        timezone: 'JST (UTC +09:00)',
        format: 'pst',
        elapsedTime: '07:13:03',
        retrievedEmails: 987,
        downloadLinks: [
          { name: 'test.example.com-1149-1.zip', url: 'test.example.com-1149-1.zip' },
          { name: 'test.example.com-1149-2.zip', url: 'test.example.com-1149-2.zip' },
        ],
        downloadPassword: 'qweASDqewqfd9867UMFgXj2LIqweqweeF15O',
      },
    ],
    jobView: {
      id: '',
      email: '',
      status: JOB_STATUS.PROCESSING,
      registeredDate: dayjs().format('YYYY/MM/DD HH:mm'),
      expirationDate: dayjs().format('YYYY/MM/DD'),
      searchRange: {
        start: dayjs().second(0).millisecond(0).toDate(),
        end: dayjs().second(59).millisecond(999).toDate(),
      },
      timezone: '',
      format: '',
      elapsedTime: '',
      retrievedEmails: 0,
      downloadLinks: [],
      downloadPassword: '',
    },
  }),

  actions: {
    setJobView(param: Job): void {
      this.jobView = cloneDeep(param);
    },
  },
});

export default useBulkDownloadStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBulkDownloadStore, import.meta.hot));
}

import type { UserV0 } from '@/api';

import { cloneDeep } from 'lodash-es';

export interface UsersState {
  usersList: UserV0[];

  filteredUsers: UserV0[];
  userView: UserV0;
  isFiltered: boolean;
  isEditing: boolean;
}

export const useUsersStore = defineStore('users', () => {
  const state = reactive<UsersState>({
    usersList: [],

    filteredUsers: [],
    userView: {
      id: '',
      email: '',
      enabled: false,
      lastActivity: new Date(0),
      searchPolicyId: '',
      accessPolicy: { type: 'custom', options: [] },
      permissions: [],
      locale: NobitaLocales[0],
      createdAt: new Date(0),
      updatedAt: new Date(0),
    },
    isFiltered: false,
    isEditing: false,
  });

  return {
    ...toRefs(state),

    setUsersList(params: UserV0[]): void {
      this.setFilteredUsers(params);
      state.usersList = params;
    },

    // filtered users
    setFilteredUsers(params: UserV0[]): void {
      state.filteredUsers = params;
    },

    filteredUsersEmail: computed(() => state.filteredUsers.map((e: UserV0) => e.email)),

    resetFilteredUsers(): void {
      state.filteredUsers = state.usersList;
    },

    setUserView(param: UserV0): void {
      state.userView = cloneDeep(param);
    },

    setIsFiltered(param: boolean): void {
      state.isFiltered = param;
    },

    setIsEditing(param: boolean): void {
      state.isEditing = param;
    },

    updateUserListItem(param: UserV0): void {
      const index: number = state.usersList.findIndex((e) => e.id === param.id);
      if (index > -1) {
        state.usersList[index] = param;
      }

      const filteredIndex: number = state.filteredUsers.findIndex((e) => e.id === param.id);
      if (filteredIndex > -1) {
        state.filteredUsers[filteredIndex] = param;
      }
    },

    addUserListItem(param: UserV0): void {
      state.usersList.unshift(param);

      const filteredIndex: number = state.filteredUsers.findIndex((e) => e.id === param.id);
      if (filteredIndex <= -1) {
        state.filteredUsers.unshift(param);
      }
    },

    deleteUserListItem(param: string): void {
      const index: number = state.usersList.findIndex((e) => e.id === param);
      if (index > -1) {
        state.usersList.splice(index, 1);
      }

      const filteredIndex: number = state.filteredUsers.findIndex((e) => e.id === param);
      if (filteredIndex > -1) {
        state.filteredUsers.splice(filteredIndex, 1);
      }
    },

    searchUsersByName(term: string): UserV0[] {
      const res = state.usersList.filter(
        (e) => e.email.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) >= 0,
      );
      return res;
    },
  };
});

export default useUsersStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUsersStore, import.meta.hot));
}

import type { ArchiveStatisticsV0 } from '@/api';

export interface ArchiveStatisticsState {
  stats: ArchiveStatisticsV0 | null;
}

export const useArchiveStatisticsStore = defineStore('archiveStatistics', {
  state: (): ArchiveStatisticsState => ({
    stats: null,
  }),

  actions: {
    setArchiveStatistics(stats: ArchiveStatisticsV0 | null) {
      this.stats = stats;
    },
  },
});

export default useArchiveStatisticsStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useArchiveStatisticsStore, import.meta.hot));
}

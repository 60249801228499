import type { TranslationKey } from '@/locales';
import type { DateRange } from '@/types/DateRange';
import type { OperationLogDownloadCriteria, OperationLogDownloadInfo } from '@/types/OperationLog';
import type { SearchRangeOption } from '@/types/SearchRange';

import dayjs from 'dayjs';

export interface OperationLogDownloadState {
  selectedCriteria: OperationLogDownloadCriteria;
  downloadCriteria: OperationLogDownloadCriteria;
  downloadInfo?: OperationLogDownloadInfo;
  log_downloading: boolean;
  log_download_error: boolean;
  log_preparing: boolean;
  log_prepare_error: boolean;
}

const getDefaultCriteria = (): OperationLogDownloadCriteria => {
  const tenant = useTenantStore();

  return {
    rangeIndex: DEFAULT_OPERATION_LOG_DOWNLOAD_RANGE_OPTION,
    start: SearchRanges[DEFAULT_OPERATION_LOG_DOWNLOAD_RANGE_OPTION].getDateRange(tenant.timezone)
      .start,
    end: SearchRanges[DEFAULT_OPERATION_LOG_DOWNLOAD_RANGE_OPTION].getDateRange(tenant.timezone)
      .end,
  };
};

export const useOperationLogDownloadStore = defineStore('operationLogDownload', {
  state: (): OperationLogDownloadState => ({
    selectedCriteria: getDefaultCriteria(),
    downloadCriteria: getDefaultCriteria(),
    downloadInfo: undefined,
    log_downloading: false,
    log_download_error: false,
    log_preparing: false,
    log_prepare_error: false,
  }),

  getters: {
    // validation
    errorMessage(state): TranslationKey | '' {
      if (dayjs(state.selectedCriteria.start).isAfter(state.selectedCriteria.end)) {
        return 'validation.dateRangeStartAfterEnd';
      } else {
        return '';
      }
    },
  },

  actions: {
    getDateRangeById(id: SearchRangeOption, timezone: string): DateRange {
      return SearchRanges[id].getDateRange(timezone);
    },

    // dates & date range
    async setDates(params: DateRange): Promise<void> {
      this.selectedCriteria.start = params.start;
      this.selectedCriteria.end = params.end;
    },

    async setDownloadCriteria(): Promise<void> {
      this.downloadCriteria.rangeIndex = this.selectedCriteria.rangeIndex;
      this.downloadCriteria.start = this.selectedCriteria.start;
      this.downloadCriteria.end = this.selectedCriteria.end;
    },

    setRangeIndex(index: SearchRangeOption): void {
      this.selectedCriteria.rangeIndex = index;
    },

    /**
     * This reverts the modal's criteria back to the default criteria.
     */
    async cleanCriteria(): Promise<void> {
      this.selectedCriteria.rangeIndex = this.downloadCriteria.rangeIndex;
      this.selectedCriteria.start = this.downloadCriteria.start;
      this.selectedCriteria.end = this.downloadCriteria.end;
    },

    discardChanges(): void {
      // This timeout is required to let the dialog close animation finish before we revert the changes.
      setTimeout(() => {
        this.cleanCriteria();
      }, TRANSITION_MS);
    },

    reset(): void {
      setTimeout(() => {
        this.selectedCriteria = getDefaultCriteria();
        this.downloadCriteria = getDefaultCriteria();
        this.downloadInfo = undefined;
        this.log_downloading = false;
        this.log_download_error = false;
        this.log_preparing = false;
        this.log_prepare_error = false;
      }, TRANSITION_MS);
    },

    setDownloadingStatus(param: boolean): void {
      this.log_downloading = param;
    },
    setDownloadingStatusError(param: boolean): void {
      this.log_download_error = param;
    },

    setPreparingStatus(param: boolean): void {
      this.log_preparing = param;
    },
    setPreparingStatusError(param: boolean): void {
      this.log_prepare_error = param;
    },

    setDownloadInfo(param: OperationLogDownloadInfo): void {
      this.downloadInfo = param;
    },
  },
});

export default useOperationLogSettingsStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOperationLogSettingsStore, import.meta.hot));
}

import { HELP_PANEL_TAGS } from '@/types/HelpPanel';

export interface SidePanelState {
  isActive: boolean;
  currentHighlight: HELP_PANEL_TAGS | '';

  isVisible: boolean;
  inPosition: boolean;
}

export const useSidePanelStore = defineStore('sidePanel', {
  state: (): SidePanelState => ({
    isActive: false,
    currentHighlight: '',

    isVisible: false,
    inPosition: false,
  }),
  actions: {
    open() {
      this.isActive = true;
    },

    close() {
      this.currentHighlight = '';
      this.isActive = false;
    },

    toggle() {
      if (this.isActive) {
        this.close();
      } else {
        this.open();
      }
    },

    setCurrentHighlight(target: HELP_PANEL_TAGS | '' = '', context?: HELP_PANEL_CONTEXT): void {
      const { openInfoEvent } = useAnalyticsHelper();
      this.currentHighlight = target;
      if (target) openInfoEvent(target, context);
    },

    setInPosition(val: boolean): void {
      this.inPosition = val;
    },

    setIsVisible(val: boolean): void {
      this.isVisible = val;
    },
  },
});

export default useSidePanelStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSidePanelStore, import.meta.hot));
}

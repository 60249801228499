/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AccessPolicyIdV0 = {
    ArchiveAccess: 'archive_access',
    Full: 'full',
    UserManagement: 'user_management'
} as const;
export type AccessPolicyIdV0 = typeof AccessPolicyIdV0[keyof typeof AccessPolicyIdV0];


export function instanceOfAccessPolicyIdV0(value: any): boolean {
    for (const key in AccessPolicyIdV0) {
        if (Object.prototype.hasOwnProperty.call(AccessPolicyIdV0, key)) {
            if (AccessPolicyIdV0[key as keyof typeof AccessPolicyIdV0] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AccessPolicyIdV0FromJSON(json: any): AccessPolicyIdV0 {
    return AccessPolicyIdV0FromJSONTyped(json, false);
}

export function AccessPolicyIdV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessPolicyIdV0 {
    return json as AccessPolicyIdV0;
}

export function AccessPolicyIdV0ToJSON(value?: AccessPolicyIdV0 | null): any {
    return value as any;
}

export function AccessPolicyIdV0ToJSONTyped(value: any, ignoreDiscriminator: boolean): AccessPolicyIdV0 {
    return value as AccessPolicyIdV0;
}


import type {
  AccessPolicyV0,
  CustomSearchPolicyV0,
  SearchPolicyDetailsV0,
  SearchPolicyInListV0,
} from '@/api';
import type { TranslationKey } from '@/locales';
import type { TimezoneId } from '@/store/timezone';
import type { AccessPolicyOptionsEnum, AccessPolicyWithOptions } from '@/types/AccessPolicy';
import type { Dayjs } from 'dayjs';

import i18n from '@/locales';

import dayjs from 'dayjs';

export function useFormatters() {
  const { t, locale } = i18n.global;
  const { tenant, timezone } = useStores('tenant', 'timezone');

  /**
   * Format a date and time from an email date header as a string in the proper format of the current locale, or unaltered if it was an invalid header.
   *
   * @param date A normalised datetime already processed by nobita or a raw unparsed datetime from the Date header
   *             If the date was not already processed by the backend, the string is returned untouched.
   * @returns A formatted string in the style of the chosen locale.
   */
  const formatEmailDateHeader = (
    date: string,
    format: TranslationKey = 'formatting.dateDayTimeFormat',
  ): string => {
    // TODO - enable strict parsing
    const d = dayjs(date);
    if (!d.isValid()) return date;
    return d.tz(tenant.timezone).locale(locale.value).format(t(format));
  };

  /**
   * Format a date and time from an email as a string in the proper format of the current locale.
   *
   * @param date A JS Date, Dayjs object, or `null`.
   *             If `null` is provided or the date is invalid, a blank dash is returned to represent "no date".
   * @param format A Nobita translation key that represents the desired formatting to use.
   * @returns A formatted string in the style of the chosen locale.
   */
  const formatDatetime = (
    date: Date | dayjs.Dayjs | null,
    format: TranslationKey = 'formatting.dateDayTimeFormat',
  ): string => {
    const d = dayjs(date).tz(tenant.timezone);
    if (!d.isValid()) return EMPTY_DASH;
    return d.locale(locale.value).format(t(format));
  };

  /**
   * Format a date without a time component as a string in the proper format of the current locale.
   *
   * @param date A JS Date, Dayjs object, or `null`.
   *             The time component will be ignored (not printed).
   *             If `null` is provided or the date is invalid, a blank dash is returned to represent "no date".
   * @param format A Nobita translation key that represents the desired formatting to use.
   * @returns A formatted string in the style of the chosen locale.
   */
  const formatDate = (
    date: Date | dayjs.Dayjs | null,
    format: TranslationKey = 'formatting.dateFormat',
  ) => {
    const d = dayjs(date).tz(tenant.timezone);
    if (!d.isValid()) return EMPTY_DASH;
    return d.locale(locale.value).format(t(format));
  };

  /**
   * Return a formatted timezone in `JST (UTC+9)` format.
   *
   * @param timezoneId The timezone to use, in `Asia/Tokyo` string format.
   * @param datetime The datetime that we want to evaluate the timezone in.
   */
  const formatTimezone = (timezoneId: TimezoneId, datetime: Dayjs | Date): string => {
    const resolvedDatetime = dayjs(datetime);

    const offsetString = `UTC${resolvedDatetime.tz(timezoneId).format('Z')}`;
    if (timezoneId === 'Etc/UTC') return 'UTC';

    const abbr = timezone.getAbbreviation(timezoneId, resolvedDatetime);
    if (abbr) return `${abbr} (${offsetString})`;
    else return offsetString;
  };

  /**
   * Format a file size in bytes, kilobites, megabytes, etc., based on the most significant digits.
   *
   * @param bytes number of bytes (filesize)
   */
  const formatBytes = (bytes: number) => {
    if (bytes <= 0 || isNaN(bytes)) return '0 B';

    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1)).toLocaleString(locale.value)} ${
      sizes[i]
    }`;
  };

  /**
   * Format a number with the given locale (including commas, periods, etc.)
   *
   * @param param numeric value to format in the current locale
   */
  const formatNumber = (param: number) => {
    if (param <= 0 || isNaN(param)) return '0';

    return Intl.NumberFormat(locale.value).format(param);
  };

  /**
   * Format a fractional number of days as a string in the current locale, representing the number of hours and minutes included.
   *
   * @param days A fractional representation of the time in days. Domain: 0 < days < 1.
   * @returns A string representation of the hours and minutes in the current locale, e.g. `in 1h 30m` or `1時間30分後`
   */
  function formatHourMin(days: number) {
    return dayjs
      .duration(days, 'days')
      .locale(locale.value)
      .format(t('formatting.hourMinuteFormat'));
  }

  /**
   * Retrieve and format the name of a search policy -- either a custom policy or a system policy.
   *
   * @param policy Either a Custom Search Policy or a System Search Policy, as returned by the API.
   * @returns The name of the policy, or a translated string if the policy is a system policy.
   */
  function formatSearchPolicyName(
    policy: SearchPolicyDetailsV0 | SearchPolicyInListV0 | CustomSearchPolicyV0,
  ): string {
    if ('name' in policy) {
      return policy.name;
    } else {
      if (policy.id === 'allow_all') return t('searchPolicies.allowAll');
      else if (policy.id === 'block_all') return t('searchPolicies.blockAll');
      else return policy.id;
    }
  }

  /**
   * Format the name of an access policy.
   *
   * @param accessPolicy A valid access policy object which is either either `custom` or a `named` policy.
   */
  function formatAccessPolicyName(accessPolicy?: AccessPolicyV0 | null): string {
    if (accessPolicy?.type === 'named') {
      return t(`accessPolicyLabels.presets.${accessPolicy.id}`);
    }

    return t('accessPolicyLabels.custom');
  }

  /**
   * Retrieve and format the name of an access policy's option value.
   *
   * @param accessPolicy A valid access policy object of type either `custom` or `named` policy, which has an `options` property.
   * @param accessPolicyOption The specific option to look up the value of on the provided access policy.
   */
  function formatAccessPolicyOptionName(
    accessPolicy: AccessPolicyWithOptions,
    accessPolicyOption: AccessPolicyOptionsEnum,
  ): string {
    const accessPolicyOptions = accessPolicy?.options ?? [];
    const option = accessPolicyOptions.find((e) => e.id === accessPolicyOption);
    if (option) {
      switch (option.value) {
        case 'read+write': {
          if (accessPolicyOption === 'user_settings' || accessPolicyOption === 'search_policies')
            return t('permissionsOptions.viewEditCreate');
          else return t('permissionsOptions.viewEdit');
        }
        case 'read':
          return t('permissionsOptions.viewOnly');
        case 'read+download':
          return t('permissionsOptions.viewDownload');
        case 'enabled':
          return t('permissionsOptions.selfEdit');
        default: {
          option satisfies never;
        }
      }
    }
    return t('permissionsOptions.none');
  }

  /**
   * For use with Usage Warnings
   * @param param
   * @returns
   */
  function formatUsage(param: number): string {
    return param.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  /**
   * For use with Usage Warnings
   * @param param
   * @returns
   */
  function formatApproximatedUsage(param: number): string | number {
    const multiplier = 10;
    return Math.abs(param) > 999
      ? (Math.sign(param) * Math.round((Math.abs(param) / 1000) * multiplier)) / multiplier + 'k'
      : Math.sign(param) * Math.abs(param);
  }

  /**
   * Format a number months as a string of years and months.
   * @param param
   * @returns
   */
  function formatMonthNumber(param: number): string {
    const years = Math.floor(param / 12);
    const months = param % 12;
    const yearText = t(`archiveSettings.periodYear`, { count: years });
    const monthText = months > 0 ? t(`archiveSettings.periodMonth`, { count: months }) : '';
    return `${yearText} ${monthText}`;
  }

  return {
    formatDate,
    formatDatetime,
    formatEmailDateHeader,
    formatTimezone,
    formatBytes,
    formatNumber,
    formatHourMin,
    formatSearchPolicyName,
    formatAccessPolicyName,
    formatAccessPolicyOptionName,
    formatUsage,
    formatApproximatedUsage,
    formatMonthNumber,
  };
}

/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CustomSearchPolicyDetailsV0 } from './CustomSearchPolicyDetailsV0';
import {
    instanceOfCustomSearchPolicyDetailsV0,
    CustomSearchPolicyDetailsV0FromJSON,
    CustomSearchPolicyDetailsV0FromJSONTyped,
    CustomSearchPolicyDetailsV0ToJSON,
} from './CustomSearchPolicyDetailsV0';
import type { SystemSearchPolicyDetailsV0 } from './SystemSearchPolicyDetailsV0';
import {
    instanceOfSystemSearchPolicyDetailsV0,
    SystemSearchPolicyDetailsV0FromJSON,
    SystemSearchPolicyDetailsV0FromJSONTyped,
    SystemSearchPolicyDetailsV0ToJSON,
} from './SystemSearchPolicyDetailsV0';

/**
 * @type SearchPolicyDetailsV0
 * 
 * @export
 */
export type SearchPolicyDetailsV0 = { type: 'custom' } & CustomSearchPolicyDetailsV0 | { type: 'system' } & SystemSearchPolicyDetailsV0;

export function SearchPolicyDetailsV0FromJSON(json: any): SearchPolicyDetailsV0 {
    return SearchPolicyDetailsV0FromJSONTyped(json, false);
}

export function SearchPolicyDetailsV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchPolicyDetailsV0 {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'custom':
            return Object.assign({}, CustomSearchPolicyDetailsV0FromJSONTyped(json, true), { type: 'custom' } as const);
        case 'system':
            return Object.assign({}, SystemSearchPolicyDetailsV0FromJSONTyped(json, true), { type: 'system' } as const);
        default:
            throw new Error(`No variant of SearchPolicyDetailsV0 exists with 'type=${json['type']}'`);
    }
}

export function SearchPolicyDetailsV0ToJSON(json: any): any {
    return SearchPolicyDetailsV0ToJSONTyped(json, false);
}

export function SearchPolicyDetailsV0ToJSONTyped(value?: SearchPolicyDetailsV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'custom':
            return Object.assign({}, CustomSearchPolicyDetailsV0ToJSON(value), { type: 'custom' } as const);
        case 'system':
            return Object.assign({}, SystemSearchPolicyDetailsV0ToJSON(value), { type: 'system' } as const);
        default:
            throw new Error(`No variant of SearchPolicyDetailsV0 exists with 'type=${value['type']}'`);
    }

}


import type { SearchEntryV0 } from '@/api';

import dayjs from 'dayjs';
import { groupBy } from 'lodash-es';

export interface SearchHistoryState {
  searchRecords: SearchEntryV0[];
}

export const useSearchHistoryStore = defineStore('searchHistory', () => {
  const state = reactive<SearchHistoryState>({
    searchRecords: [],
  });

  const tenant = useTenantStore();

  const dateGroupedSearchRecords = computed<Record<string, SearchEntryV0[]>>(() =>
    groupBy(state.searchRecords, (record) =>
      dayjs(record.timestamp).tz(tenant.timezone).format('YYYY-MM-DD'),
    ),
  );

  return {
    ...toRefs(state),
    dateGroupedSearchRecords,

    setSearchHistory(searchRecords: SearchEntryV0[]) {
      state.searchRecords = searchRecords;
    },
  };
});

export default useSearchHistoryStore;

import.meta.hot?.accept(acceptHMRUpdate(useSearchHistoryStore, import.meta.hot));

<template>
  <button
    class="IconAction"
    :class="[`IconAction-${size}`, `IconAction-${shape}`, `IconAction-${mode}`]"
    :type
    :disabled
    :tabindex="disabled ? -1 : tabindex"
    :title="titleText ? $t(titleText) : undefined"
  >
    <BaseIcon :size class="Icon">
      <slot />
    </BaseIcon>
  </button>
</template>

<script setup lang="ts">
import { type TranslationKey } from '@/locales';

export type Props = {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  type?: 'button' | 'submit';
  mode?: 'light' | 'dark';
  shape?: 'square' | 'circle';
  disabled?: boolean;
  tabindex?: -1 | 0;
  titleText?: TranslationKey; // TODO: [a11y] make this non-optional
};

export type Slots = {
  default(): void;
};

withDefaults(defineProps<Props>(), {
  size: 'sm',
  type: 'button',
  mode: 'light',
  shape: 'circle',
  tabindex: 0,
  titleText: undefined,
});

// TODO: use the Slots type defined above here as well and don't repeat ourselves
// But this issue is blocking us: https://github.com/vuejs/eslint-plugin-vue/issues/2398
defineSlots<{
  default(): void;
}>();
</script>

<style scoped lang="scss">
.IconAction {
  border: none;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  padding: 0;
  background-color: transparent;

  @include setTransition(background-color);

  &:disabled {
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
    }
  }

  // shape
  &-square {
    border-radius: 4px;
  }
  &-circle {
    border-radius: 50%;
  }

  // mode
  &-light {
    &:hover,
    &:focus-visible {
      background-color: black(5);
    }
    &:active {
      background-color: alpha-primary(20);
    }
    &:focus-visible {
      outline: 3px solid primary(600);
    }

    & .Icon {
      fill: black(87);
    }

    &:disabled {
      & .Icon {
        fill: neutral(350);
      }
    }
  }

  &-dark {
    background-color: neutral(500);

    &:hover,
    &:focus-visible {
      background-color: neutral(800);
    }
    &:active {
      background-color: primary(700);
    }
    &:focus-visible {
      outline: 3px solid primary(600);
      outline-offset: 1px;
    }
    &:disabled {
      .Icon {
        fill: neutral(350);
      }
    }

    .Icon {
      fill: white();
      color: white();
    }
  }

  // sizes
  &-xs {
    padding: 2px; // (not in design system)
  }
  &-sm {
    padding: 6px;
  }
  &-md {
    padding: 6px;
  }
  &-lg {
    padding: 8px;
  }
}

.Icon {
  display: block;

  // sizes
  .IconAction-xs & {
    width: 12px; // plus padding = 16px (not in design system)
    height: 12px;
  }
  .IconAction-sm & {
    width: 16px; // plus padding = 28px
    height: 16px;
  }
  .IconAction-md & {
    width: 20px; // plus padding = 32px
    height: 20px;
  }
  .IconAction-lg & {
    width: 24px; // plus padding = 40px
    height: 24px;
  }
}
</style>

<template>
  <BaseDialog
    id="FailedOnboardingDialog"
    :title="$t('dialogs.failedOnboarding.title')"
    :subtitle="$t('dialogs.failedOnboarding.message')"
    persistent
  >
    <template #footer>
      <menu class="Toolbar">
        <li class="ToolbarItem">
          <BaseAction
            data-testid="FailedOnboardingDialogPrimaryAction"
            color="primary"
            to="https://console.hdems.com/"
            target="_self"
            title="dialogs.failedOnboarding.action"
          >
            {{ $t('dialogs.failedOnboarding.action') }}
          </BaseAction>
        </li>
      </menu>
    </template>
  </BaseDialog>
</template>

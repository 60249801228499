import type { NobitaRouteRecordRaw } from './routes';
import type { RouteLocationNormalized } from 'vue-router';

import AuthorisationView from '@/views/AuthorisationView.vue';
import BaseView from '@/views/BaseView.vue';
import LoginView from '@/views/LoginView.vue';

import { createRouter, createWebHistory } from 'vue-router';

import { Route_404 } from './routes';

export type { NobitaRouteName, NobitaRouteLocationRaw } from './routes';

/**
 * Core Nobita routes
 */
const routes: NobitaRouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: BaseView,
    props: (route: RouteLocationNormalized) => ({ hideNav: route.name === 'emailView' }),
    children: [Route_404],
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/cb',
    name: 'Authorisation',
    component: AuthorisationView,
  },
];

const router = createRouter({
  history: createWebHistory(
    import.meta.env.BASE_URL.startsWith('/pr-') ? import.meta.env.BASE_URL : '/',
  ),
  routes,
});

addBeforeEachRouterGuard(router);

export default router;

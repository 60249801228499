/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ECJWKV0 } from './ECJWKV0';
import {
    type instanceOfECJWKV0,
    ECJWKV0FromJSON,
    ECJWKV0FromJSONTyped,
    ECJWKV0ToJSON,
} from './ECJWKV0';
import type { RSAJWKV0 } from './RSAJWKV0';
import {
    type instanceOfRSAJWKV0,
    RSAJWKV0FromJSON,
    RSAJWKV0FromJSONTyped,
    RSAJWKV0ToJSON,
} from './RSAJWKV0';

/**
 * @type JWKV0
 * 
 * @export
 */
export type JWKV0 = { kty: 'EC' } & ECJWKV0 | { kty: 'RSA' } & RSAJWKV0;

export function JWKV0FromJSON(json: any): JWKV0 {
    return JWKV0FromJSONTyped(json, false);
}

export function JWKV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): JWKV0 {
    if (json == null) {
        return json;
    }
    switch (json['kty']) {
        case 'EC':
            return Object.assign({}, ECJWKV0FromJSONTyped(json, true), { kty: 'EC' } as const);
        case 'RSA':
            return Object.assign({}, RSAJWKV0FromJSONTyped(json, true), { kty: 'RSA' } as const);
        default:
            throw new Error(`No variant of JWKV0 exists with 'kty=${json['kty']}'`);
    }
}

export function JWKV0ToJSON(json: any): any {
    return JWKV0ToJSONTyped(json, false);
}

export function JWKV0ToJSONTyped(value?: JWKV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['kty']) {
        case 'EC':
            return Object.assign({}, ECJWKV0ToJSON(value), { kty: 'EC' } as const);
        case 'RSA':
            return Object.assign({}, RSAJWKV0ToJSON(value), { kty: 'RSA' } as const);
        default:
            throw new Error(`No variant of JWKV0 exists with 'kty=${value['kty']}'`);
    }

}


<template>
  <div class="RadioField">
    <input
      :id="inputId"
      class="RadioInput"
      type="radio"
      name="feedback-mood"
      :value="inputValue"
      :disabled
      :aria-label="$t(inputTextKey)"
      @change="model = inputValue"
    />
    <label
      :for="inputId"
      class="RadioLabel"
      :class="{ 'RadioLabel-disabled': disabled, 'RadioLabel-checked': inputValue === model }"
    >
      <BaseIcon class="MoodIcon" :class="{ 'MoodIcon-checked': inputValue === model }" size="xl">
        <NobitaIconFaceVeryAngry v-if="inputId === FEEDBACK_MOODS.veryAngry" />
        <NobitaIconFaceAngry v-else-if="inputId === FEEDBACK_MOODS.angry" />
        <NobitaIconFaceHappy v-else-if="inputId === FEEDBACK_MOODS.happy" />
        <NobitaIconFaceVeryHappy v-else-if="inputId === FEEDBACK_MOODS.veryHappy" />
      </BaseIcon>
    </label>
  </div>
</template>

<script setup lang="ts">
import type { TranslationKey } from '@/locales';
import { FEEDBACK_MOOD_VALUE, FEEDBACK_MOODS } from '@/types/Feedback';

const props = defineProps<{
  inputId: FEEDBACK_MOODS;
  inputValue: FEEDBACK_MOOD_VALUE;
  disabled?: boolean;
}>();

const model = defineModel<FEEDBACK_MOOD_VALUE | null>({ required: true });

const inputTextKey = computed(() =>
  props.inputId === 'feedback-very-angry'
    ? 'feedback.moods.feedbackVeryAngry'
    : props.inputId === 'feedback-angry'
      ? 'feedback.moods.feedbackAngry'
      : props.inputId === 'feedback-very-happy'
        ? 'feedback.moods.feedbackVeryHappy'
        : 'feedback.moods.feedbackHappy',
) satisfies ComputedRef<TranslationKey>;
</script>

<style scoped lang="scss">
.RadioInput {
  @include hidden;
}

.MoodIcon {
  cursor: pointer;
  fill: neutral(350);
  border: 2px solid neutral(350);
  border-radius: 50%;

  @include setTransition(border-color, fill);

  &:hover {
    fill: neutral(500);
    border-color: neutral(500);
  }

  &-checked {
    cursor: default;
    fill: neutral(500);
    border-color: yellow(300);

    &:hover {
      border-color: yellow(300);
    }
  }
}

.RadioLabel {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-weight: $font-weight-normal;
  font-size: 14px;
  line-height: 1.4;
  color: black(87);
  background-color: white();
  border-radius: 50%;
  cursor: pointer;

  @include setTransition(background-color);

  &-disabled {
    cursor: default;
  }

  &-checked {
    background-color: yellow(300);
  }
}
</style>

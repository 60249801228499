import type { TranslationKey } from '@/locales';
import type { SnackbarItem, SnackbarParams } from '@/types/Snackbar';

export interface SnackbarsStore {
  topRight: SnackbarItem[];
  topCenter: SnackbarItem[];
  bottomRight: SnackbarItem[];
}

export const useSnackbarsStore = defineStore('snackbars', {
  state: (): SnackbarsStore => ({
    topRight: [],
    topCenter: [],
    bottomRight: [],
  }),

  actions: {
    async addSnackbar(param: SnackbarParams): Promise<void> {
      const list = this[param.position || SNACKBAR_POSITION.bottomRight];
      const timestamp = Date.now();
      const snackbar: SnackbarItem = {
        id: param.id ?? timestamp.toString(),
        isActive: false,
        isClosable: param.isClosable ?? true,
        hasAction: param.hasAction ?? false,
        status: param.status || 'standard',
        icon: param.icon,
        actionText: param.actionText ? i18n.global.t(param.actionText) : '',
        content: i18n.global.t(param.content),
        duration: param.duration ?? 4,
        position: param.position || SNACKBAR_POSITION.bottomRight,
        callback: param.callback,
      };

      if (param.position === SNACKBAR_POSITION.bottomRight) {
        if (list.length >= 3) {
          list[0].isActive = false;
          setTimeout(() => list.shift(), TRANSITION_MS);
          setTimeout(() => list.push(snackbar), TRANSITION_MS);
        } else {
          list.push(snackbar);
        }
      } else {
        if (list.length >= 1) {
          list[0].isActive = false;
          setTimeout(() => list.pop(), TRANSITION_MS);
          setTimeout(() => list.unshift(snackbar), TRANSITION_MS);
        } else {
          list.unshift(snackbar);
        }
      }
    },

    addSuccessSnackbar(content: TranslationKey): void {
      this.addSnackbar({ icon: 'check', content });
    },

    addWarningSnackbar(content?: TranslationKey): void {
      this.addSnackbar({
        status: 'warning',
        content: content ?? 'response.somethingWentWrong',
      });
    },

    activateSnackbar(index: number, position: SNACKBAR_POSITION): void {
      this[position][index].isActive = true;
    },

    dismissSnackbar(index: number, position: SNACKBAR_POSITION): void {
      if (this[position][index]) {
        this[position][index].isActive = false;
        setTimeout(() => this[position].splice(index, 1), TRANSITION_MS_MEDIUM);
      }
    },

    deleteSnackbar(index: number, position: SNACKBAR_POSITION): void {
      this[position].splice(index, 1);
    },

    dismissSnackbarById(id: string, position: SNACKBAR_POSITION): void {
      const index = this[position].findIndex((e) => e.id === id);
      if (index !== -1) {
        this[position].splice(index, 1);
      }
    },
  },
});

export default useSnackbarsStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSnackbarsStore, import.meta.hot));
}

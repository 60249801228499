/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ArchiveStatisticsV0,
  ErrorResponsePayloadV0,
} from '../models/index';
import {
    ArchiveStatisticsV0FromJSON,
    ArchiveStatisticsV0ToJSON,
    ErrorResponsePayloadV0FromJSON,
    ErrorResponsePayloadV0ToJSON,
} from '../models/index';

/**
 * 
 */
export class StatsApi extends runtime.BaseAPI {

    /**
     * This will return the most recent archive statistics available for the current user\'s domain.  The user requires the permission `archive:stats:get-recent` to be able to call this endpoint.  Error Codes:  - HTTP 404    - 2201 => there are currently no statistics available.
     * Get recent archive statistics.
     */
    async getRecentArchiveStatisticsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ArchiveStatisticsV0>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/stats/recent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArchiveStatisticsV0FromJSON(jsonValue));
    }

    /**
     * This will return the most recent archive statistics available for the current user\'s domain.  The user requires the permission `archive:stats:get-recent` to be able to call this endpoint.  Error Codes:  - HTTP 404    - 2201 => there are currently no statistics available.
     * Get recent archive statistics.
     */
    async getRecentArchiveStatistics(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ArchiveStatisticsV0> {
        const response = await this.getRecentArchiveStatisticsRaw(initOverrides);
        return await response.value();
    }

}

import type { DomainV0 } from '@/api/models';

export const useDomainStore = defineStore('domain', {
  state: () => ({
    idp: '',
    timezone: '',
    allowedIps: <string[]>[],
    settings: {
      timezone: '',
      allowedIps: <string[]>[],
    },
  }),

  getters: {
    isEditing(state): boolean {
      return (
        state.settings.timezone !== state.timezone ||
        !isEquivalentUnsorted(state.settings.allowedIps, state.allowedIps)
      );
    },
  },

  actions: {
    setAllowedAccess(allowedIps: string[]) {
      this.allowedIps = [...allowedIps];
    },

    updateDomain(timezone: string, allowedIps: string[]): void {
      this.timezone = timezone;
      this.allowedIps = [...allowedIps];
    },

    updateSettings(timezone: string, allowedIps: string[]): void {
      this.settings.timezone = timezone;
      this.settings.allowedIps = [...allowedIps];
    },

    resetSettings(): void {
      this.settings.timezone = this.timezone;
      this.settings.allowedIps = [...this.allowedIps];
    },

    initDomain(domain: DomainV0): void {
      this.idp = domain.idp;
      this.timezone = domain.timezone;
      this.allowedIps = [...domain.allowedIps];
      this.resetSettings();
    },
  },
});

export default useDomainStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDomainStore, import.meta.hot));
}

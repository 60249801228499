<template>
  <Transition :name="animation">
    <li v-if="snackbar.isActive" class="SnackbarHolder" role="dialog">
      <div
        :id="`Snackbar-${snackbar.id}`"
        role="status"
        class="Snackbar"
        :class="[
          `Snackbar-${snackbar.status}`,
          {
            'Snackbar-hasIcon': snackbar.icon,
            'Snackbar-hasAction': snackbar.isClosable || snackbar.hasAction,
          },
        ]"
      >
        <BaseIcon v-if="snackbar.icon" class="SnackbarIcon">
          <NobitaIconInfoFilled v-if="snackbar.icon === 'info'" />
          <NobitaIconCheckFilled v-else-if="snackbar.icon === 'check'" />
          <SpinningIcon v-else-if="snackbar.icon === 'loader'" />
        </BaseIcon>
        <p class="SnackbarMessage">
          {{ snackbar.content }}
        </p>

        <div v-if="snackbar.hasAction || snackbar.isClosable" class="SnackbarFooter">
          <BaseAction
            v-if="snackbar.hasAction"
            class="SnackbarAction"
            :color="snackbar.status === 'standard' ? 'primary' : 'secondary'"
            outlined
            size="md"
            @click="clickHandler"
          >
            {{ snackbar.actionText }}
          </BaseAction>

          <IconAction
            v-if="snackbar.isClosable"
            class="SnackbarCloseButton"
            size="md"
            mode="dark"
            icon="close"
            @click="dismiss"
          >
            <NobitaIconClose />
          </IconAction>
        </div>
      </div>
    </li>
  </Transition>
</template>

<script setup lang="ts">
import type { SnackbarItem } from '@/types/Snackbar';
import { SNACKBAR_POSITION } from '@/types/Snackbar';

export type Props = {
  index: number;
  position?: SNACKBAR_POSITION;
};

const props = withDefaults(defineProps<Props>(), {
  position: SNACKBAR_POSITION.bottomRight,
});

const { snackbars } = useStores('snackbars');

const snackbar = computed<SnackbarItem>(() => snackbars[props.position][props.index]);

const animation = computed<string>(() => {
  if (props.position === SNACKBAR_POSITION.bottomRight) {
    return snackbars[props.position].length > 1
      ? SNACKBAR_POSITION[props.position]
      : 'bottomRightSingle';
  } else {
    return SNACKBAR_POSITION[props.position];
  }
});

const clickHandler = () => snackbar.value.callback?.();

const triggerTransition = () => snackbars.activateSnackbar(props.index, props.position);

const timeoutID = ref<number | undefined>();
const setTimer = () => {
  timeoutID.value = window.setTimeout(() => {
    snackbars.dismissSnackbar(props.index, props.position);
  }, snackbar.value.duration * 1000);
};

const dismiss = () => {
  clearTimeout(timeoutID.value);
  snackbars.dismissSnackbar(props.index, props.position);
};

onMounted(() => {
  triggerTransition();
  if (snackbar.value.duration > 0) setTimer();
});
</script>

<style scoped lang="scss">
$snackholder_height: 100px;
$snackbar_min_width: 420px;
$snackbar_max_width: 720px;

.SnackbarHolder {
  width: 100%;
  height: auto;

  @include sm-and-up {
    width: auto;
    min-width: $snackbar_min_width;
    max-width: $snackbar_max_width;
  }
}

.Snackbar {
  display: inline-flex;
  place-content: center space-between;
  align-items: center;
  width: 100%;
  padding: 13px 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  box-sizing: border-box;

  @include elevationHigh;

  @include sm-and-up {
    width: auto;
    min-width: $snackbar_min_width;
    max-width: $snackbar_max_width;
  }

  &-standard {
    background-color: neutral(600);
  }

  &-warning {
    background-color: red(400);
  }

  &-hasIcon {
    padding-left: 12px;
  }

  &-hasAction {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}

.SnackbarFooter {
  display: flex;
  justify-content: center;
  flex: 0 0 auto;
  max-width: 100%;
  align-items: flex-end;
  margin-left: 12px;
}

.SnackbarCloseButton,
.SnackbarAction {
  margin-left: 4px;
}

.SnackbarMessage {
  color: white();
  font-size: 14px;
  line-height: 1.6;
  margin: 0;
  flex: 1 1 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;

  @include sm-and-up {
    -webkit-line-clamp: 1;
    line-clamp: 1;
    min-width: 0;
  }
}
.SnackbarIcon {
  fill: white();
  margin-right: 8px;
  flex: 0 0 auto;
  max-width: 100%;
}

// topCenter, topRight
.topCenter-leave-active,
.topRight-leave-active {
  opacity: 1;

  @include setTransitionMedium(opacity);
}
.topRight-leave-to,
.topCenter-leave-to {
  opacity: 0;
}
.topCenter-enter-active,
.topRight-enter-active {
  opacity: 1;
  transform: translateY(0);

  @include setTransitionMedium(opacity, transform);
}
.topCenter-enter-from,
.topRight-enter-from {
  opacity: 0;
  transform: translateY(-96px);
}

.bottomRight-leave-active,
.bottomRightSingle-leave-active {
  opacity: 1;

  @include setTransitionMedium(opacity);
}
.bottomRight-leave-to,
.bottomRightSingle-leave-to {
  opacity: 0;
}

// bottomRightSingle
.bottomRightSingle-leave-active {
  opacity: 1;

  @include setTransitionMedium(opacity);
}
.bottomRightSingle-leave-to {
  opacity: 0;
}
.bottomRightSingle-enter-active {
  opacity: 1;
  transform: translateY(0);

  @include setTransitionMedium(opacity, transform);
}
.bottomRightSingle-enter-from {
  opacity: 0;
  transform: translateY(100%);
}

// bottomRight
.bottomRight-enter-active {
  opacity: 1;
  max-height: $snackholder_height;
  animation: bottomRightIn 0.6s ease-in-out;
}
.bottomRight-enter-from {
  opacity: 0;
  max-height: 0;
}
.bottomRight-leave-active {
  opacity: 1;
  max-height: $snackholder_height;
  animation: bottomRightOut 0.6s ease-in-out;
}
.bottomRight-leave-to {
  opacity: 0;
  max-height: 0;
}

@keyframes bottomRightIn {
  0% {
    opacity: 0;
    max-height: 0;
  }
  50% {
    opacity: 0;
    max-height: $snackholder_height;
  }
  100% {
    opacity: 1;
    max-height: $snackholder_height;
  }
}

@keyframes bottomRightOut {
  0% {
    opacity: 1;
    max-height: $snackholder_height;
  }
  50% {
    opacity: 0;
    max-height: $snackholder_height;
  }
  100% {
    opacity: 0;
    max-height: 0;
  }
}
</style>

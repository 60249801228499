/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ErrorInfoV0 } from './ErrorInfoV0';
import {
    ErrorInfoV0FromJSON,
    ErrorInfoV0FromJSONTyped,
    ErrorInfoV0ToJSON,
    ErrorInfoV0ToJSONTyped,
} from './ErrorInfoV0';

/**
 * 
 * @export
 * @interface ErrorResponsePayloadV0
 */
export interface ErrorResponsePayloadV0 {
    /**
     * 
     * @type {ErrorInfoV0}
     * @memberof ErrorResponsePayloadV0
     */
    error: ErrorInfoV0;
}

/**
 * Check if a given object implements the ErrorResponsePayloadV0 interface.
 */
export function instanceOfErrorResponsePayloadV0(value: object): value is ErrorResponsePayloadV0 {
    if (!('error' in value) || value['error'] === undefined) return false;
    return true;
}

export function ErrorResponsePayloadV0FromJSON(json: any): ErrorResponsePayloadV0 {
    return ErrorResponsePayloadV0FromJSONTyped(json, false);
}

export function ErrorResponsePayloadV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorResponsePayloadV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'error': ErrorInfoV0FromJSON(json['error']),
    };
}

export function ErrorResponsePayloadV0ToJSON(json: any): ErrorResponsePayloadV0 {
    return ErrorResponsePayloadV0ToJSONTyped(json, false);
}

export function ErrorResponsePayloadV0ToJSONTyped(value?: ErrorResponsePayloadV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'error': ErrorInfoV0ToJSON(value['error']),
    };
}


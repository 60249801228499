export const useLoaderStore = defineStore('loader', {
  state: () => ({
    isLoading: false,
  }),

  actions: {
    show() {
      this.isLoading = true;
    },

    hide() {
      this.isLoading = false;
    },
  },
});

export default useLoaderStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLoaderStore, import.meta.hot));
}

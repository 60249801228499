/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponsePayloadV0,
  JWKSResponseV0,
  ProviderRequestV0,
  TokenRequestV0,
  TokenResponseV0,
  URLResponseV0,
  UserInfoUpdateV0,
  UserInfoV0,
} from '../models/index';
import {
    ErrorResponsePayloadV0FromJSON,
    ErrorResponsePayloadV0ToJSON,
    JWKSResponseV0FromJSON,
    JWKSResponseV0ToJSON,
    ProviderRequestV0FromJSON,
    ProviderRequestV0ToJSON,
    TokenRequestV0FromJSON,
    TokenRequestV0ToJSON,
    TokenResponseV0FromJSON,
    TokenResponseV0ToJSON,
    URLResponseV0FromJSON,
    URLResponseV0ToJSON,
    UserInfoUpdateV0FromJSON,
    UserInfoUpdateV0ToJSON,
    UserInfoV0FromJSON,
    UserInfoV0ToJSON,
} from '../models/index';

export interface GetAccessTokenRequest {
    tokenRequestV0: TokenRequestV0;
}

export interface GetLoginUrlRequest {
    providerRequestV0: ProviderRequestV0;
}

export interface UpdateUserInfoRequest {
    userInfoUpdateV0: UserInfoUpdateV0;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * This will authenticate the authorization response from an Idp and return an access token.  Users have to be pre-registered to complete this exchange.  Error Codes:  - N/A
     * Get an access token for the HENNGE Email Archive API
     */
    async getAccessTokenRaw(requestParameters: GetAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenResponseV0>> {
        if (requestParameters['tokenRequestV0'] == null) {
            throw new runtime.RequiredError(
                'tokenRequestV0',
                'Required parameter "tokenRequestV0" was null or undefined when calling getAccessToken().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v0/auth/access_token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenRequestV0ToJSON(requestParameters['tokenRequestV0']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseV0FromJSON(jsonValue));
    }

    /**
     * This will authenticate the authorization response from an Idp and return an access token.  Users have to be pre-registered to complete this exchange.  Error Codes:  - N/A
     * Get an access token for the HENNGE Email Archive API
     */
    async getAccessToken(requestParameters: GetAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenResponseV0> {
        const response = await this.getAccessTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This endpoint will return the identity server\'s public key set in JWKS format.\", This endpoint does not require authentication.  Error Codes:  - N/A
     * Get public key set in JWKS format.
     */
    async getJwksRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JWKSResponseV0>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v0/auth/jwks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JWKSResponseV0FromJSON(jsonValue));
    }

    /**
     * This endpoint will return the identity server\'s public key set in JWKS format.\", This endpoint does not require authentication.  Error Codes:  - N/A
     * Get public key set in JWKS format.
     */
    async getJwks(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JWKSResponseV0> {
        const response = await this.getJwksRaw(initOverrides);
        return await response.value();
    }

    /**
     * This will return IdP login URL for the tenant with the given domain name.  Error Codes:  - N/A
     * Get the IdP login URL for tenant
     */
    async getLoginUrlRaw(requestParameters: GetLoginUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<URLResponseV0>> {
        if (requestParameters['providerRequestV0'] == null) {
            throw new runtime.RequiredError(
                'providerRequestV0',
                'Required parameter "providerRequestV0" was null or undefined when calling getLoginUrl().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v0/auth/login_url`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProviderRequestV0ToJSON(requestParameters['providerRequestV0']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => URLResponseV0FromJSON(jsonValue));
    }

    /**
     * This will return IdP login URL for the tenant with the given domain name.  Error Codes:  - N/A
     * Get the IdP login URL for tenant
     */
    async getLoginUrl(requestParameters: GetLoginUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<URLResponseV0> {
        const response = await this.getLoginUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This returns current user profile info.  Error Codes:  - N/A
     * Get current user info
     */
    async getUserInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserInfoV0>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/auth/userinfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserInfoV0FromJSON(jsonValue));
    }

    /**
     * This returns current user profile info.  Error Codes:  - N/A
     * Get current user info
     */
    async getUserInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserInfoV0> {
        const response = await this.getUserInfoRaw(initOverrides);
        return await response.value();
    }

    /**
     * This endpoint will revokes the current session.  Error Codes:  - N/A
     * Revokes the current session access token for the HENNGE Email Archive API
     */
    async logoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/auth/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This endpoint will revokes the current session.  Error Codes:  - N/A
     * Revokes the current session access token for the HENNGE Email Archive API
     */
    async logout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logoutRaw(initOverrides);
    }

    /**
     * This will update the current user\'s profile info.  The user requires the permission `users:update-profile` to be able to call this endpoint.  Error Codes:  - HTTP 422    - 1002 => nothing to update.
     * Update own user info
     */
    async updateUserInfoRaw(requestParameters: UpdateUserInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserInfoV0>> {
        if (requestParameters['userInfoUpdateV0'] == null) {
            throw new runtime.RequiredError(
                'userInfoUpdateV0',
                'Required parameter "userInfoUpdateV0" was null or undefined when calling updateUserInfo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/auth/userinfo`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserInfoUpdateV0ToJSON(requestParameters['userInfoUpdateV0']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserInfoV0FromJSON(jsonValue));
    }

    /**
     * This will update the current user\'s profile info.  The user requires the permission `users:update-profile` to be able to call this endpoint.  Error Codes:  - HTTP 422    - 1002 => nothing to update.
     * Update own user info
     */
    async updateUserInfo(requestParameters: UpdateUserInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserInfoV0> {
        const response = await this.updateUserInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

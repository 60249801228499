import type { StoreDefinition } from '.';
import type { UserV0 } from '@/api';
import type { DateRange } from '@/types/DateRange';
import type { JobSearchState } from '@/types/JobSearchSettings';
import type { SearchRangeOption } from '@/types/SearchRange';

import { cloneDeep } from 'lodash-es';

const getInitialState = (): JobSearchState => {
  const tenant = useTenantStore();

  return {
    // search parameters
    dates: SearchRanges[DEFAULT_SEARCH_RANGE_OPTION].getDateRange(tenant.timezone),
    rangeIndex: DEFAULT_SEARCH_RANGE_OPTION,
    users: [],
    total: 0,
    resultPerPage: 10,
  };
};

const storeDefinition: StoreDefinition<JobSearchState> = {
  state: getInitialState,

  actions: {
    getDateRangeById(id: SearchRangeOption, timezone: string): DateRange {
      return SearchRanges[id].getDateRange(timezone);
    },

    setDates(range: DateRange): void {
      this.dates = cloneDeep(range);
    },

    setRangeIndex(index: SearchRangeOption): void {
      this.rangeIndex = index;
    },

    setTotal(params: number): void {
      this.total = params;
    },

    // users
    setUsers(params: UserV0[]): void {
      this.users = cloneDeep(params);
    },

    addUser(value: UserV0): void {
      this.users.push(value);
    },

    deleteUser(valueIndex: number): void {
      this.users.splice(valueIndex, 1);
    },
  },
};

export const useJobSearchStore = defineStore('jobSearch', storeDefinition);

export default useJobSearchStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useJobSearchStore, import.meta.hot));
}

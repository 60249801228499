/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Source: https://docs.google.com/spreadsheets/d/1EcfremvOJ8qY9_XxWh5JMr7MhNJMvRJtIsQSMf7vO7o/edit#gid=1281456260.
 * @export
 */
export const Permission = {
    AccessPoliciesgetAll: 'access-policies:get-all',
    Archivemailattachmentdownload: 'archive:mail:attachment:download',
    Archivemailattachmentpreview: 'archive:mail:attachment:preview',
    Archivemaildefrost: 'archive:mail:defrost',
    ArchivemaildownloadCsv: 'archive:mail:download-csv',
    ArchivemaildownloadMbox: 'archive:mail:download-mbox',
    ArchivemaildownloadPst: 'archive:mail:download-pst',
    ArchivemaildownloadRaw: 'archive:mail:download-raw',
    ArchivemaildownloadReadable: 'archive:mail:download-readable',
    ArchivemailexportHeaders: 'archive:mail:export-headers',
    ArchivemailgetDetails: 'archive:mail:get-details',
    ArchivemailgetHeaders: 'archive:mail:get-headers',
    Archivemailsearch: 'archive:mail:search',
    Archivemailsearchhistory: 'archive:mail:search:history',
    Archivesettingsget: 'archive:settings:get',
    ArchivestatsgetRecent: 'archive:stats:get-recent',
    Domainget: 'domain:get',
    Domainupdate: 'domain:update',
    OperationLogsdownload: 'operation-logs:download',
    OperationLogsgetAll: 'operation-logs:get-all',
    SearchPoliciescreate: 'search-policies:create',
    SearchPoliciesdelete: 'search-policies:delete',
    SearchPoliciesget: 'search-policies:get',
    SearchPoliciesgetAll: 'search-policies:get-all',
    SearchPoliciesupdate: 'search-policies:update',
    Userscreate: 'users:create',
    Usersdelete: 'users:delete',
    Usersget: 'users:get',
    UsersgetAll: 'users:get-all',
    Usersupdate: 'users:update',
    UsersupdateProfile: 'users:update-profile',
    UsersupdateSelf: 'users:update-self'
} as const;
export type Permission = typeof Permission[keyof typeof Permission];


export function instanceOfPermission(value: any): boolean {
    for (const key in Permission) {
        if (Object.prototype.hasOwnProperty.call(Permission, key)) {
            if (Permission[key as keyof typeof Permission] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PermissionFromJSON(json: any): Permission {
    return PermissionFromJSONTyped(json, false);
}

export function PermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Permission {
    return json as Permission;
}

export function PermissionToJSON(value?: Permission | null): any {
    return value as any;
}

export function PermissionToJSONTyped(value: any, ignoreDiscriminator: boolean): Permission {
    return value as Permission;
}


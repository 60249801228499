<template>
  <nav
    v-if="visibleMenuItems.length"
    data-testid="BaseNav"
    :inert="overlay.count > 0"
    class="Nav"
    :class="{ 'Nav-open': open, 'Nav-closed': !open }"
  >
    <IconAction class="ArrowToggle" mode="dark" size="sm" @click="toggleNav">
      <NobitaIconChevronRightRounded class="Arrow" />
    </IconAction>

    <div class="Container" @click="openNav">
      <SkeletonNav class="Menu" :is-loading="!tenant.isLoggedIn" :params="SKELETON_STRUCTURE">
        <ul class="Menu" :inert="!open">
          <BaseNavSection
            v-for="(section, index) in visibleMenuItems"
            :key="`nav-section-${index}`"
            :section
            :index
          />
        </ul>
      </SkeletonNav>
    </div>
  </nav>
</template>

<script setup lang="ts">
import type { NavSection } from '@/types/Nav';
import type { SkeletonNavBuilder } from '@/types/Skeleton';

const { tenant, overlay } = useStores('tenant', 'overlay');

const menuItems = computed<NavSection[]>(() => [
  {
    id: 'emailArchive',
    title: 'page.emailArchive',
    items: [
      {
        title: 'page.archiveSearch',
        route: 'archiveSearch',
        isShown: tenant.authorizedPages.archiveSearch,
      },
      {
        title: 'page.searchHistory',
        route: 'searchHistory',
        isShown: tenant.authorizedPages.searchHistory,
      },
      {
        title: 'page.archiveStatistics',
        route: 'archiveStatistics',
        isShown: tenant.authorizedPages.archiveStatistics,
      },
    ],
  },
  {
    id: 'jobList',
    title: 'page.jobList',
    items: [
      {
        title: 'page.bulkDownload',
        route: 'bulkDownload',
        isShown: tenant.authorizedPages.bulkDownload,
      },
    ],
  },
  {
    id: 'settings',
    title: 'page.settings',
    items: [
      {
        title: 'page.userSettings',
        route: 'userSettings',
        isShown: tenant.authorizedPages.userSettings,
      },
      {
        title: 'page.searchPolicySettings',
        route: 'searchPolicySettings',
        isShown: tenant.authorizedPages.searchPolicySettings,
      },
      {
        title: 'page.consoleSettings',
        route: 'consoleSettings',
        isShown: tenant.authorizedPages.consoleSettings,
      },
      {
        title: 'page.archiveSettings',
        route: 'archiveSettings',
        isShown: tenant.authorizedPages.archiveSettings,
      },
    ],
  },
  {
    id: 'log',
    title: 'page.log',
    items: [
      {
        title: 'page.operationLog',
        route: 'operationLog',
        isShown: tenant.authorizedPages.operationLog,
      },
    ],
  },
]);

const visibleMenuItems = computed<NavSection[]>(() =>
  menuItems.value.filter((section: NavSection) => section.items.some((item) => item.isShown)),
);

const SKELETON_STRUCTURE = {
  navGroups: [{ navItems: 4 }, { navItems: 4 }],
} as const satisfies SkeletonNavBuilder;

// Active state

const open = defineModel<boolean>({ required: false, default: true });

function toggleNav() {
  open.value = !open.value;
}

function openNav() {
  if (!open.value) {
    open.value = true;
  }
}

// Auto-close Menu Functionality

const { width } = useWindowSize();

// XL Breakpoint
const BREAKPOINT_WIDTH = 1440; // edited from 1400, then 1450. This is the breakpoint for smooth auto-retraction of the nav.

watch(
  () => width.value >= BREAKPOINT_WIDTH,
  (isWide) => {
    if (!isWide && open.value) {
      open.value = false;
    } else if (isWide && !open.value) {
      open.value = true;
    }
  },
  {
    immediate: true,
  },
);
</script>

<style scoped lang="scss">
.Nav {
  position: relative;
  z-index: 110;
  flex: 0 0 auto;
  box-sizing: border-box;
  width: $nav-min-width;

  @include setTransition(width);

  &-open {
    width: $nav-width;
  }
  &-closed {
    cursor: pointer;
  }
}

.Container {
  position: fixed;
  left: -$nav-width + $nav-min-width;
  width: $nav-width;
  height: calc(100vh - #{$header-height});
  border-right: 1px solid black(12);
  background-color: neutral(100);
  box-sizing: border-box;
  overflow: hidden auto;

  @include setTransition(left, background-color);

  .Nav-open & {
    left: 0;
  }
  .Nav-closed &:hover {
    background-color: black(
      5
    ); // TODO: this SHOULD be neutral(200) after all the rebranding changes hit master
  }
}

.Menu {
  position: relative;
}

.Menu,
:deep(.Menu) {
  // Deep needed for skeleton nav loader
  list-style-type: none;
  margin: 0;
  padding: 0;
  transition-delay: 0s;
  width: $nav-width;
  box-sizing: border-box;
  overflow: hidden;
  opacity: 0;

  @include setTransitionMedium(opacity, max-width);

  .Nav-open & {
    opacity: 1;
    max-width: $nav-width;
  }
}

.ArrowToggle {
  display: block;
  position: fixed;
  z-index: 50;
  bottom: 40px;
  left: 20px;
  line-height: 0;
  padding: 4px; // Specialty padding

  @include setTransition(left);

  .Nav-open & {
    left: $nav-width - 12px;
  }
}

.Arrow {
  transform: rotate(0deg);

  @include setTransition(transform);

  .Nav-open & {
    transform: rotate(-180deg);
  }
}
</style>

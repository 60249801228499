import type { Dayjs } from 'dayjs';

import windowsZones from '@/data/windowsZones.json';

// tz database (olson) keys; e.g. `Asia/Tokyo`
export type TimezoneId = string;

// tz database keys that are defined in `windowsZones.json`
export type WindowsTimezoneId = (typeof windowsZones)[number]['id'];

export interface Timezone {
  id: WindowsTimezoneId;
  name: string;
  label: string;
}

const ABBREVIATION_OVERRIDES: { [timezoneId: WindowsTimezoneId]: string } = {
  'Asia/Tokyo': 'JST',
  'Asia/Taipei': 'TST',
};

export const useTimezoneStore = defineStore('timezone', {
  state: () => ({
    activeTimezone: '',
  }),

  getters: {
    timezones(): Timezone[] {
      return windowsZones;
    },
  },

  actions: {
    setTimezone(timezoneId: TimezoneId) {
      this.activeTimezone = timezoneId;
    },

    getAbbreviation(timezoneId: TimezoneId, dt: Dayjs): string | undefined {
      const abbrOverride = ABBREVIATION_OVERRIDES[timezoneId];
      if (abbrOverride) return abbrOverride;
      else {
        const generatedAbbr = dt.tz(timezoneId).format('z');
        if (generatedAbbr.startsWith('GMT+') || generatedAbbr.startsWith('GMT-')) return undefined;
        else return generatedAbbr;
      }
    },

    getTimezone(timezoneId: WindowsTimezoneId): Timezone | undefined {
      return windowsZones.find((zone) => zone.id === timezoneId);
    },
  },
});

export default useTimezoneStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTimezoneStore, import.meta.hot));
}

import type { DateRange } from '@/types/DateRange';
import type { RowLimitOption } from '@/types/Pagination';
import type { SearchRangeOption } from '@/types/SearchRange';

import { OperationTypeV0 } from '@/api';
import { instanceOfOperationTypeV0, OperationTypeV0FromJSON } from '@/api/models/OperationTypeV0';
import { schemas } from '@/api/zod';

import '@/plugins/dayjs';

import dayjs from 'dayjs';
import qs from 'qs';
import { z } from 'zod';

export type QueryParameterModel = {
  limit: RowLimitOption;
  operationTypes: Set<OperationTypeV0>;
  userEmails?: Set<string> | null;
} & (
  | {
      rangeIndex: 'custom';
      dates: DateRange;
    }
  | {
      rangeIndex: Exclude<SearchRangeOption, 'custom'>;
      dates?: DateRange;
    }
);

export interface ParseResult {
  dates?: DateRange;
  limit?: RowLimitOption;
  operationTypes: Set<OperationTypeV0>;
  rangeIndex: SearchRangeOption;
  userEmails: Set<string>;
}

/**
 * Object key order matters here as it will determine the order of the keys in the query string.
 */
export function generateQueryString(request: QueryParameterModel): string {
  type ValueType =
    | SearchRangeOption
    | Exclude<RowLimitOption, typeof DEFAULT_LIMIT_VALUE>
    | Date
    | typeof schemas.OperationTypeQueryParameter.Enum
    | string[];

  const queryObject: Record<string, ValueType> = {
    date_range: request.rangeIndex,
  };

  if (request.rangeIndex === 'custom') {
    queryObject.start = request.dates.start;
    queryObject.end = request.dates.end;
  }

  if (request.limit !== DEFAULT_LIMIT_VALUE) {
    queryObject.limit = request.limit;
  }

  queryObject.user_emails = [...(request.userEmails ?? [])];
  queryObject.operation_types = [...request.operationTypes].map((x) =>
    // For the query string, we will replace "getAccessToken" with "login"
    x === OperationTypeV0.GetAccessToken ? 'login' : x,
  );

  const result = qs.stringify(queryObject, {
    encode: false,
    format: 'RFC1738',
    arrayFormat: 'comma',
    serializeDate: (date: Date) => date.toISOString(),
  });

  return result;
}

/**
 * This parses the query string for the operation log page's saved search settings.
 * General rule is to allow other unused keys to pass through,
 * but if there is a malformed value for a known key, we throw away the query string.
 * @param queryString Defaults to `location.search`
 * @param timezone The timezone to use for parsing dates, as an Olson DB string like 'America/New_York'
 */
export function parseQueryString(queryString: string, timezone: string): ParseResult | null {
  if (queryString.length <= 1) return null; // accounts for possible '?' prefix

  const params = qs.parse(queryString, { ignoreQueryPrefix: true, comma: true });

  // Process Dates

  const earliestDatePossible = new Date(0);
  const endOfToday = dayjs().tz(timezone).endOf('day').toDate();

  const ZDateRangeSchema = z
    .object({
      date_range: z.enum(SearchRangeOptions).exclude(['custom']),
    })
    .or(
      z.object({
        start: z.coerce.date().min(earliestDatePossible).max(endOfToday),
        end: z.coerce.date().min(earliestDatePossible).max(endOfToday),
        date_range: z.literal('custom').optional().default('custom'),
      }),
    );

  // Will throw on invalid data
  const dateRangeData = ZDateRangeSchema.parse(params);

  let dates;
  if (dateRangeData.date_range === 'custom') {
    if (dateRangeData.start >= dateRangeData.end) {
      throw new NobitaParseError('Start date cannot be after end date');
    }

    dates = {
      start: dayjs(dateRangeData.start).tz(timezone).startOf('minute').toDate(),
      end: dayjs(dateRangeData.end).tz(timezone).endOf('minute').toDate(),
    };
  }

  // Process Users and Operation Types

  const schema = schemas.OperationLogSearchQueryParameters.partial()
    .strip()
    .extend({
      limit: z
        .string()
        .pipe(z.coerce.number().int().gte(0).lte(200))
        .pipe(ZRowLimitOptions)
        .catch(DEFAULT_LIMIT_VALUE), // Will default to DEFAULT_LIMIT_VALUE if invalid
    });

  // Ensure array type for user_emails and operation_types, even with just 1 value
  if (typeof params.user_emails === 'string') {
    params.user_emails = [params.user_emails];
  }
  if (typeof params.operation_types === 'string') {
    params.operation_types = [params.operation_types];
  }

  // Will throw on invalid data
  const criteriaData = schema.parse(params);

  const result: ParseResult = {
    limit: criteriaData.limit ? criteriaData.limit : undefined,
    rangeIndex: dateRangeData.date_range,
    userEmails: criteriaData.user_emails?.length ? new Set(criteriaData.user_emails) : new Set(),
    operationTypes: criteriaData.operation_types?.length
      ? new Set(
          criteriaData.operation_types.map((x) => {
            if (x === 'login') {
              return OperationTypeV0.GetAccessToken;
            }

            if (instanceOfOperationTypeV0(x)) {
              return OperationTypeV0FromJSON(x);
            }

            // we'll never hit this unless we add something above in the serialisation code and forget to handle it here in the deserialisation
            throw new NobitaParseError(`Unknown operation type: ${x}`);
          }),
        )
      : new Set(),
  };

  if (dates) {
    result.dates = dates;
  }

  return result;
}

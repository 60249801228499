import type { OperationLogV0 } from '@/api';

import { OperationTypeV0 } from '@/api';

import { cloneDeep } from 'lodash-es';

export interface OperationLogState {
  logs: OperationLogV0[];
  logView: OperationLogV0;
  skiptoken: string | null;
  backtoken: string | null;
}

export const useOperationLogStore = defineStore('operationLog', {
  state: (): OperationLogState => ({
    logs: [],
    logView: {
      id: '',
      apiVersion: '',
      attachmentId: undefined,
      domainId: '',
      downloadFormat: undefined,
      errorCode: undefined,
      httpResponseStatus: 0,
      initialSearchPolicy: undefined,
      initialUser: undefined,
      ipAddress: '',
      operationType: OperationTypeV0.Logout,
      searchPolicyId: undefined,
      searchPolicyName: undefined,
      searchQuery: undefined,
      operationLogSearchQuery: undefined,
      sessionKey: undefined,
      targetUserEmail: undefined,
      targetUserId: undefined,
      timestamp: new Date(0),
      uidl: undefined,
      uidls: undefined,
      updatedSearchPolicy: undefined,
      updatedUser: undefined,
      userEmail: undefined,
      userId: undefined,
    },
    skiptoken: null,
    backtoken: null,
  }),

  actions: {
    setOperationLog(param: OperationLogV0[]): void {
      this.logs = cloneDeep(param);
    },

    setLogView(param: OperationLogV0): void {
      this.logView = cloneDeep(param);
    },

    setSkiptoken(param: string | null): void {
      this.skiptoken = param;
    },

    setBacktoken(param: string | null): void {
      this.backtoken = param;
    },
  },
});

export default useOperationLogStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOperationLogStore, import.meta.hot));
}

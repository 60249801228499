/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessPolicyOptionV0 } from './AccessPolicyOptionV0';
import {
    AccessPolicyOptionV0FromJSON,
    AccessPolicyOptionV0FromJSONTyped,
    AccessPolicyOptionV0ToJSON,
    AccessPolicyOptionV0ToJSONTyped,
} from './AccessPolicyOptionV0';

/**
 * 
 * @export
 * @interface CustomAccessPolicyV0
 */
export interface CustomAccessPolicyV0 {
    /**
     * 
     * @type {Array<AccessPolicyOptionV0>}
     * @memberof CustomAccessPolicyV0
     */
    options: Array<AccessPolicyOptionV0>;
    /**
     * 
     * @type {string}
     * @memberof CustomAccessPolicyV0
     */
    type: CustomAccessPolicyV0TypeEnum;
}


/**
 * @export
 */
export const CustomAccessPolicyV0TypeEnum = {
    Custom: 'custom'
} as const;
export type CustomAccessPolicyV0TypeEnum = typeof CustomAccessPolicyV0TypeEnum[keyof typeof CustomAccessPolicyV0TypeEnum];


/**
 * Check if a given object implements the CustomAccessPolicyV0 interface.
 */
export function instanceOfCustomAccessPolicyV0(value: object): value is CustomAccessPolicyV0 {
    if (!('options' in value) || value['options'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function CustomAccessPolicyV0FromJSON(json: any): CustomAccessPolicyV0 {
    return CustomAccessPolicyV0FromJSONTyped(json, false);
}

export function CustomAccessPolicyV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomAccessPolicyV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'options': ((json['options'] as Array<any>).map(AccessPolicyOptionV0FromJSON)),
        'type': json['type'],
    };
}

export function CustomAccessPolicyV0ToJSON(json: any): CustomAccessPolicyV0 {
    return CustomAccessPolicyV0ToJSONTyped(json, false);
}

export function CustomAccessPolicyV0ToJSONTyped(value?: CustomAccessPolicyV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'options': ((value['options'] as Array<any>).map(AccessPolicyOptionV0ToJSON)),
        'type': value['type'],
    };
}


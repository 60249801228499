/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ArchiveStatusV0 } from './ArchiveStatusV0';
import {
    ArchiveStatusV0FromJSON,
    ArchiveStatusV0FromJSONTyped,
    ArchiveStatusV0ToJSON,
    ArchiveStatusV0ToJSONTyped,
} from './ArchiveStatusV0';

/**
 * 
 * @export
 * @interface ArchiveHeadersV0
 */
export interface ArchiveHeadersV0 {
    /**
     * 
     * @type {Date}
     * @memberof ArchiveHeadersV0
     */
    archivedDate: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArchiveHeadersV0
     */
    cc?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof ArchiveHeadersV0
     */
    dataSize: number;
    /**
     * 
     * @type {string}
     * @memberof ArchiveHeadersV0
     */
    date?: string | null;
    /**
     * 
     * If the status is frozen, this field indicates how long it would take to defrost the mail in milliseconds.
     * If the status is defrosting, this field indicates how long it will take in total to defrost the mail in milliseconds.
     * If the status is defrosted, this field indicates how long it took to defrost the mail in milliseconds.
     * 
     * For defrosted / defrosting mails, the API response already includes a field indicating when the defrosting will finish (or did finish for defrosted). However, this field could be used to know when defrosting began by calculating back from defrosted_from.
     * 
     * @type {number}
     * @memberof ArchiveHeadersV0
     */
    defrostDurationMs: number | null;
    /**
     * If the status is defrosted, this field indicates when the mail reached the defrosted state. If the status is defrosting, this field indicates when the mail will be defrosted.
     * @type {Date}
     * @memberof ArchiveHeadersV0
     */
    defrostedFrom: Date | null;
    /**
     * If the status is defrosted, this field indicates when the mail will return to the frozen state. If the status is defrosting, this field indicates when the mail will return to the frozen state again after defrosting completes.
     * @type {Date}
     * @memberof ArchiveHeadersV0
     */
    defrostedUntil: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveHeadersV0
     */
    envelopeFrom: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArchiveHeadersV0
     */
    envelopeTo: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArchiveHeadersV0
     */
    from?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ArchiveHeadersV0
     */
    hasAttachment: boolean;
    /**
     * 
     * @type {ArchiveStatusV0}
     * @memberof ArchiveHeadersV0
     */
    status: ArchiveStatusV0;
    /**
     * 
     * @type {string}
     * @memberof ArchiveHeadersV0
     */
    subject?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArchiveHeadersV0
     */
    to?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveHeadersV0
     */
    uidl: string;
}



/**
 * Check if a given object implements the ArchiveHeadersV0 interface.
 */
export function instanceOfArchiveHeadersV0(value: object): value is ArchiveHeadersV0 {
    if (!('archivedDate' in value) || value['archivedDate'] === undefined) return false;
    if (!('dataSize' in value) || value['dataSize'] === undefined) return false;
    if (!('defrostDurationMs' in value) || value['defrostDurationMs'] === undefined) return false;
    if (!('defrostedFrom' in value) || value['defrostedFrom'] === undefined) return false;
    if (!('defrostedUntil' in value) || value['defrostedUntil'] === undefined) return false;
    if (!('envelopeFrom' in value) || value['envelopeFrom'] === undefined) return false;
    if (!('envelopeTo' in value) || value['envelopeTo'] === undefined) return false;
    if (!('hasAttachment' in value) || value['hasAttachment'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('uidl' in value) || value['uidl'] === undefined) return false;
    return true;
}

export function ArchiveHeadersV0FromJSON(json: any): ArchiveHeadersV0 {
    return ArchiveHeadersV0FromJSONTyped(json, false);
}

export function ArchiveHeadersV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): ArchiveHeadersV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'archivedDate': (new Date(json['archived_date'])),
        'cc': json['cc'] == null ? undefined : json['cc'],
        'dataSize': json['data_size'],
        'date': json['date'] == null ? undefined : json['date'],
        'defrostDurationMs': json['defrost_duration_ms'],
        'defrostedFrom': (json['defrosted_from'] == null ? null : new Date(json['defrosted_from'])),
        'defrostedUntil': (json['defrosted_until'] == null ? null : new Date(json['defrosted_until'])),
        'envelopeFrom': json['envelope_from'],
        'envelopeTo': json['envelope_to'],
        'from': json['from'] == null ? undefined : json['from'],
        'hasAttachment': json['has_attachment'],
        'status': ArchiveStatusV0FromJSON(json['status']),
        'subject': json['subject'] == null ? undefined : json['subject'],
        'to': json['to'] == null ? undefined : json['to'],
        'uidl': json['uidl'],
    };
}

export function ArchiveHeadersV0ToJSON(json: any): ArchiveHeadersV0 {
    return ArchiveHeadersV0ToJSONTyped(json, false);
}

export function ArchiveHeadersV0ToJSONTyped(value?: ArchiveHeadersV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'archived_date': ((value['archivedDate']).toISOString()),
        'cc': value['cc'],
        'data_size': value['dataSize'],
        'date': value['date'],
        'defrost_duration_ms': value['defrostDurationMs'],
        'defrosted_from': (value['defrostedFrom'] == null ? null : (value['defrostedFrom'] as any).toISOString()),
        'defrosted_until': (value['defrostedUntil'] == null ? null : (value['defrostedUntil'] as any).toISOString()),
        'envelope_from': value['envelopeFrom'],
        'envelope_to': value['envelopeTo'],
        'from': value['from'],
        'has_attachment': value['hasAttachment'],
        'status': ArchiveStatusV0ToJSON(value['status']),
        'subject': value['subject'],
        'to': value['to'],
        'uidl': value['uidl'],
    };
}


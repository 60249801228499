/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const IdpV0 = {
    Google: 'google',
    Microsoft: 'microsoft'
} as const;
export type IdpV0 = typeof IdpV0[keyof typeof IdpV0];


export function instanceOfIdpV0(value: any): boolean {
    for (const key in IdpV0) {
        if (Object.prototype.hasOwnProperty.call(IdpV0, key)) {
            if (IdpV0[key as keyof typeof IdpV0] === value) {
                return true;
            }
        }
    }
    return false;
}

export function IdpV0FromJSON(json: any): IdpV0 {
    return IdpV0FromJSONTyped(json, false);
}

export function IdpV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): IdpV0 {
    return json as IdpV0;
}

export function IdpV0ToJSON(value?: IdpV0 | null): any {
    return value as any;
}

export function IdpV0ToJSONTyped(value: any, ignoreDiscriminator: boolean): IdpV0 {
    return value as IdpV0;
}


/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MailAttachmentV0 } from './MailAttachmentV0';
import {
    MailAttachmentV0FromJSON,
    MailAttachmentV0FromJSONTyped,
    MailAttachmentV0ToJSON,
    MailAttachmentV0ToJSONTyped,
} from './MailAttachmentV0';

/**
 * 
 * @export
 * @interface ExtraMailDetailsV0
 */
export interface ExtraMailDetailsV0 {
    /**
     * List of the attachment details of the mail, if any.
     * @type {Array<MailAttachmentV0>}
     * @memberof ExtraMailDetailsV0
     */
    attachments?: Array<MailAttachmentV0>;
    /**
     * The main HTML part of the mail if any, rendered as plain text.
     * @type {string}
     * @memberof ExtraMailDetailsV0
     */
    simplifiedHtmlPart?: string | null;
    /**
     * The main text part of the mail, if any.
     * @type {string}
     * @memberof ExtraMailDetailsV0
     */
    textPart?: string | null;
}

/**
 * Check if a given object implements the ExtraMailDetailsV0 interface.
 */
export function instanceOfExtraMailDetailsV0(value: object): value is ExtraMailDetailsV0 {
    return true;
}

export function ExtraMailDetailsV0FromJSON(json: any): ExtraMailDetailsV0 {
    return ExtraMailDetailsV0FromJSONTyped(json, false);
}

export function ExtraMailDetailsV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtraMailDetailsV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'attachments': json['attachments'] == null ? undefined : ((json['attachments'] as Array<any>).map(MailAttachmentV0FromJSON)),
        'simplifiedHtmlPart': json['simplified_html_part'] == null ? undefined : json['simplified_html_part'],
        'textPart': json['text_part'] == null ? undefined : json['text_part'],
    };
}

export function ExtraMailDetailsV0ToJSON(json: any): ExtraMailDetailsV0 {
    return ExtraMailDetailsV0ToJSONTyped(json, false);
}

export function ExtraMailDetailsV0ToJSONTyped(value?: ExtraMailDetailsV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'attachments': value['attachments'] == null ? undefined : ((value['attachments'] as Array<any>).map(MailAttachmentV0ToJSON)),
        'simplified_html_part': value['simplifiedHtmlPart'],
        'text_part': value['textPart'],
    };
}


export interface DateRange {
  start: Date;
  end: Date;
}

export function isDateRange(obj: unknown): obj is DateRange {
  if (typeof obj === 'object' && obj !== null && 'start' in obj && 'end' in obj) {
    return obj.start instanceof Date && obj.end instanceof Date;
  }
  return false;
}

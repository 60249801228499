/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ECJWKV0
 */
export interface ECJWKV0 {
    /**
     * 
     * @type {string}
     * @memberof ECJWKV0
     */
    alg: string;
    /**
     * 
     * @type {string}
     * @memberof ECJWKV0
     */
    crv: string;
    /**
     * 
     * @type {string}
     * @memberof ECJWKV0
     */
    kid: string;
    /**
     * 
     * @type {string}
     * @memberof ECJWKV0
     */
    kty: ECJWKV0KtyEnum;
    /**
     * 
     * @type {string}
     * @memberof ECJWKV0
     */
    use: string;
    /**
     * 
     * @type {string}
     * @memberof ECJWKV0
     */
    x: string;
    /**
     * 
     * @type {string}
     * @memberof ECJWKV0
     */
    y: string;
}


/**
 * @export
 */
export const ECJWKV0KtyEnum = {
    Ec: 'EC'
} as const;
export type ECJWKV0KtyEnum = typeof ECJWKV0KtyEnum[keyof typeof ECJWKV0KtyEnum];


/**
 * Check if a given object implements the ECJWKV0 interface.
 */
export function instanceOfECJWKV0(value: object): value is ECJWKV0 {
    if (!('alg' in value) || value['alg'] === undefined) return false;
    if (!('crv' in value) || value['crv'] === undefined) return false;
    if (!('kid' in value) || value['kid'] === undefined) return false;
    if (!('kty' in value) || value['kty'] === undefined) return false;
    if (!('use' in value) || value['use'] === undefined) return false;
    if (!('x' in value) || value['x'] === undefined) return false;
    if (!('y' in value) || value['y'] === undefined) return false;
    return true;
}

export function ECJWKV0FromJSON(json: any): ECJWKV0 {
    return ECJWKV0FromJSONTyped(json, false);
}

export function ECJWKV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): ECJWKV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'alg': json['alg'],
        'crv': json['crv'],
        'kid': json['kid'],
        'kty': json['kty'],
        'use': json['use'],
        'x': json['x'],
        'y': json['y'],
    };
}

export function ECJWKV0ToJSON(json: any): ECJWKV0 {
    return ECJWKV0ToJSONTyped(json, false);
}

export function ECJWKV0ToJSONTyped(value?: ECJWKV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'alg': value['alg'],
        'crv': value['crv'],
        'kid': value['kid'],
        'kty': value['kty'],
        'use': value['use'],
        'x': value['x'],
        'y': value['y'],
    };
}


import type { AccessPolicyIdV0, NamedAccessPolicyWithOptionsV0 } from '@/api';

export interface AccessPoliciesState {
  policies: NamedAccessPolicyWithOptionsV0[];
}

export const useAccessPoliciesStore = defineStore('accessPolicies', {
  state: (): AccessPoliciesState => ({
    policies: [],
  }),

  actions: {
    setAccessPolicies(policies: NamedAccessPolicyWithOptionsV0[]) {
      this.policies = policies;
    },

    getAccessPolicyById(id: AccessPolicyIdV0): NamedAccessPolicyWithOptionsV0 {
      const index = this.policies.findIndex((e) => e.id === id);
      if (index > -1) {
        return this.policies[index];
      } else {
        throw new Error(`No access policy found with id: ${id}`);
      }
    },
  },
});

export default useAccessPoliciesStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAccessPoliciesStore, import.meta.hot));
}

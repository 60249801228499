import type { OverlayType } from '@/store/overlay';
import type { InjectionKey, MaybeRefOrGetter } from 'vue';

export type KeypressSource = OverlayType | 'base';

export type EscapeCallbackHandlerRegisterFn = (
  source: MaybeRefOrGetter<KeypressSource>,
  escapeCallback: () => unknown,
) => void;

export const KeypressHandlerInjectionKey: InjectionKey<EscapeCallbackHandlerRegisterFn> =
  Symbol('Keypress Handler');

export function useKeypresses() {
  /**
   * In general, don't use this function externally. Invoking it more than once could cause race conditions.
   *
   * @param callbackFunction - The function to be called when the [escape] key is pressed
   * @param allowForInputEls - Allow callback function to be called even if focus is currently within an \<input\> or \<textarea\> element
   */
  function onEscapeKey(callbackFunction: () => unknown, allowForInputEls = false) {
    const { escape } = useMagicKeys();

    // Make sure we're not in an input element
    const activeElement = useActiveElement({ triggerOnRemoval: true });
    const notUsingInput = computed(() =>
      allowForInputEls
        ? true
        : activeElement.value?.tagName !== 'INPUT' && activeElement.value?.tagName !== 'TEXTAREA',
    );
    const validEscape = computed(() => escape.value && notUsingInput.value);

    whenever(validEscape, callbackFunction);
  }

  /**
   * This fn should only be invoked ONCE in the App.vue root
   * It is abstracted as a concept into this file for separation of concerns
   * and in preparation for adding more keystroke listeners in the future
   */
  function watchKeypresses() {
    const { overlay } = useStores('overlay');

    onEscapeKey(() => {
      const topOverlay: KeypressSource = overlay.topKey ?? 'base';

      callbackFunctions
        .filter(([source]) => source === topOverlay)
        .forEach(([_, callback]) => callback());
    });

    // At the moment we haven't introduced a concept of unregistering escape callbacks
    // But this shouldn't be a problem because of the filter above
    const callbackFunctions: [KeypressSource, () => unknown][] = [];

    const registerEscapeCallback: EscapeCallbackHandlerRegisterFn = (source, escapeCallback) => {
      callbackFunctions.push([toValue(source), escapeCallback]);
    };

    provide(KeypressHandlerInjectionKey, registerEscapeCallback);
  }

  return {
    watchKeypresses,
  };
}

<template>
  <BaseDropdown :active align="bottom" justify="right">
    <template #items>
      <DropdownItem
        external-link="https://support.hdeone.com/hc/categories/204651087-HENNGE-Email-Archive"
      >
        {{ $t('page.helpCenter') }}
      </DropdownItem>
    </template>
  </BaseDropdown>
</template>

<script setup lang="ts">
defineProps<{
  active?: boolean;
}>();
</script>

export type EmailReadableOriginal = {
  isLoading: boolean;
  isLoaded: boolean;
  content: string | null;
};

export enum EMAIL_BODY_TAB {
  PLAIN = 0,
  HTML,
  ORIGINAL,
}

export enum EMAIL_BODY_TAB_LABEL {
  PLAIN = 'emailDetails.body_plain',
  HTML = 'emailDetails.body_html',
  ORIGINAL = 'emailDetails.original',
}

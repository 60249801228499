/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperationLogV0 } from './OperationLogV0';
import {
    OperationLogV0FromJSON,
    OperationLogV0FromJSONTyped,
    OperationLogV0ToJSON,
    OperationLogV0ToJSONTyped,
} from './OperationLogV0';

/**
 * 
 * @export
 * @interface OperationLogsV0
 */
export interface OperationLogsV0 {
    /**
     * 
     * @type {string}
     * @memberof OperationLogsV0
     */
    backtoken?: string | null;
    /**
     * 
     * @type {Array<OperationLogV0>}
     * @memberof OperationLogsV0
     */
    operationLogs: Array<OperationLogV0>;
    /**
     * 
     * @type {string}
     * @memberof OperationLogsV0
     */
    searchCode: string;
    /**
     * 
     * @type {string}
     * @memberof OperationLogsV0
     */
    skiptoken?: string | null;
}

/**
 * Check if a given object implements the OperationLogsV0 interface.
 */
export function instanceOfOperationLogsV0(value: object): value is OperationLogsV0 {
    if (!('operationLogs' in value) || value['operationLogs'] === undefined) return false;
    if (!('searchCode' in value) || value['searchCode'] === undefined) return false;
    return true;
}

export function OperationLogsV0FromJSON(json: any): OperationLogsV0 {
    return OperationLogsV0FromJSONTyped(json, false);
}

export function OperationLogsV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationLogsV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'backtoken': json['backtoken'] == null ? undefined : json['backtoken'],
        'operationLogs': ((json['operation_logs'] as Array<any>).map(OperationLogV0FromJSON)),
        'searchCode': json['search_code'],
        'skiptoken': json['skiptoken'] == null ? undefined : json['skiptoken'],
    };
}

export function OperationLogsV0ToJSON(json: any): OperationLogsV0 {
    return OperationLogsV0ToJSONTyped(json, false);
}

export function OperationLogsV0ToJSONTyped(value?: OperationLogsV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'backtoken': value['backtoken'],
        'operation_logs': ((value['operationLogs'] as Array<any>).map(OperationLogV0ToJSON)),
        'search_code': value['searchCode'],
        'skiptoken': value['skiptoken'],
    };
}


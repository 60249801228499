<template>
  <a
    v-if="to && isTargetExternal(to)"
    class="Action"
    :class="[
      `Action-${color}`,
      `Action-${size}`,
      {
        [`Action-icon_${iconPosition}`]: !!$slots.icon,
        'Action-outlined': outlined,
        'Action-block': block,
      },
    ]"
    :type
    :tabindex
    :disabled
    :href="to"
    :title="$t(title)"
    :target
    rel="noopener noreferrer"
  >
    <BaseIcon v-if="$slots.icon" class="Icon">
      <slot name="icon" class="Icon" />
    </BaseIcon>

    <slot />
  </a>

  <RouterLink
    v-else-if="to"
    class="Action"
    :class="[
      `Action-${color}`,
      `Action-${size}`,
      {
        [`Action-icon_${iconPosition}`]: !!$slots.icon,
        'Action-outlined': outlined,
        'Action-block': block,
      },
    ]"
    :type
    :tabindex
    :disabled
    :to
    :title="$t(title)"
    :target
    :rel="target === '_blank' ? 'noopener noreferrer' : undefined"
  >
    <BaseIcon v-if="$slots.icon" class="Icon">
      <slot name="icon" class="Icon" />
    </BaseIcon>

    <slot />
  </RouterLink>

  <button
    v-else
    class="Action"
    :class="[
      `Action-${color}`,
      `Action-${size}`,
      {
        [`Action-icon_${iconPosition}`]: !!$slots.icon,
        'Action-outlined': outlined,
        'Action-block': block,
      },
    ]"
    :type
    :tabindex
    :disabled
  >
    <BaseIcon v-if="$slots.icon" class="Icon">
      <slot name="icon" class="Icon" />
    </BaseIcon>

    <slot />
  </button>
</template>

<script setup lang="ts">
import type { TranslationKey } from '@/locales';
import type { RouteLocationRaw } from 'vue-router';

export type Props = {
  iconPosition?: 'left' | 'right';
  color?:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'textPrimary'
    | 'textSecondary'
    | 'transparentLight'
    | 'link';
  size?: 'sm' | 'md' | 'lg';
  block?: boolean;
  outlined?: boolean;
  disabled?: boolean;
  to?: RouteLocationRaw | string;
  target?: '_blank' | '_self';
  title?: TranslationKey | '';
  type?: 'button' | 'submit' | 'reset';
  tabindex?: -1 | 0;
};

withDefaults(defineProps<Props>(), {
  iconPosition: 'left',
  color: 'primary',
  size: 'md',
  type: 'button',
  to: '',
  target: undefined,
  title: '',
  tabindex: 0,
});

export type Slots = {
  default(): void;
  icon(): void;
};

// TODO: use the Slots type defined above here as well and don't repeat ourselves
// But this issue is blocking us: https://github.com/vuejs/eslint-plugin-vue/issues/2398
defineSlots<{
  default(): void;
  icon(): void;
}>();

const isTargetExternal = (target: RouteLocationRaw | string): target is string =>
  typeof target === 'string';
</script>

<!-- eslint-disable vue/max-lines-per-block -->
<style scoped lang="scss">
.Action {
  position: relative;
  display: inline-flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  word-break: keep-all;
  white-space: nowrap;
  font-weight: 500;
  text-decoration: none;

  @include setTransition(color, background-color, opacity, border);

  &-block {
    display: flex;
    width: 100%;
  }

  // sizes
  &-sm {
    height: 28px;
    padding: 0 8px;
    font-size: 12px;
    min-width: 56px;

    &.Action-icon_left {
      padding-left: 6px;
    }

    &.Action-icon_right {
      padding-right: 6px;
    }
  }

  &-md {
    height: 32px;
    padding: 0 16px;
    font-size: 14px;
    min-width: 64px;

    &.Action-icon_left {
      padding-left: 12px;
    }

    &.Action-icon_right {
      padding-right: 12px;
    }
  }

  &-lg {
    height: 40px;
    padding: 0 20px;
    font-size: 16px;
    min-width: 80px;

    &.Action-icon_left {
      padding-left: 12px;
    }

    &.Action-icon_right {
      padding-right: 12px;
    }
  }

  // disabled
  &:disabled {
    cursor: not-allowed;
  }

  // focus outline
  &:focus-visible {
    outline: 3px solid primary(600);
    outline-offset: 1px;
  }

  // colors
  &-primary {
    background-color: primary(700);
    border: 1px solid primary(700);
    color: white();

    &:hover {
      background-color: primary(900);
      border: 1px solid primary(900);
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:active {
      opacity: 0.38;
      background-color: primary(700);
      border: 1px solid primary(700);
    }
  }

  &-secondary {
    border: 1px solid black(12);
    background-color: neutral(50);
    color: primary(800);

    &:hover,
    &:focus {
      background-color: neutral(150);
    }

    &:active {
      background-color: alpha-primary(20);
      border-color: alpha-primary(20);
      color: primary(900);

      .Icon {
        fill: primary(900);
      }
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:active {
      background-color: neutral(50);
      color: neutral(350);

      .Icon {
        fill: neutral(350);
      }
    }
  }

  &-danger {
    border: 1px solid black(12);
    background-color: red(400);
    color: white();

    &:hover {
      background-color: red(600);
    }

    &:active {
      border-color: alpha-red(5);
      background-color: alpha-red(12);
      color: red(400);
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:active {
      background-color: red(400);
      border-color: black(12);
      color: white();
      opacity: 0.38;
    }
  }

  &-outlined {
    &.Action-primary {
      background-color: transparent;
      color: primary(200);
      border-color: primary(200);

      &:hover {
        background-color: white(12);
        color: primary(200);
        border-color: primary(200);
      }

      &:disabled {
        background-color: transparent;
        color: primary(200);
        border-color: primary(200);
      }

      &:disabled:hover {
        background-color: transparent;
      }
    }

    &.Action-secondary {
      background-color: transparent;
      color: white();
      border-color: white();

      &:hover {
        background-color: white(12);
        color: white();
        border-color: white();
      }

      &:disabled {
        background-color: transparent;
        color: white();
        border-color: white();
      }

      &:disabled:hover {
        background-color: transparent;
        color: white();
        border-color: white();
      }
    }

    &.Action-danger {
      background-color: transparent;
      color: red(400);
      border-color: transparent;

      &:hover {
        background-color: alpha-red(5);
      }

      &:active {
        background-color: alpha-red(12);
      }

      &:disabled,
      &:disabled:hover,
      &:disabled:active {
        background-color: transparent;
        opacity: 0.38;
        border-color: transparent;
        color: red(400);
      }
    }
  }

  &-textPrimary {
    color: primary(700);
    background-color: transparent;
    border: none;

    .Icon {
      fill: primary(700);
    }

    &:hover {
      color: primary(900);
      background-color: black(12);

      .Icon {
        fill: primary(900);
      }
    }

    &:focus {
      color: primary(900);
      background-color: alpha-primary(20);

      .Icon {
        fill: primary(900);
      }
    }

    &:disabled {
      opacity: 0.4;
      background-color: transparent;
      color: black(38);
      cursor: default;

      .Icon {
        fill: black(38);
      }
    }

    &:disabled:hover {
      background-color: transparent;
    }
  }

  &-textSecondary {
    color: black(87);
    background-color: transparent;
    border: none;

    .Icon {
      fill: black(87);
    }

    &:hover {
      background-color: black(12);
    }

    &:focus {
      color: black(87);
      background-color: alpha-primary(20);

      .Icon {
        fill: black(87);
      }
    }

    &:disabled {
      opacity: 0.4;
      background-color: transparent;
      color: black(38);
      cursor: default;

      .Icon {
        fill: black(38);
      }
    }

    &:disabled:hover {
      background-color: transparent;
    }
  }

  &-transparentLight {
    color: black(60);
    background-color: transparent;
    border: none;
    padding: 7px 16px;

    &:hover {
      color: black(87);
      background-color: transparent;
    }

    &:disabled {
      opacity: 0.4;
      background-color: transparent;
      cursor: default;
    }

    &:disabled:hover {
      background-color: transparent;
    }
  }

  &-link {
    color: primary(700);
    cursor: pointer;
    outline: none;
    border: none;
    box-sizing: border-box;
    text-decoration: none;
    background-color: transparent;

    .Icon {
      fill: primary(700);
    }

    &:hover,
    &:focus {
      color: primary(900);
      text-decoration: underline;

      .Icon {
        fill: primary(900);
      }
    }

    &:disabled {
      color: black(38);
      background-color: transparent;
      cursor: default;

      .Icon {
        fill: black(38);
      }

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

.Icon {
  @include setTransition(fill);

  .Action-icon_right & {
    order: 2;
  }

  // colors
  .Action-primary & {
    fill: white();
  }
  .Action-secondary & {
    fill: primary(800);
  }
  .Action-danger & {
    fill: white();
  }
  .Action-danger.Action-outlined & {
    fill: red(400);
  }

  .Action-danger:active & {
    fill: red(400);
  }

  // sizes
  .Action-sm & {
    width: 16px;
    height: 16px;
  }
  .Action-md & {
    width: 20px;
    height: 20px;
  }
  .Action-lg & {
    width: 24px;
    height: 24px;
  }
}
</style>

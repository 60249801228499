/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DiscoveryDocument,
  ErrorResponsePayloadV0,
} from '../models/index';
import {
    DiscoveryDocumentFromJSON,
    DiscoveryDocumentToJSON,
    ErrorResponsePayloadV0FromJSON,
    ErrorResponsePayloadV0ToJSON,
} from '../models/index';

/**
 * 
 */
export class DiscoveryApi extends runtime.BaseAPI {

    /**
     * This endpoint will return the openid/oauth2 endpoints for the server.\", This endpoint does not require authentication.  Error Codes:  - N/A
     * Get openid connection configuration.
     */
    async getDiscoveryDocWellKnownOpenidConfigurationGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiscoveryDocument>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/.well-known/openid-configuration`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscoveryDocumentFromJSON(jsonValue));
    }

    /**
     * This endpoint will return the openid/oauth2 endpoints for the server.\", This endpoint does not require authentication.  Error Codes:  - N/A
     * Get openid connection configuration.
     */
    async getDiscoveryDocWellKnownOpenidConfigurationGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiscoveryDocument> {
        const response = await this.getDiscoveryDocWellKnownOpenidConfigurationGetRaw(initOverrides);
        return await response.value();
    }

}

<template>
  <span class="NavBadge" :class="{ 'NavBadge-highlight': highlight }">
    {{ $t(badge) }}
  </span>
</template>

<script setup lang="ts">
import type { TranslationKey } from '@/locales';

export type Props = {
  badge: TranslationKey;
  highlight?: boolean;
};

defineProps<Props>();
</script>

<style scoped lang="scss">
.NavBadge {
  display: inline-block;
  padding: 3px 5px;
  margin-left: 4px;
  border-radius: 40px;
  background-color: neutral(150);
  color: neutral(450);
  font-size: 12px;
  text-transform: lowercase;

  &-highlight {
    background-color: green(50);
  }
}
</style>

/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TokenRequestV0
 */
export interface TokenRequestV0 {
    /**
     * 
     * @type {string}
     * @memberof TokenRequestV0
     */
    url: string;
}

/**
 * Check if a given object implements the TokenRequestV0 interface.
 */
export function instanceOfTokenRequestV0(value: object): value is TokenRequestV0 {
    if (!('url' in value) || value['url'] === undefined) return false;
    return true;
}

export function TokenRequestV0FromJSON(json: any): TokenRequestV0 {
    return TokenRequestV0FromJSONTyped(json, false);
}

export function TokenRequestV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenRequestV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'],
    };
}

export function TokenRequestV0ToJSON(json: any): TokenRequestV0 {
    return TokenRequestV0ToJSONTyped(json, false);
}

export function TokenRequestV0ToJSONTyped(value?: TokenRequestV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'url': value['url'],
    };
}


<template>
  <div class="RadioField">
    <input
      :id="inputId"
      v-model="model"
      class="RadioInput"
      type="radio"
      :name
      :value="inputValue"
      :disabled
    />
    <label
      :for="inputId"
      class="RadioLabel"
      :class="{ 'RadioLabel-checked': inputValue === model, 'RadioLabel-disabled': disabled }"
    >
      {{ label ? $t(label) : '' }}
    </label>
  </div>
</template>

<script setup lang="ts" generic="T extends string">
import type { TranslationKey } from '@/locales';

defineProps<{
  inputId: string;
  name: string;
  inputValue: T;
  label?: TranslationKey;
  disabled?: boolean;
}>();

const model = defineModel<T | null>({ required: true });
</script>

<style scoped lang="scss">
.RadioInput {
  @include hidden;
}

.RadioLabel {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  background-color: black(12);
  color: black(87);
  border-radius: 4px;
  cursor: pointer;

  @include setTransition(background-color, color);

  &-checked {
    cursor: default;
    background-color: primary(700);
    color: white();
  }

  &-disabled {
    cursor: default;
  }
}
</style>

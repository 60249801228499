/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubjectSearchConditionV0
 */
export interface SubjectSearchConditionV0 {
    /**
     * 
     * @type {string}
     * @memberof SubjectSearchConditionV0
     */
    field: SubjectSearchConditionV0FieldEnum;
    /**
     * 
     * @type {string}
     * @memberof SubjectSearchConditionV0
     */
    type: SubjectSearchConditionV0TypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubjectSearchConditionV0
     */
    values: Array<string>;
}


/**
 * @export
 */
export const SubjectSearchConditionV0FieldEnum = {
    Subject: 'subject'
} as const;
export type SubjectSearchConditionV0FieldEnum = typeof SubjectSearchConditionV0FieldEnum[keyof typeof SubjectSearchConditionV0FieldEnum];

/**
 * @export
 */
export const SubjectSearchConditionV0TypeEnum = {
    Contain: 'contain',
    NotContain: 'not_contain'
} as const;
export type SubjectSearchConditionV0TypeEnum = typeof SubjectSearchConditionV0TypeEnum[keyof typeof SubjectSearchConditionV0TypeEnum];


/**
 * Check if a given object implements the SubjectSearchConditionV0 interface.
 */
export function instanceOfSubjectSearchConditionV0(value: object): value is SubjectSearchConditionV0 {
    if (!('field' in value) || value['field'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function SubjectSearchConditionV0FromJSON(json: any): SubjectSearchConditionV0 {
    return SubjectSearchConditionV0FromJSONTyped(json, false);
}

export function SubjectSearchConditionV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): SubjectSearchConditionV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'field': json['field'],
        'type': json['type'],
        'values': json['values'],
    };
}

export function SubjectSearchConditionV0ToJSON(json: any): SubjectSearchConditionV0 {
    return SubjectSearchConditionV0ToJSONTyped(json, false);
}

export function SubjectSearchConditionV0ToJSONTyped(value?: SubjectSearchConditionV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'field': value['field'],
        'type': value['type'],
        'values': value['values'],
    };
}


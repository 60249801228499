import type { TranslationKey } from '@/locales';

import { event } from 'vue-gtag';

export function useAnalyticsHelper() {
  const { searchSettings, search } = useStores('searchSettings', 'search');

  const emailSearchEvent = (): void => {
    event('search_action', {
      search_query: searchSettings.sanitizedQueryString,
    });
  };

  const emailSearchPaginationEvent = (page: number): void => {
    event('email_search_pagination_action', {
      page: page,
    });
  };

  const emailSearchResultsPerPageEvent = (resultsPerPage: number): void => {
    event('email_search_results_action', {
      resultsPerPage: resultsPerPage,
    });
  };

  const emailSearchDateRangeEvent = (context: 'toolbar' | 'settings'): void => {
    event('archive_search_date_range_action', {
      rangeIndex: search.rangeIndex,
      dates: `${search.dates.start.toString()} - ${search.dates.end.toString()}`,
      context: context,
    });
  };

  const openEmailDrawerEvent = (uidl: string): void => {
    event('email_drawer_action', {
      uidl: uidl,
    });
  };

  const emailDrawerNewTabEvent = (uidl: string): void => {
    event('email_drawer_new_tab_action', {
      uidl: uidl,
    });
  };

  const emailDrawerDefrostEvent = (uidl: string): void => {
    event('email_drawer_defrost_action', {
      uidl: uidl,
    });
  };

  const emailDrawerDownloadEvent = (uidl: string): void => {
    event('email_drawer_download_action', {
      uidl: uidl,
    });
  };

  const emailDrawerBodyTabsEvent = (uidl: string, tab: TranslationKey): void => {
    if (tab === EMAIL_BODY_TAB_LABEL.PLAIN) {
      event('email_drawer_plain_text_action', {
        uidl: uidl,
      });
    } else if (tab === EMAIL_BODY_TAB_LABEL.HTML) {
      event('email_drawer_text_html_action', {
        uidl: uidl,
      });
    } else if (tab === EMAIL_BODY_TAB_LABEL.ORIGINAL) {
      event('email_drawer_source_action', {
        uidl: uidl,
      });
    }
  };

  const emailDrawerPaginationEvent = (uidl: string, page: number): void => {
    event('email_drawer_pagination_action', {
      uidl: uidl,
      page: page,
    });
  };

  const openInfoEvent = async (
    target: HELP_PANEL_TAGS,
    context?: HELP_PANEL_CONTEXT,
  ): Promise<void> => {
    event(context ? `${HelpPanelEvents[target]}_${context}` : HelpPanelEvents[target]);
  };

  const loginDomainInputAction = (isDomainUrl: boolean): void => {
    event(isDomainUrl ? 'login_domain_input_url_action' : 'login_domain_input_field_action');
  };

  return {
    emailSearchEvent,
    emailSearchPaginationEvent,
    emailSearchResultsPerPageEvent,
    emailSearchDateRangeEvent,
    openEmailDrawerEvent,
    emailDrawerNewTabEvent,
    emailDrawerDefrostEvent,
    emailDrawerDownloadEvent,
    emailDrawerBodyTabsEvent,
    emailDrawerPaginationEvent,
    openInfoEvent,
    loginDomainInputAction,
  };
}

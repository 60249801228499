<template>
  <div class="Wrapper">
    <DomainTemplateHeader />
    <div class="MainWrapper">
      <main class="DefaultTemplate">
        <slot />
      </main>
    </div>
  </div>

  <BaseLoader v-if="loader.isLoading" fullscreen />
  <SnackbarsContainer />
</template>

<script setup lang="ts">
const { loader } = useStores('loader');

defineSlots<{
  default(): void;
}>();
</script>

<style scoped lang="scss">
.Wrapper {
  position: relative;
  max-width: $fixed-width-content;
  margin: 0 auto;
}

.MainWrapper {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  height: calc(100vh - #{$domain-header-height * 2});
  padding: $domain-header-height 0;
}

.DefaultTemplate {
  flex: 1 1 0px;
  width: auto;
}
</style>

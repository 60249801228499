<template>
  <div class="SkeletonIcon" :class="`SkeletonIcon-${size}`"></div>
</template>

<script setup lang="ts">
export type Props = {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

withDefaults(defineProps<Props>(), { size: 'sm' });
</script>

<style scoped lang="scss">
.SkeletonIcon {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 20px;

  @include skeletonGradient;

  // size
  &-xs {
    width: 16px;
    height: 16px;
  }
  &-sm {
    width: 20px;
    height: 20px;
  }
  &-md {
    width: 24px;
    height: 24px;
  }
  &-lg {
    width: 32px;
    height: 32px;
  }
  &-xl {
    width: 40px;
    height: 40px;
  }
}
</style>

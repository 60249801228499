import { z } from "zod";

const AnySearchConditionQueryParameter = z
  .object({
    field: z.literal("any"),
    type: z.literal("contain"),
    values: z.array(z.string().max(1000)).max(1),
  })
  .strict()
  .passthrough();
const FromSearchConditionQueryParameter = z
  .object({
    field: z.literal("from"),
    type: z.enum(["contain", "match", "not_contain", "not_match"]),
    values: z.array(z.string().max(1000)).max(10),
  })
  .strict()
  .passthrough();
const ToCcBccSearchConditionQueryParameter = z
  .object({
    field: z.literal("to_cc_bcc"),
    type: z.enum([
      "contain",
      "contain_not_only",
      "match",
      "not_contain",
      "not_match",
    ]),
    values: z.array(z.string().max(1000)).max(10),
  })
  .strict()
  .passthrough();
const ToSearchConditionQueryParameter = z
  .object({
    field: z.literal("to"),
    type: z.enum([
      "contain",
      "contain_not_only",
      "match",
      "not_contain",
      "not_match",
    ]),
    values: z.array(z.string().max(1000)).max(10),
  })
  .strict()
  .passthrough();
const CcSearchConditionQueryParameter = z
  .object({
    field: z.literal("cc"),
    type: z.enum([
      "contain",
      "contain_not_only",
      "match",
      "not_contain",
      "not_match",
    ]),
    values: z.array(z.string().max(1000)).max(10),
  })
  .strict()
  .passthrough();
const EnvelopeFromSearchConditionQueryParameter = z
  .object({
    field: z.literal("envelope_from"),
    type: z.enum(["contain", "match", "not_contain", "not_match"]),
    values: z.array(z.string().max(1000)).max(10),
  })
  .strict()
  .passthrough();
const EnvelopeToSearchConditionQueryParameter = z
  .object({
    field: z.literal("envelope_to"),
    type: z.enum([
      "contain",
      "contain_not_only",
      "match",
      "not_contain",
      "not_match",
    ]),
    values: z.array(z.string().max(1000)).max(10),
  })
  .strict()
  .passthrough();
const EnvelopeFromEnvelopeToSearchConditionQueryParameter = z
  .object({
    field: z.literal("envelope_from_envelope_to"),
    type: z.enum(["contain", "match", "not_contain", "not_match"]),
    values: z.array(z.string().max(1000)).max(10),
  })
  .strict()
  .passthrough();
const SubjectSearchConditionQueryParameter = z
  .object({
    field: z.literal("subject"),
    type: z.enum(["contain", "not_contain"]),
    values: z.array(z.string().max(1000)).max(10),
  })
  .strict()
  .passthrough();
const BodySearchConditionQueryParameter = z
  .object({
    field: z.literal("body"),
    type: z.enum(["contain", "not_contain"]),
    values: z.array(z.string().max(1000)).max(10),
  })
  .strict()
  .passthrough();
const SubjectBodySearchConditionQueryParameter = z
  .object({
    field: z.literal("subject_body"),
    type: z.enum(["contain", "not_contain"]),
    values: z.array(z.string().max(1000)).max(10),
  })
  .strict()
  .passthrough();
const AttachmentSearchConditionQueryParameter = z
  .object({
    field: z.literal("has_attachment"),
    type: z.enum(["exist", "not_exist"]),
  })
  .strict()
  .passthrough();
const ArchiveStatusV0 = z.enum([
  "available",
  "defrosted",
  "defrosting",
  "frozen",
]);
const StorageStatusSearchConditionQueryParameter = z
  .object({
    field: z.literal("storage_status"),
    values: z.array(ArchiveStatusV0).min(1).max(4),
  })
  .strict()
  .passthrough();
const ArchiveSearchQueryParameters = z
  .object({
    conditions: z
      .array(
        z.discriminatedUnion("field", [
          AnySearchConditionQueryParameter,
          FromSearchConditionQueryParameter,
          ToCcBccSearchConditionQueryParameter,
          ToSearchConditionQueryParameter,
          CcSearchConditionQueryParameter,
          EnvelopeFromSearchConditionQueryParameter,
          EnvelopeToSearchConditionQueryParameter,
          EnvelopeFromEnvelopeToSearchConditionQueryParameter,
          SubjectSearchConditionQueryParameter,
          BodySearchConditionQueryParameter,
          SubjectBodySearchConditionQueryParameter,
          AttachmentSearchConditionQueryParameter,
          StorageStatusSearchConditionQueryParameter,
        ])
      )
      .max(10)
      .optional()
      .default([]),
  })
  .strict()
  .passthrough();
const OperationTypeQueryParameter = z.enum([
  "createSearchPolicy",
  "createUser",
  "defrostMail",
  "deleteSearchPolicy",
  "deleteUser",
  "downloadMail",
  "downloadMailAttachment",
  "downloadOperationLogs",
  "login",
  "getMailDetails",
  "getOperationLogs",
  "logout",
  "previewMailAttachment",
  "searchArchive",
  "updateDomain",
  "updateSearchPolicy",
  "updateUser",
]);
const OperationLogSearchQueryParameters = z
  .object({
    operation_types: z.array(OperationTypeQueryParameter).min(1).max(17),
    user_emails: z.array(z.string().max(256).email()).max(10).nullish(),
  })
  .strict()
  .passthrough();

export const schemas = {
  AnySearchConditionQueryParameter,
  FromSearchConditionQueryParameter,
  ToCcBccSearchConditionQueryParameter,
  ToSearchConditionQueryParameter,
  CcSearchConditionQueryParameter,
  EnvelopeFromSearchConditionQueryParameter,
  EnvelopeToSearchConditionQueryParameter,
  EnvelopeFromEnvelopeToSearchConditionQueryParameter,
  SubjectSearchConditionQueryParameter,
  BodySearchConditionQueryParameter,
  SubjectBodySearchConditionQueryParameter,
  AttachmentSearchConditionQueryParameter,
  ArchiveStatusV0,
  StorageStatusSearchConditionQueryParameter,
  ArchiveSearchQueryParameters,
  OperationTypeQueryParameter,
  OperationLogSearchQueryParameters,
};

export interface ArchiveSettings {
  isArchiving: boolean;
  frozenPeriod: 'lt_10years' | 'gte_10years';
  frozenPeriodMonths: number;
  realtimePeriodMonths: number;
}

export interface ArchiveSettingsState {
  settings_loaded: boolean;
  settings: ArchiveSettings;
}

export const useArchiveSettingsStore = defineStore('archiveSettings', {
  state: (): ArchiveSettingsState => ({
    settings_loaded: false,
    settings: {
      isArchiving: false,
      frozenPeriod: 'lt_10years',
      frozenPeriodMonths: NaN,
      realtimePeriodMonths: NaN,
    },
  }),

  getters: {
    isArchiving(): boolean {
      return this.settings.isArchiving;
    },
    frozenPeriodMonths(): number {
      return this.settings.frozenPeriodMonths;
    },
    realtimePeriodMonths(): number {
      return this.settings.realtimePeriodMonths;
    },
  },

  actions: {
    async initArchiveSettings(): Promise<void> {
      const { getArchiveSettings } = useArchiveHelper();

      this.settings_loaded = false;

      // TODO: feature for backup period >= 10 years to patch this up so it is
      // working, I will simply set the value to 10 years now. we can refactor when
      // we do the user story.
      const apiSettings = await getArchiveSettings();
      this.settings = {
        isArchiving: apiSettings.isArchiving,
        realtimePeriodMonths: apiSettings.preservationPeriod.realtimePeriodMonths,
        frozenPeriod: apiSettings.preservationPeriod.frozenPeriod,
        frozenPeriodMonths:
          apiSettings.preservationPeriod.frozenPeriod === 'gte_10years'
            ? 120
            : (apiSettings.preservationPeriod.frozenPeriodMonths ?? 120),
      };
      this.settings_loaded = true;
    },
  },
});

export default useArchiveSettingsStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useArchiveSettingsStore, import.meta.hot));
}

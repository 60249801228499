/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttachmentPreviewResponseV0
 */
export interface AttachmentPreviewResponseV0 {
    /**
     * 
     * @type {string}
     * @memberof AttachmentPreviewResponseV0
     */
    mediaType: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentPreviewResponseV0
     */
    url: string;
}

/**
 * Check if a given object implements the AttachmentPreviewResponseV0 interface.
 */
export function instanceOfAttachmentPreviewResponseV0(value: object): value is AttachmentPreviewResponseV0 {
    if (!('mediaType' in value) || value['mediaType'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    return true;
}

export function AttachmentPreviewResponseV0FromJSON(json: any): AttachmentPreviewResponseV0 {
    return AttachmentPreviewResponseV0FromJSONTyped(json, false);
}

export function AttachmentPreviewResponseV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentPreviewResponseV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'mediaType': json['media_type'],
        'url': json['url'],
    };
}

export function AttachmentPreviewResponseV0ToJSON(json: any): AttachmentPreviewResponseV0 {
    return AttachmentPreviewResponseV0ToJSONTyped(json, false);
}

export function AttachmentPreviewResponseV0ToJSONTyped(value?: AttachmentPreviewResponseV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'media_type': value['mediaType'],
        'url': value['url'],
    };
}


import { z } from 'zod';

export const RowLimitOptions = [10, 25, 50, 100, 200] as const;

export type RowLimitOption = (typeof RowLimitOptions)[number];

export const ZRowLimitOptions = z.union([
  z.literal(10),
  z.literal(25),
  z.literal(50),
  z.literal(100),
  z.literal(200),
]);

export const DEFAULT_LIMIT_VALUE = 50 satisfies RowLimitOption;

import type { DateRange } from './DateRange';
import type { TranslationKey } from '@/locales';

import dayjs from 'dayjs';

export type SearchRange = {
  label: TranslationKey;
  getDateRange: (timezone: string) => DateRange;
};

const getEndOfToday = (timezone: string) => dayjs().tz(timezone).endOf('day').toDate();

export const SearchRanges = {
  custom: {
    label: 'dateRanges.custom',
    getDateRange: (timezone: string) => ({
      start: dayjs().tz(timezone).subtract(7, 'days').startOf('day').toDate(),
      end: dayjs().tz(timezone).endOf('day').toDate(),
    }),
  },
  today: {
    label: 'dateRanges.today',
    getDateRange: (timezone: string) => ({
      start: dayjs().tz(timezone).startOf('day').toDate(),
      end: getEndOfToday(timezone),
    }),
  },
  yesterday: {
    label: 'dateRanges.yesterday',
    getDateRange: (timezone: string) => ({
      start: dayjs().tz(timezone).startOf('day').subtract(1, 'minute').startOf('day').toDate(),
      end: dayjs().tz(timezone).startOf('day').subtract(1, 'minute').endOf('day').toDate(),
    }),
  },
  '3days': {
    label: 'dateRanges.3days',
    getDateRange: (timezone: string) => ({
      start: dayjs().tz(timezone).subtract(3, 'days').startOf('day').toDate(),
      end: getEndOfToday(timezone),
    }),
  },
  '7days': {
    label: 'dateRanges.7days',
    getDateRange: (timezone: string) => ({
      start: dayjs().tz(timezone).subtract(7, 'days').startOf('day').toDate(),
      end: getEndOfToday(timezone),
    }),
  },
  '30days': {
    label: 'dateRanges.30days',
    getDateRange: (timezone: string) => ({
      start: dayjs().tz(timezone).subtract(30, 'days').startOf('day').toDate(),
      end: getEndOfToday(timezone),
    }),
  },
  '1year': {
    label: 'dateRanges.1year',
    getDateRange: (timezone: string) => ({
      start: dayjs().tz(timezone).subtract(1, 'year').add(1, 'day').startOf('day').toDate(),
      end: getEndOfToday(timezone),
    }),
  },
} as const satisfies Record<string, SearchRange>;

export type SearchRangeOption = keyof typeof SearchRanges;

export const SearchRangeOptions = [
  'custom',
  'today',
  'yesterday',
  '3days',
  '7days',
  '30days',
  '1year',
] as const satisfies SearchRangeOption[];

export const DEFAULT_SEARCH_RANGE_OPTION = 'today' satisfies SearchRangeOption;
export const DEFAULT_OPERATION_LOG_DOWNLOAD_RANGE_OPTION = '30days' satisfies SearchRangeOption;

<template>
  <li
    role="menuitem"
    class="Item"
    :class="[
      {
        'Item-submenu': isSubmenu,
        'Item-isExternalLink': !!externalLink,
        'Item-disabled': disabled,
      },
      `Item-${size}`,
    ]"
  >
    <a
      v-if="externalLink"
      class="ExternalLink"
      :class="[`ExternalLink-${size}`]"
      :href="externalLink"
      target="_blank"
      rel="noopener noreferrer"
    >
      <slot />
      <BaseIcon class="ExternalIcon" size="xs">
        <NobitaIconNewWindow />
      </BaseIcon>
    </a>
    <template v-else>
      <slot />
      <BaseIcon v-if="isSubmenu" class="Arrow">
        <NobitaIconChevronRight />
      </BaseIcon>
    </template>
  </li>
</template>

<script setup lang="ts">
export type Props = {
  externalLink?: string;
  isSubmenu?: boolean;
  disabled?: boolean;
  size?: 'sm' | 'md';
};

withDefaults(defineProps<Props>(), {
  externalLink: undefined,
  size: 'md',
});

defineSlots<{
  default(): void;
}>();
</script>

<style scoped lang="scss">
.Item {
  position: relative;
  color: black(87);
  cursor: pointer;
  line-height: 1;
  background-color: white();
  text-align: left;

  @include setTransition(background-color);

  &:not(:first-child) {
    border-top: 1px solid black(12);
  }

  &:hover {
    background-color: black(5);
  }

  // size
  &-sm {
    font-size: 13px;
    padding: 10px 15px;
  }
  &-md {
    font-size: 14px;
    padding: 14.5px 16px;
  }
  &-submenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px 10px 16px;
    cursor: pointer;
  }
  &-isExternalLink {
    padding: 0;
  }
  &-disabled {
    cursor: default;
    color: black(38);
  }
}

.ExternalLink {
  color: black(87);
  text-decoration: none;
  display: flex;

  // size
  &-sm {
    font-size: 13px;
    padding: 10px 15px;
  }
  &-md {
    font-size: 14px;
    padding: 14.5px 16px;
  }
}

.ExternalIcon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  fill: neutral(600);
}
</style>

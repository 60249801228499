/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessPolicySelfEditOptionV0
 */
export interface AccessPolicySelfEditOptionV0 {
    /**
     * 
     * @type {string}
     * @memberof AccessPolicySelfEditOptionV0
     */
    id: AccessPolicySelfEditOptionV0IdEnum;
    /**
     * 
     * @type {string}
     * @memberof AccessPolicySelfEditOptionV0
     */
    value: AccessPolicySelfEditOptionV0ValueEnum;
}


/**
 * @export
 */
export const AccessPolicySelfEditOptionV0IdEnum = {
    SelfEdit: 'self_edit'
} as const;
export type AccessPolicySelfEditOptionV0IdEnum = typeof AccessPolicySelfEditOptionV0IdEnum[keyof typeof AccessPolicySelfEditOptionV0IdEnum];

/**
 * @export
 */
export const AccessPolicySelfEditOptionV0ValueEnum = {
    Enabled: 'enabled'
} as const;
export type AccessPolicySelfEditOptionV0ValueEnum = typeof AccessPolicySelfEditOptionV0ValueEnum[keyof typeof AccessPolicySelfEditOptionV0ValueEnum];


/**
 * Check if a given object implements the AccessPolicySelfEditOptionV0 interface.
 */
export function instanceOfAccessPolicySelfEditOptionV0(value: object): value is AccessPolicySelfEditOptionV0 {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function AccessPolicySelfEditOptionV0FromJSON(json: any): AccessPolicySelfEditOptionV0 {
    return AccessPolicySelfEditOptionV0FromJSONTyped(json, false);
}

export function AccessPolicySelfEditOptionV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessPolicySelfEditOptionV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'value': json['value'],
    };
}

export function AccessPolicySelfEditOptionV0ToJSON(json: any): AccessPolicySelfEditOptionV0 {
    return AccessPolicySelfEditOptionV0ToJSONTyped(json, false);
}

export function AccessPolicySelfEditOptionV0ToJSONTyped(value?: AccessPolicySelfEditOptionV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'value': value['value'],
    };
}


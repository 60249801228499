<template>
  <template v-for="position in positions" :key="`position-container-${position}`">
    <ul
      v-if="snackbars[position].length > 0"
      class="SnackbarsContainer"
      :class="[`SnackbarsContainer-${position}`]"
    >
      <BaseSnackbar
        v-for="(item, index) in snackbars[position]"
        :key="`snackbars-item-${item.id}`"
        :index
        :position
      />
    </ul>
  </template>
</template>

<script setup lang="ts">
const { snackbars } = useStores('snackbars');

const positions = ref<SNACKBAR_POSITION[]>([
  SNACKBAR_POSITION.topCenter,
  SNACKBAR_POSITION.topRight,
  SNACKBAR_POSITION.bottomRight,
]);
</script>

<style scoped lang="scss">
$max_width: 720px;

.SnackbarsContainer {
  position: fixed;
  z-index: 900;
  display: flex;
  flex-flow: column;
  width: calc(100% - 64px);
  margin: 0;
  padding: 0;
  overflow: visible;
  list-style: none;

  // positions
  &-topCenter,
  &-topRight {
    top: 96px;
    left: 50%;
    transform: translateX(-50%);
    justify-content: flex-start;
    align-items: center;
  }

  &-bottomRight {
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    justify-content: flex-end;
    align-items: center;
  }

  @include sm-and-up {
    display: inline-flex;
    width: auto;
    max-width: $max_width;

    // positions
    &-topCenter {
      top: 96px;
      left: 50%;
      transform: translateX(-50%);
      justify-content: flex-start;
      align-items: center;
    }

    &-topRight {
      top: 96px;
      right: 32px;
      transform: none;
      justify-content: flex-start;
      align-items: flex-end;
    }

    &-bottomRight {
      bottom: 16px;
      right: 32px;
      left: initial;
      transform: none;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
}
</style>

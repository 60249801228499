/**
 * Archive helper
 *
 * @desc helps do CRUD in components for Archive Search
 */

import type {
  ArchiveHeadersV0,
  ArchiveSettingsV0,
  ArchiveStatusDetailV0,
  AttachmentPreviewResponseV0,
  DefrostMailRequest,
  DownloadMailAttachmentRequest,
  DownloadMailRequest,
  ExportHeadersRequest,
  GetMailHeadersRequest,
  PreviewMailAttachmentRequest,
  SearchHistoryV0,
  URLResponseV0,
} from '@/api';

import { OVERLAY_LOCK } from '@/store/overlay';

import { NobitaError } from '@/errors';

export type GetMailHeadersResponse = ArchiveHeadersV0 | NobitaError | false | undefined;
export type DefrostEmailResponse =
  | ArchiveStatusDetailV0
  | NobitaError
  | false
  | GetMailHeadersResponse;

export function useArchiveHelper() {
  const { api, apiEndpoints, archiveSearch, searchSettings, snackbars, loader, drawer } = useStores(
    'api',
    'apiEndpoints',
    'archiveSearch',
    'searchSettings',
    'snackbars',
    'loader',
    'drawer',
  );

  const { emailDrawerDefrostEvent } = useAnalyticsHelper();

  const archiveApi = computed(() => api.archiveApi);

  const getMailHeaders = async (uidl: string): Promise<GetMailHeadersResponse> => {
    apiEndpoints.getMailHeaders.reset();

    logger.info('Get Email Headers:', uidl);

    const requestParameters: GetMailHeadersRequest = {
      uidl,
    };
    const response = await apiEndpoints.getMailHeaders.invoke(requestParameters);

    if (response.success) {
      archiveSearch.setEmailHeaders(response.result);
      return response.result;
    } else {
      const err = response.error;

      if (err instanceof NobitaError) {
        return err;
      }

      snackbars.addWarningSnackbar();
      logger.error('Failed to get email headers for an unknown reason', err);
      return false;
    }
  };

  const downloadEmailHeaders = async (): Promise<void> => {
    const requestParameters: ExportHeadersRequest = {
      headerExportQueryV0: {
        conditions: searchSettings.conditions,
        start: searchSettings.dates.start,
        end: searchSettings.dates.end,
      },
    };

    logger.info('Download Email Headers');

    const res: URLResponseV0 = await archiveApi.value.exportHeaders(requestParameters);
    window.location.replace(res.url);
  };

  /**
   * Download a single email immediately as an .eml file.
   * Upon success, this will invoke the download in the browser immediately, and returns nothing.
   *
   * @param uidl ID of the email to download as an .eml file
   */
  const downloadMail = async (uidl: string): Promise<void> => {
    const downloadMailRequest: DownloadMailRequest = { uidl, format: 'raw' };

    logger.info(`Fetching .eml file for UIDL ${uidl}.`);

    const res = await archiveApi.value.downloadMail(downloadMailRequest);

    window.location.replace(res.url); // This will download the .eml file in the browser
  };

  const loadReadableOriginal = async (uidl: string): Promise<string> => {
    const downloadMailRequest: DownloadMailRequest = { uidl, format: 'readable' };

    logger.info(`Fetching original URL for UIDL ${uidl}.`);
    const meta = await archiveApi.value.downloadMail(downloadMailRequest);

    logger.info(`Loading original from URL ${meta.url}`);
    const original = await fetch(meta.url);
    return await original.text();
  };

  const defrostEmail = async (uidl: string): Promise<DefrostEmailResponse> => {
    loader.show();
    drawer.lock(OVERLAY_LOCK.LOADING);

    emailDrawerDefrostEvent(uidl);

    apiEndpoints.defrostMail.reset();

    logger.info('Defrost Email:', uidl);

    const requestParameters: DefrostMailRequest = {
      uidl,
    };
    const response = await apiEndpoints.defrostMail.invoke(requestParameters);

    loader.hide();
    drawer.unlock(OVERLAY_LOCK.LOADING);
    if (response.success) {
      archiveSearch.afterDefrostEmail(uidl, response.result);
      drawer.attemptClose();
      return response.result;
    } else {
      const err = response.error;

      if (err instanceof NobitaError) {
        if (
          err.nobitaErrorCode === NobitaErrorCode.DEFROST_INVALID_STATE &&
          err.httpErrorCode === 422
        ) {
          return await getMailHeaders(uidl);
        }
        return err;
      }
      snackbars.addWarningSnackbar();
      logger.error('Failed to defrost email for an unknown reason', err);
      return false;
    }
  };

  const downloadArchive = async (ids: Array<string>, format: string): Promise<void> => {
    logger.info(`Download Archive placeholder, ids=${ids}, format=${format}`);
  };

  const downloadAttachment = async (uidl: string, attachmentId: string): Promise<void> => {
    const requestParameters: DownloadMailAttachmentRequest = {
      uidl,
      attachmentId,
    };

    logger.info('Download Attachment');

    const res: URLResponseV0 = await archiveApi.value.downloadMailAttachment(requestParameters);
    window.location.replace(res.url);
  };

  const fetchAttachmentPreviewUrl = async (
    uidl: string,
    attachmentId: string,
  ): Promise<AttachmentPreviewResponseV0 | NobitaError | false> => {
    apiEndpoints.previewMailAttachment.reset();

    const requestParameters: PreviewMailAttachmentRequest = {
      uidl,
      attachmentId,
    };

    logger.info('Preview Attachment');

    const response = await apiEndpoints.previewMailAttachment.invoke(requestParameters);

    if (response.success) {
      return response.result;
    } else {
      const err = response.error;

      if (err instanceof NobitaError) {
        return err;
      }

      snackbars.addWarningSnackbar();
      logger.error('Failed to fetch preview URL for an unknown reason', err);
      return false;
    }
  };

  const loadAttachmentPreview = async (url: string): Promise<Uint8Array | false> => {
    try {
      const response = await fetch(url);

      const arrayBuffer = await response.arrayBuffer();

      return new Uint8Array(arrayBuffer);
    } catch (error) {
      snackbars.addWarningSnackbar();
      logger.error('Failed to load attachment preview from url:', error);
      return false;
    }
  };

  /**
   * Fetch the archive settings
   */
  const getArchiveSettings = async (): Promise<ArchiveSettingsV0> => {
    logger.info('Fetching archive settings');

    return archiveApi.value.getArchiveSettings();
  };

  const getSearchHistory = async (): Promise<SearchHistoryV0 | NobitaError | false> => {
    apiEndpoints.getSearchHistory.reset();

    logger.info('Fetching Search History for user');

    const response = await apiEndpoints.getSearchHistory.invoke();

    if (response.success) {
      return response.result;
    } else {
      const err = response.error;

      if (err instanceof NobitaError) {
        return err;
      }

      snackbars.addWarningSnackbar();
      logger.error('Failed to get Search History for an unknown reason', err);
      return false;
    }
  };

  return {
    getMailHeaders,
    downloadEmailHeaders,
    defrostEmail,
    downloadArchive,
    downloadAttachment,
    fetchAttachmentPreviewUrl,
    loadAttachmentPreview,
    downloadMail,
    loadReadableOriginal,
    getArchiveSettings,
    getSearchHistory,
  };
}

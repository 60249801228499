<template>
  <div class="SkeletonNavItem" :class="{ 'SkeletonNavItem-header': header }"></div>
</template>

<script setup lang="ts">
defineProps<{
  header?: boolean;
}>();
</script>

<style scoped lang="scss">
.SkeletonNavItem {
  position: relative;
  height: 16px;
  border-radius: 16px;
  width: 10rem;
  margin: 8px 0;

  @include skeletonGradient;

  &-header {
    height: 20px;
    width: 8rem;
    margin-bottom: 6px;
  }
}
</style>

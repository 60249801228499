import type { TranslationKey } from '@/locales';

import dayjs from 'dayjs';
import { z } from 'zod';

export function useValidator() {
  function validateDomain(domain: string) {
    if (
      domain.startsWith('.') ||
      domain.startsWith('@') ||
      domain.endsWith('.') ||
      domain.endsWith('-') ||
      // Must have at least one period
      domain.indexOf('.') === -1 ||
      // Disallow `..`, `-.`, `-.` in domain
      /(\.\.|-\.|\.-)/.test(domain) ||
      // No invalid characters
      /[^a-zA-Z0-9.-]/.test(domain)
    ) {
      return false;
    }

    const domainsParts = domain.split('.');
    const DOMAIN_PART_MAX_LENGTH = 63;

    for (const part of domainsParts) {
      if (part.length > DOMAIN_PART_MAX_LENGTH) {
        return false;
      }
    }

    return true;
  }

  function validateEmail(email: string): boolean {
    const EMAIL_MAX_LENGTH = 254;
    const LOCAL_PART_MAX_LENGTH = 64;

    const _email = String(email);

    const rule1 =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const rule2 = /^[^@]+@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/; // Modified rule to check for at least one character before @
    const rule3 = _email.startsWith('-');
    const rule4 = _email.substring(0, _email.indexOf('@')).length > LOCAL_PART_MAX_LENGTH;
    const rule5 = _email.length > EMAIL_MAX_LENGTH;
    const rule6 = validateDomain(_email.substring(email.indexOf('@') + 1));

    return (
      (rule1.test(_email.toLowerCase()) || rule2.test(_email.toLowerCase())) &&
      !rule3 &&
      !rule4 &&
      !rule5 &&
      rule6
    );
  }

  function validateIp(ip: string): boolean {
    const test = z.string().ip();
    return test.safeParse(ip).success;
  }

  function validateDateRange(start: Date, end: Date): TranslationKey | '' {
    if (dayjs(start).isAfter(end)) {
      return 'validation.dateRangeStartAfterEnd';
    } else {
      return '';
    }
  }

  return {
    validateDomain,
    validateEmail,
    validateIp,
    validateDateRange,
  };
}

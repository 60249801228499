/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MailAttachmentV0
 */
export interface MailAttachmentV0 {
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentV0
     */
    filename: string;
    /**
     * 
     * @type {number}
     * @memberof MailAttachmentV0
     */
    filesize: number;
    /**
     * 
     * @type {string}
     * @memberof MailAttachmentV0
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof MailAttachmentV0
     */
    previewSupported: boolean;
}

/**
 * Check if a given object implements the MailAttachmentV0 interface.
 */
export function instanceOfMailAttachmentV0(value: object): value is MailAttachmentV0 {
    if (!('filename' in value) || value['filename'] === undefined) return false;
    if (!('filesize' in value) || value['filesize'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('previewSupported' in value) || value['previewSupported'] === undefined) return false;
    return true;
}

export function MailAttachmentV0FromJSON(json: any): MailAttachmentV0 {
    return MailAttachmentV0FromJSONTyped(json, false);
}

export function MailAttachmentV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): MailAttachmentV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'filename': json['filename'],
        'filesize': json['filesize'],
        'id': json['id'],
        'previewSupported': json['preview_supported'],
    };
}

export function MailAttachmentV0ToJSON(json: any): MailAttachmentV0 {
    return MailAttachmentV0ToJSONTyped(json, false);
}

export function MailAttachmentV0ToJSONTyped(value?: MailAttachmentV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'filename': value['filename'],
        'filesize': value['filesize'],
        'id': value['id'],
        'preview_supported': value['previewSupported'],
    };
}


import type {
  ArchiveHeadersV0,
  ArchiveStatusDetailV0,
  ArchiveStatusV0,
  ExtraMailDetailsV0,
} from '@/api';
import type { EmailReadableOriginal } from '@/types/Email';
import type { RowLimitOption } from '@/types/Pagination';

import { cloneDeep } from 'lodash-es';

export interface ArchiveSearchSingleEmailState {
  // single view
  emailViewHeader: ArchiveHeadersV0;
  emailViewDetails: ExtraMailDetailsV0;
  emailReadableOriginal: EmailReadableOriginal;
  emailView_index: number;
}

export interface ArchiveSearchState extends ArchiveSearchSingleEmailState {
  // list view
  emails: ArchiveHeadersV0[];
  selectedEmails: string[];
  isFirstSearch: boolean;
  limit: RowLimitOption;
  total: number;
  skiptoken: string | null;
  backtoken: string | null;
}

const getInitialSingleEmailState = (): ArchiveSearchSingleEmailState => ({
  // single view
  emailViewHeader: {
    uidl: '',
    date: '',
    envelopeFrom: '',
    envelopeTo: [],
    to: [],
    cc: [],
    from: [],
    subject: '',
    hasAttachment: false,
    dataSize: 0,
    status: 'available',
    archivedDate: new Date(0),
    defrostedUntil: new Date(0),
    defrostedFrom: new Date(0),
    defrostDurationMs: 0,
  },
  emailViewDetails: {
    textPart: '',
    simplifiedHtmlPart: '',
    attachments: [],
  },
  emailReadableOriginal: {
    isLoading: false,
    isLoaded: false,
    content: null,
  },
  emailView_index: 1,
});

export const useArchiveSearchStore = defineStore('archiveSearch', () => {
  const { getMailDetails, getMailHeaders } = useApiEndpointsStore();

  const state = reactive<ArchiveSearchState>({
    // list view
    emails: [],
    selectedEmails: [],
    isFirstSearch: true,
    limit: DEFAULT_LIMIT_VALUE,
    total: 0,
    skiptoken: null,
    backtoken: null,

    // single view
    ...getInitialSingleEmailState(),
  });

  return {
    ...toRefs(state),

    isEmpty: computed<boolean>(() => state.emails.length === 0),
    isSelectable: computed<boolean>(() => state.selectedEmails.length < MAX_EMAIL_SELECTION),

    // actions
    resetSearch(): void {
      state.isFirstSearch = true;
      state.selectedEmails = [];
      state.emails = [];
      state.limit = DEFAULT_LIMIT_VALUE;
      state.total = 0;
    },

    setFirstSearch(params: boolean): void {
      state.isFirstSearch = params;
    },

    // Email search
    setEmails(param: ArchiveHeadersV0[]): void {
      state.emails = cloneDeep(param);
    },

    // Emails selection
    selectEmails(emails: string[]): void {
      if (state.selectedEmails.length + emails.length <= MAX_EMAIL_SELECTION) {
        state.selectedEmails = [...state.selectedEmails, ...emails];
      } else {
        const space = MAX_EMAIL_SELECTION - state.selectedEmails.length;
        state.selectedEmails = [...state.selectedEmails, ...emails.slice(0, space)];
      }
    },

    deselectEmails(emails: string[]): void {
      state.selectedEmails = state.selectedEmails.filter((e) => !emails.includes(e));
    },

    clearAllSelectedEmails(): void {
      state.selectedEmails = [];
    },

    toggleEmail(id: string): void {
      const index: number = state.selectedEmails.indexOf(id);
      if (index !== -1) {
        state.selectedEmails.splice(index, 1);
      } else if (this.isSelectable.value) {
        state.selectedEmails.push(id);
      }
    },

    // Single view

    resetEmailView() {
      const newEmailViewState = getInitialSingleEmailState();
      state.emailViewHeader = newEmailViewState.emailViewHeader;
      state.emailReadableOriginal = newEmailViewState.emailReadableOriginal;
      state.emailViewDetails = newEmailViewState.emailViewDetails;

      getMailHeaders.reset();
      getMailDetails.reset();
    },

    setEmailHeaders(headers: ArchiveHeadersV0): void {
      state.emailViewHeader = cloneDeep(headers);
    },

    setEmailDetails(details: ExtraMailDetailsV0): void {
      state.emailViewDetails = cloneDeep(details); // TODO: worry about copying extra bad things in here?
      this.clearReadableOriginal();
    },

    setEmailViewIndex(index: number) {
      state.emailView_index = index;
    },

    // Search options
    setLimit(param: RowLimitOption): void {
      state.limit = param;
    },

    setTotal(param: number): void {
      state.total = param;
    },

    setSkiptoken(param: string | null): void {
      state.skiptoken = param;
    },

    setBacktoken(param: string | null): void {
      state.backtoken = param;
    },

    setReadableOriginal(text: string): void {
      state.emailReadableOriginal.content = text;
    },

    clearReadableOriginal(): void {
      state.emailReadableOriginal.content = null;
      state.emailReadableOriginal.isLoaded = false;
      state.emailReadableOriginal.isLoading = false;
    },

    // This will only peform an optimistic shallow update; it doesn't affect the backend
    afterDefrostEmail(
      uidl: string,
      { status, defrostedFrom, defrostedUntil, defrostDurationMs }: ArchiveStatusDetailV0,
    ): void {
      // Update in the list of emails returned from search results
      const index = state.emails.findIndex((e) => e.uidl === uidl);

      if (index > -1) {
        state.emails[index].status = status;

        if (defrostedFrom !== undefined) {
          state.emails[index].defrostedFrom = defrostedFrom;
        }
        if (defrostedUntil !== undefined) {
          state.emails[index].defrostedUntil = defrostedUntil;
        }
      }

      // Update it in the drawer details as well, if the UIDL matches
      if (state.emailViewHeader.uidl === uidl) {
        state.emailViewHeader.status = status;
        state.emailViewHeader.defrostDurationMs = defrostDurationMs;

        if (defrostedFrom !== undefined) {
          state.emailViewHeader.defrostedFrom = defrostedFrom;
        }
        if (defrostedUntil !== undefined) {
          state.emailViewHeader.defrostedUntil = defrostedUntil;
        }
      }
    },

    // This will only peform an optimistic shallow update; it doesn't affect the backend
    updateStatus(uidl: string, status: ArchiveStatusV0): void {
      // Update in the list of emails returned from search results
      const index = state.emails.findIndex((e) => e.uidl === uidl);

      if (index > -1) {
        state.emails[index].status = status;
      }

      // Update it in the drawer details as well, if the UIDL matches
      if (state.emailViewHeader.uidl === uidl) {
        state.emailViewHeader.status = status;
      }
    },
  };
});

export default useArchiveSearchStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useArchiveSearchStore, import.meta.hot));
}

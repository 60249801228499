import type { SearchPolicyDetailsV0, SearchPolicyInListV0, UserV0 } from '@/api';

import { cloneDeep } from 'lodash-es';

export type SearchPolicyDetailsWithUserInfo = SearchPolicyDetailsV0 & {
  users: UserV0[];
  users_loaded: boolean;
};

export interface SearchPoliciesState {
  policies: SearchPolicyInListV0[];

  // filtered list
  filteredPolicies: SearchPolicyInListV0[];
  isFiltered: boolean;

  // drawer view
  policyView: SearchPolicyDetailsWithUserInfo;
  policyView_loaded: boolean;

  isEditing: boolean;
}

const getInitialState = (): SearchPoliciesState => ({
  policies: [],

  // filtered list
  filteredPolicies: [],
  isFiltered: false,

  // drawer view
  policyView: {
    id: '',
    name: '',
    rules: [],
    userIds: [],
    createdAt: new Date(0),
    updatedAt: new Date(0),
    type: 'custom',

    // separately fetched list of full user data
    users: [],
    users_loaded: false,
  },
  policyView_loaded: false,
  isEditing: false,
});

export const useSearchPoliciesStore = defineStore('searchPolicies', () => {
  const state = reactive<SearchPoliciesState>(getInitialState());

  const getSearchPolicyById = computed(() => {
    return (id: string): SearchPolicyInListV0 | null => {
      const index: number = state.policies.findIndex((e) => e.id === id);
      if (index !== -1) {
        return state.policies[index];
      } else {
        return null;
      }
    };
  });

  // search logic
  const searchSearchPoliciesByName = computed(() => {
    const { formatSearchPolicyName } = useFormatters();

    return (term: string): SearchPolicyInListV0[] => {
      return state.policies.filter(
        (sp: SearchPolicyInListV0) =>
          formatSearchPolicyName(sp).toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) >= 0,
      );
    };
  });

  return {
    ...toRefs(state),

    getSearchPolicyById,
    searchSearchPoliciesByName,

    updatePoliciesListItem(param: SearchPolicyInListV0): void {
      const index: number = state.policies.findIndex((e) => e.id === param.id);
      if (index !== -1) {
        state.policies[index] = param;
      }
    },

    addPoliciesListItem(param: SearchPolicyInListV0): void {
      // This needs to start at index 2 because we always have
      // Allow All and Block All policies at the top of the list.
      state.policies.splice(2, 0, param);
    },

    deletePoliciesListItem(param: string): void {
      const index: number = state.policies.findIndex((e) => e.id === param);
      if (index > -1) {
        state.policies.splice(index, 1);
      }

      const filteredIndex: number = state.filteredPolicies.findIndex((e) => e.id === param);
      if (filteredIndex > -1) {
        state.filteredPolicies.splice(filteredIndex, 1);
      }
    },

    setSearchPolicies(param: SearchPolicyInListV0[]): void {
      state.policies = param;
      state.filteredPolicies = param;
    },

    // Drawer view

    setSearchPolicyView(param: SearchPolicyDetailsV0): void {
      // preserve users list if already filled
      state.policyView = {
        ...param,
        users: state.policyView.users,
        users_loaded: state.policyView.users_loaded,
      };
      state.policyView_loaded = true;
    },

    setSearchPolicyUsersById(users: UserV0[]): void {
      state.policyView.users = [...users];
      state.policyView.users_loaded = true;
    },

    clearSearchPolicyView() {
      state.policyView = getInitialState().policyView;
    },

    setIsEditing(param: boolean): void {
      state.isEditing = param;
    },

    // Filtering

    setIsFiltered(param: boolean): void {
      state.isFiltered = param;
    },

    setFilteredSearchPoliciesList(params: SearchPolicyInListV0[]): void {
      state.filteredPolicies = params;
    },

    resetFilteredSearchPoliciesList(): void {
      state.filteredPolicies = cloneDeep(state.policies);
    },
  };
});

export default useSearchPoliciesStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearchPoliciesStore, import.meta.hot));
}

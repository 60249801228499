/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RSAJWKV0
 */
export interface RSAJWKV0 {
    /**
     * 
     * @type {string}
     * @memberof RSAJWKV0
     */
    alg: string;
    /**
     * 
     * @type {string}
     * @memberof RSAJWKV0
     */
    e: string;
    /**
     * 
     * @type {string}
     * @memberof RSAJWKV0
     */
    kid: string;
    /**
     * 
     * @type {string}
     * @memberof RSAJWKV0
     */
    kty: RSAJWKV0KtyEnum;
    /**
     * 
     * @type {string}
     * @memberof RSAJWKV0
     */
    n: string;
    /**
     * 
     * @type {string}
     * @memberof RSAJWKV0
     */
    use: string;
}


/**
 * @export
 */
export const RSAJWKV0KtyEnum = {
    Rsa: 'RSA'
} as const;
export type RSAJWKV0KtyEnum = typeof RSAJWKV0KtyEnum[keyof typeof RSAJWKV0KtyEnum];


/**
 * Check if a given object implements the RSAJWKV0 interface.
 */
export function instanceOfRSAJWKV0(value: object): value is RSAJWKV0 {
    if (!('alg' in value) || value['alg'] === undefined) return false;
    if (!('e' in value) || value['e'] === undefined) return false;
    if (!('kid' in value) || value['kid'] === undefined) return false;
    if (!('kty' in value) || value['kty'] === undefined) return false;
    if (!('n' in value) || value['n'] === undefined) return false;
    if (!('use' in value) || value['use'] === undefined) return false;
    return true;
}

export function RSAJWKV0FromJSON(json: any): RSAJWKV0 {
    return RSAJWKV0FromJSONTyped(json, false);
}

export function RSAJWKV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): RSAJWKV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'alg': json['alg'],
        'e': json['e'],
        'kid': json['kid'],
        'kty': json['kty'],
        'n': json['n'],
        'use': json['use'],
    };
}

export function RSAJWKV0ToJSON(json: any): RSAJWKV0 {
    return RSAJWKV0ToJSONTyped(json, false);
}

export function RSAJWKV0ToJSONTyped(value?: RSAJWKV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'alg': value['alg'],
        'e': value['e'],
        'kid': value['kid'],
        'kty': value['kty'],
        'n': value['n'],
        'use': value['use'],
    };
}


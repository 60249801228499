<template>
  <RouterView />
</template>

<script setup lang="ts">
import '@fontsource-variable/noto-sans-jp'; // Supports weights 100-900
import '@/styles/global.scss';

const { tenant } = useStores('tenant');

onMounted(initLocale);

function initLocale() {
  // This will set the locale at the start of the UI session.
  // This step is necessary at load time to set the HTML lang attribute, vue-i18n starting default, etc.
  // This will pull from the user's browser, if necessary (either local storage or the browser's locale)
  tenant.setUILocale(tenant.locale);
}

// Keypress handling
// We include this here at the root component only and because we only want one keypress handler for the whole app.
// Attaching handlers from children components is done via `inject`/`provide`

const { watchKeypresses } = useKeypresses();
watchKeypresses();
</script>

<template>
  <Teleport to="#app" :disabled="!active">
    <Transition name="DialogHolder">
      <div
        v-if="active"
        v-bind="$attrs"
        ref="scrim"
        :inert="dialog.obscured(id)"
        class="DialogHolder"
        :data-testid="`${id}Holder`"
        :class="[
          `DialogHolder-priority_${priority}`,
          {
            'DialogHolder-withSidePanel-isVisible': sidePanel.isVisible,
            'DialogHolder-withSidePanel-inPosition': sidePanel.inPosition,
            'DialogHolder-withSidePanel-isActive': sidePanel.isActive,
          },
        ]"
        @click="handleScrimClick"
      >
        <div class="Separator" aria-hidden="true" />
        <article
          :id
          v-focus-trap="active"
          :data-testid="id"
          :aria-labelledby="titleId"
          role="dialog"
          class="Dialog"
          :class="[`Dialog-${size}`, `Dialog-priority_${priority}`]"
        >
          <header v-if="!!title || $slots.toolbar" class="DialogHeader">
            <h3 :id="titleId" class="DialogTitle">{{ title }}</h3>
            <slot name="toolbar" />
          </header>

          <p v-if="!!subtitle" class="DialogSubtitle">{{ subtitle }}</p>

          <section v-if="$slots.default" class="DialogMain">
            <slot />
          </section>

          <footer v-if="$slots.footer" class="DialogFooter">
            <slot name="footer" />
          </footer>
        </article>
        <div class="Separator" aria-hidden="true" />
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import type { DialogType } from '@/store/dialog';

type Props = {
  id: DialogType;
  persistent?: boolean;
  title: string; // Cannot be a TranslationKey because we show user content as a title for PDF preview dialog
  subtitle?: string;
  size?: 's' | 'm' | 'l' | 'xl';
  priority?: 'low' | 'medium' | 'high';

  /**
   * If provided, the BaseDialog component itself will not trigger the close action.
   * This is useful for specialized dialogs that wish to interrupt/handle the closing logic
   *  with special logic from this component's parent.
   */
  customCloseHandler?: () => boolean | Promise<boolean>;
};

const props = withDefaults(defineProps<Props>(), {
  subtitle: '',
  size: 'm',
  priority: 'medium',
  customCloseHandler: undefined,
});

defineSlots<{
  toolbar: [];
  /**
   * The main content of the dialog. Can handle multiple elements.
   * Each child will have 16px of margin between them.
   */
  default: [];
  footer: [];
}>();

defineOptions({
  inheritAttrs: false,
});

const { dialog, sidePanel } = useStores('dialog', 'sidePanel');

const active = computed(() => dialog.active(props.id));

const titleId = computed(() => `${props.id}-Title`);

async function closeHandler(): Promise<boolean> {
  if (props.customCloseHandler) {
    // Likely the custom close handler may not perform these checks,
    //  so let's perform them here explicitly first
    if (!dialog.disabled(props.id) && active.value) {
      return props.customCloseHandler();
    } else return false;
  } else {
    return dialog.attemptClose(props.id);
  }
}

// Escape keypress handling
const registerEscapeCallback = inject(KeypressHandlerInjectionKey);

const dialogId = computed(() => props.id);

registerEscapeCallback?.(dialogId, escapeKeyCallback);

function escapeKeyCallback() {
  if (!props.persistent) {
    closeHandler();
  }
}

// Scrim click handling

const scrim = ref<HTMLElement | null>(null);

function handleScrimClick(e: MouseEvent) {
  // IFF the click is on the scrim, and not on the dialog itself, and if this dialog is active
  if (e.target === scrim.value)
    if (!props.persistent && active.value)
      // close the dialog
      closeHandler();
}
</script>

<style scoped lang="scss">
.DialogHolder {
  position: fixed;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  overflow-y: auto;
  top: $global-header-height;
  left: 0;
  height: calc(100vh - $global-header-height);
  width: 100vw;
  background-color: black(38);
  max-width: 100vw;

  &-withSidePanel {
    &-isVisible:not(.DialogHolder-withSidePanel-inPosition),
    &-isVisible:not(.DialogHolder-withSidePanel-isActive) {
      @include setTransitionMedium(max-width);
    }

    &-isVisible.DialogHolder-withSidePanel-isActive {
      max-width: calc(100vw - $side-panel-width);
    }
  }

  &-priority {
    &_low {
      z-index: 100;
    }
    &_medium {
      z-index: 600;
    }
    &_high {
      top: 0;
      height: 100vh;
      z-index: 800;
    }
  }
}

/* Transition
* NOTE: Transition name must match the class name of the immediate children of the <Transition> component
*/
@include transitionFadein($name: DialogHolder);

.Separator {
  flex: 1 0 0;
}

.Dialog {
  box-sizing: border-box;
  background-color: white();
  padding: 32px;
  border-radius: 8px;
  z-index: -1;
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include elevationHigh;

  &-priority {
    &_low {
      z-index: 110;
    }
    &_medium {
      z-index: 610;
    }
    &_high {
      z-index: 810;
    }
  }

  // size
  &-s {
    max-width: $dialog_width_s;
    padding: 16px;
  }
  &-m {
    max-width: $dialog_width_m;
  }
  &-l {
    max-width: $dialog_width_l;
  }
  &-xl {
    max-width: $dialog_width_xl;
  }

  > :first-child {
    border-radius: 8px 8px 0 0;
  }

  > :last-child {
    border-radius: 0 0 8px 8px;
  }
}

.DialogHeader,
.DialogFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DialogHeader {
  height: 40px;
}

.DialogFooter {
  justify-content: flex-end;
}

.DialogMain {
  display: flex;
  flex-direction: column;
}

.DialogTitle {
  font-size: 20px;
  line-height: normal;
  color: black(87);
  margin: 0;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.DialogSubtitle {
  color: black(87);
  font-size: 14px;
  line-height: 1.2;
  margin: 0;
}
</style>

/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SearchPolicyRulesV0 } from './SearchPolicyRulesV0';
import {
    SearchPolicyRulesV0FromJSON,
    SearchPolicyRulesV0FromJSONTyped,
    SearchPolicyRulesV0ToJSON,
    SearchPolicyRulesV0ToJSONTyped,
} from './SearchPolicyRulesV0';

/**
 * Used when updating SearchPolicy.
 * @export
 * @interface SearchPolicyUpdateV0
 */
export interface SearchPolicyUpdateV0 {
    /**
     * 
     * @type {string}
     * @memberof SearchPolicyUpdateV0
     */
    name?: string | null;
    /**
     * 
     * @type {Array<SearchPolicyRulesV0>}
     * @memberof SearchPolicyUpdateV0
     */
    rules?: Array<SearchPolicyRulesV0> | null;
}

/**
 * Check if a given object implements the SearchPolicyUpdateV0 interface.
 */
export function instanceOfSearchPolicyUpdateV0(value: object): value is SearchPolicyUpdateV0 {
    return true;
}

export function SearchPolicyUpdateV0FromJSON(json: any): SearchPolicyUpdateV0 {
    return SearchPolicyUpdateV0FromJSONTyped(json, false);
}

export function SearchPolicyUpdateV0FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchPolicyUpdateV0 {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'rules': json['rules'] == null ? undefined : ((json['rules'] as Array<any>).map(SearchPolicyRulesV0FromJSON)),
    };
}

export function SearchPolicyUpdateV0ToJSON(json: any): SearchPolicyUpdateV0 {
    return SearchPolicyUpdateV0ToJSONTyped(json, false);
}

export function SearchPolicyUpdateV0ToJSONTyped(value?: SearchPolicyUpdateV0 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'rules': value['rules'] == null ? undefined : ((value['rules'] as Array<any>).map(SearchPolicyRulesV0ToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomSearchPolicyDetailsV0,
  ErrorResponsePayloadV0,
  SearchPoliciesV0,
  SearchPolicyCreateV0,
  SearchPolicyDetailsV0,
  SearchPolicyUpdateV0,
  UsersV0,
} from '../models/index';
import {
    CustomSearchPolicyDetailsV0FromJSON,
    CustomSearchPolicyDetailsV0ToJSON,
    ErrorResponsePayloadV0FromJSON,
    ErrorResponsePayloadV0ToJSON,
    SearchPoliciesV0FromJSON,
    SearchPoliciesV0ToJSON,
    SearchPolicyCreateV0FromJSON,
    SearchPolicyCreateV0ToJSON,
    SearchPolicyDetailsV0FromJSON,
    SearchPolicyDetailsV0ToJSON,
    SearchPolicyUpdateV0FromJSON,
    SearchPolicyUpdateV0ToJSON,
    UsersV0FromJSON,
    UsersV0ToJSON,
} from '../models/index';

export interface CreateSearchPolicyRequest {
    searchPolicyCreateV0: SearchPolicyCreateV0;
}

export interface DeleteSearchPolicyRequest {
    id: string;
}

export interface GetSearchPolicyRequest {
    id: string;
}

export interface GetSearchPolicyUsersRequest {
    id: string;
}

export interface UpdateSearchPolicyRequest {
    id: string;
    searchPolicyUpdateV0: SearchPolicyUpdateV0;
}

/**
 * 
 */
export class SearchPoliciesApi extends runtime.BaseAPI {

    /**
     * This will create a new search policy for the current user\'s domain.  The user requires the permission `search-policies:create` to be able to call this endpoint.  Error Codes:  - HTTP 409    - 1002 => a search policy with this name already exists.
     * Create a new search policy
     */
    async createSearchPolicyRaw(requestParameters: CreateSearchPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomSearchPolicyDetailsV0>> {
        if (requestParameters['searchPolicyCreateV0'] == null) {
            throw new runtime.RequiredError(
                'searchPolicyCreateV0',
                'Required parameter "searchPolicyCreateV0" was null or undefined when calling createSearchPolicy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/search_policies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchPolicyCreateV0ToJSON(requestParameters['searchPolicyCreateV0']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomSearchPolicyDetailsV0FromJSON(jsonValue));
    }

    /**
     * This will create a new search policy for the current user\'s domain.  The user requires the permission `search-policies:create` to be able to call this endpoint.  Error Codes:  - HTTP 409    - 1002 => a search policy with this name already exists.
     * Create a new search policy
     */
    async createSearchPolicy(requestParameters: CreateSearchPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomSearchPolicyDetailsV0> {
        const response = await this.createSearchPolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This will delete the search policy with the specified `id` the current user\'s domain.  The user requires the permission `search-policies:delete` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given search policy  - HTTP 409    - 1002 => the search policy is still being referenced by users
     * Delete search policy
     */
    async deleteSearchPolicyRaw(requestParameters: DeleteSearchPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteSearchPolicy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/search_policies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This will delete the search policy with the specified `id` the current user\'s domain.  The user requires the permission `search-policies:delete` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given search policy  - HTTP 409    - 1002 => the search policy is still being referenced by users
     * Delete search policy
     */
    async deleteSearchPolicy(requestParameters: DeleteSearchPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSearchPolicyRaw(requestParameters, initOverrides);
    }

    /**
     * This will return all search policies defined for the current user\'s domain.  The user requires the permission `search-policies:get-all` to be able to call this endpoint.  Error Codes:  - N/A
     * Get a list of all search policies
     */
    async getSearchPoliciesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchPoliciesV0>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/search_policies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchPoliciesV0FromJSON(jsonValue));
    }

    /**
     * This will return all search policies defined for the current user\'s domain.  The user requires the permission `search-policies:get-all` to be able to call this endpoint.  Error Codes:  - N/A
     * Get a list of all search policies
     */
    async getSearchPolicies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchPoliciesV0> {
        const response = await this.getSearchPoliciesRaw(initOverrides);
        return await response.value();
    }

    /**
     * This will return the search policy by its `id` for the current user\'s domain.  The user requires the permission `search-policies:get` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given search policy
     * Get a search policy by id
     */
    async getSearchPolicyRaw(requestParameters: GetSearchPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchPolicyDetailsV0>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getSearchPolicy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/search_policies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchPolicyDetailsV0FromJSON(jsonValue));
    }

    /**
     * This will return the search policy by its `id` for the current user\'s domain.  The user requires the permission `search-policies:get` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given search policy
     * Get a search policy by id
     */
    async getSearchPolicy(requestParameters: GetSearchPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchPolicyDetailsV0> {
        const response = await this.getSearchPolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This will return all the users for a specific search policy identified by its `id` for the current user\'s domain.  The user requires the permission `search-policies:get` and `users:get`  to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given search policy
     * Get all users attached to a search policy
     */
    async getSearchPolicyUsersRaw(requestParameters: GetSearchPolicyUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsersV0>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getSearchPolicyUsers().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/search_policies/{id}/users`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersV0FromJSON(jsonValue));
    }

    /**
     * This will return all the users for a specific search policy identified by its `id` for the current user\'s domain.  The user requires the permission `search-policies:get` and `users:get`  to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given search policy
     * Get all users attached to a search policy
     */
    async getSearchPolicyUsers(requestParameters: GetSearchPolicyUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsersV0> {
        const response = await this.getSearchPolicyUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This will update the search policy by its `id` for the current user\'s domain.  The user requires the permission `search-policies:update` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given search policy  - HTTP 409    - 1002 => a search policy with this name already exists.  - HTTP 422    - 1002 => invalid update values for this search policy
     * Update some fields on the search policy
     */
    async updateSearchPolicyRaw(requestParameters: UpdateSearchPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomSearchPolicyDetailsV0>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateSearchPolicy().'
            );
        }

        if (requestParameters['searchPolicyUpdateV0'] == null) {
            throw new runtime.RequiredError(
                'searchPolicyUpdateV0',
                'Required parameter "searchPolicyUpdateV0" was null or undefined when calling updateSearchPolicy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/search_policies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SearchPolicyUpdateV0ToJSON(requestParameters['searchPolicyUpdateV0']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomSearchPolicyDetailsV0FromJSON(jsonValue));
    }

    /**
     * This will update the search policy by its `id` for the current user\'s domain.  The user requires the permission `search-policies:update` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given search policy  - HTTP 409    - 1002 => a search policy with this name already exists.  - HTTP 422    - 1002 => invalid update values for this search policy
     * Update some fields on the search policy
     */
    async updateSearchPolicy(requestParameters: UpdateSearchPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomSearchPolicyDetailsV0> {
        const response = await this.updateSearchPolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

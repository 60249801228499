<template>
  <p class="HelpMessage" :role="type === 'error' ? 'alert' : 'note'" :class="`HelpMessage-${type}`">
    <slot />
  </p>
</template>

<script setup lang="ts">
export type Props = {
  type?: 'info' | 'error';
};

withDefaults(defineProps<Props>(), {
  type: 'error',
  errorMessage: '',
});

defineSlots<{
  default(): void;
}>();
</script>

<style scoped lang="scss">
.HelpMessage {
  margin: 0;
  padding: 4px;
  padding-bottom: 0;
  font-size: 12px;
  line-height: 1.2;
  color: red(400);
  box-sizing: border-box;
  text-align: left;

  &-info {
    color: black(60);
  }
}
</style>

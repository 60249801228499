import type { NobitaPrivilegedRouteName, NobitaRouteName } from './routes';
import type { UserInfoV0 } from '@/api';
import type { TenantStore } from '@/store/tenant';
import type { Router, RouteRecordRaw } from 'vue-router';

import { orderedPageRouteMap, Route_NoPermissions } from './routes';

export function rejectHandler(error_message: string) {
  // We couldn't load the Vue module for some reason.
  // This might mean that the deployed modules have changed due to a redeployment.
  //    See: https://github.com/HENNGE/nobita2-ui/issues/823

  const full_error = `Could not load Vue module! Forcing refresh.\n${error_message}`;

  logger.error(full_error);

  // To refresh the user's session, force a reload.
  window.location.reload();
}

/**
 * Wrapper function that ensures that failed dynamic Vue component imports are handled gracefully
 * with a hard refresh of the user's session.
 * @param dynamic_importer An async function that returns a Promise of a Vue component.
 * @returns The result of calling the param function, or void if an error occurs.
 */
export function dynamic<T>(dynamic_importer: () => Promise<T>): () => Promise<T> | void {
  return function () {
    try {
      return dynamic_importer();
    } catch (e) {
      rejectHandler(String(e));
    }
  };
}

export function addBeforeEachRouterGuard(router: Router) {
  // Check if valid route is being requested based on user's permissions
  router.beforeEach(async (to) => {
    const { tenant } = useStores('tenant');
    const { stored_accessToken, stored_currentRoute } = useNobitaLocalStorage();

    const haveAccessToken = !!stored_accessToken.value;

    logger.debug(`Checking access token: route=${String(to.name)}, haveToken=${haveAccessToken}`);

    if (to.name === 'Authorisation') {
      // Authorisation page - don't worry about any existing tokens, just validate what we got after being redirected
      return true;
    }

    // In cases where we don't have an access token
    if (!stored_accessToken.value) {
      if (to.name === 'Login') {
        return true;
      } else {
        // Any other page (requires authentication)

        // Record this attempt to navigate to a logged-in page as the most current route
        stored_currentRoute.value = to.fullPath;

        // The user doesn't have an access token, so redirect them to the login page.
        logger.info('No access token found; returning to Login screen to get one.');
        return { name: 'Login' };
      }
    }
    // From here on we know we have an access token

    // If we have not loaded the user's settings yet, do so.
    let addedNewRoutesThisTime = false;
    if (!tenant.isLoggedIn) {
      try {
        logger.info('Tenant information has not yet been fetched. Fetching...');
        await initUserAccess(router, stored_accessToken.value);
        addPrivilegedRoutes(router, tenant.authorizedPages);
        addedNewRoutesThisTime = true;
      } catch (_) {
        logger.error('An error occurred trying to fetch user domain and privileged routes');
        return { name: 'Login' };
      }
    }

    // Now that we dynamically added the user's privileged routes,
    // see if the current route request matches any of them
    const resolvedTo = router.resolve(to);

    if (resolvedTo.name === 'Home' || resolvedTo.name === 'Login') {
      // match the check for `Login` above, but for the case where the user is logged in.
      if (privilegedRoutes.length) {
        // Redirect the root path to the first allowed route for this user
        return { name: privilegedRoutes[0] };
      } else {
        return { name: 'NoPermissions' };
      }
    } else if (
      [...Object.keys(orderedPageRouteMap), '404', 'NoPermissions'].includes(
        String(resolvedTo.name),
      )
    ) {
      // We have a match to a legitimate route
      // We must trigger at least one redirection after new routes were added
      // Otherwise, return just 'true' to prevent infinite recursion
      stored_currentRoute.value = to.fullPath;
      return addedNewRoutesThisTime ? to.fullPath : true;
    } else {
      // We didn't match a route that we know about.
      // This means the route truly is invalid (e.g. `/foobar`).
      // In this case, show the 404 "Page Not Found" error message.
      return { name: '404' };
    }
  });
}

// This will be populated with the route names that the user is privileged for
const privilegedRoutes: NobitaRouteName[] = [];

function addPrivilegedRoutes(router: Router, authorizedPages: TenantStore['authorizedPages']) {
  for (const p of Object.keys(orderedPageRouteMap)) {
    const page = p as NobitaPrivilegedRouteName;
    const route = orderedPageRouteMap[page];
    if (authorizedPages[page]) {
      // Add the route as it is as a child of the main Home route
      router.addRoute('Home', route);
      privilegedRoutes.push(page);
    } else {
      // Make a "Permission Required" version of the usual route
      const unauthorizedRoute: RouteRecordRaw = {
        ...Route_NoPermissions,
        path: route.path,
        name: route.name,
      };
      // Add the altered route as a child of the main Home route
      router.addRoute('Home', unauthorizedRoute);
    }
  }

  if (privilegedRoutes.length === 0) {
    // If the user has no privileged routes, add a special route to show permission required error at root
    router.addRoute('Home', Route_NoPermissions);
  }
}

async function initUserAccess(router: Router, accessToken: string) {
  const { tenant, snackbars } = useStores('tenant', 'snackbars');
  const { stored_accessToken, stored_domainName } = useNobitaLocalStorage();
  const { getUserInfo } = useAuthHelper();

  try {
    const userInfo: UserInfoV0 = await getUserInfo(accessToken);

    // set domain-name in localStorage
    stored_domainName.value = userInfo.loginDomainName;

    await tenant.setTenant(userInfo);

    const expiryDate = new Date(tenant.expiry).getTime();

    setTimeout(() => {
      // clear access token in storage
      stored_accessToken.value = null;

      tenant.setLoggedInStatus(false);

      snackbars.addSnackbar({
        status: 'warning',
        isClosable: false,
        hasAction: true,
        content: 'response.authExpired',
        actionText: 'actionText.reload',
        duration: 0,
        callback: () => {
          routeToLogin(router, true);
        },
      });
    }, expiryDate - Date.now());
  } catch (_) {
    logger.error('Error! Session timeout or other error');
    // clear access token in storage
    stored_accessToken.value = null;

    tenant.setLoggedInStatus(false);
    routeToLogin(router, false);
  }
}

export function routeToLogin(router: Router, autoReLogin: boolean): void {
  const { stored_loginHint } = useNobitaLocalStorage();
  if (autoReLogin === false) stored_loginHint.value = null;

  // use the router so we include base path for the preview env.
  const login = router.resolve({ name: 'Login' });

  // using the vue router and redirecting to /login is currently broken and causes
  // an explosion and the app is dead. In the interest of allowing logout again, this
  // will hard redirect us to /login after the ajax request to kill the session token
  // completes.
  document.location.href = router.options.history.base + login.fullPath;
}

/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponsePayloadV0,
  UserCreateV0,
  UserUpdateV0,
  UserV0,
  UsersV0,
} from '../models/index';
import {
    ErrorResponsePayloadV0FromJSON,
    ErrorResponsePayloadV0ToJSON,
    UserCreateV0FromJSON,
    UserCreateV0ToJSON,
    UserUpdateV0FromJSON,
    UserUpdateV0ToJSON,
    UserV0FromJSON,
    UserV0ToJSON,
    UsersV0FromJSON,
    UsersV0ToJSON,
} from '../models/index';

export interface CreateUserRequest {
    userCreateV0: UserCreateV0;
}

export interface DeleteUserRequest {
    id: string;
}

export interface GetUserRequest {
    id: string;
}

export interface UpdateUserRequest {
    id: string;
    userUpdateV0: UserUpdateV0;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * This will create a new user for the current user\'s domain.  The user requires the permission `users:create` to be able to call this endpoint.  Error Codes:  - HTTP 409    - 1002 => a user already exists with this email.  - HTTP 422    - 6000 => the search policy specified does not exist.
     * Create a new user
     */
    async createUserRaw(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserV0>> {
        if (requestParameters['userCreateV0'] == null) {
            throw new runtime.RequiredError(
                'userCreateV0',
                'Required parameter "userCreateV0" was null or undefined when calling createUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserCreateV0ToJSON(requestParameters['userCreateV0']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserV0FromJSON(jsonValue));
    }

    /**
     * This will create a new user for the current user\'s domain.  The user requires the permission `users:create` to be able to call this endpoint.  Error Codes:  - HTTP 409    - 1002 => a user already exists with this email.  - HTTP 422    - 6000 => the search policy specified does not exist.
     * Create a new user
     */
    async createUser(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserV0> {
        const response = await this.createUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This will delete the user from the current user\'s domain.  The user requires the permission `users:delete` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given user
     * Delete a user
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This will delete the user from the current user\'s domain.  The user requires the permission `users:delete` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given user
     * Delete a user
     */
    async deleteUser(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserRaw(requestParameters, initOverrides);
    }

    /**
     * This will return the user from the current user\'s domain.  The user requires the permission `users:get` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given user
     * Get a user by id
     */
    async getUserRaw(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserV0>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserV0FromJSON(jsonValue));
    }

    /**
     * This will return the user from the current user\'s domain.  The user requires the permission `users:get` to be able to call this endpoint.  Error Codes:  - HTTP 403    - 1102 => the user does not have access to the given user
     * Get a user by id
     */
    async getUser(requestParameters: GetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserV0> {
        const response = await this.getUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This will return all users that belong to the current user\'s domain.  The user requires the permission `users:get-all` to be able to call this endpoint.  Error Codes:  - N/A
     * Get a list of all users
     */
    async getUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsersV0>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersV0FromJSON(jsonValue));
    }

    /**
     * This will return all users that belong to the current user\'s domain.  The user requires the permission `users:get-all` to be able to call this endpoint.  Error Codes:  - N/A
     * Get a list of all users
     */
    async getUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsersV0> {
        const response = await this.getUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     * This will update the user in the current user\'s domain.  The user requires the permission `users:update` to be able to call this endpoint. If the user being updated is the same as the authenticated user, the user additionally requires the permission users:update-self to call this endpoint  Error Codes:  - HTTP 403    - 1102 => the user does not have the required permissions to call this endpoint. - HTTP 422    - 6000 => the search policy specified does not exist.    - 1002 => nothing to update.
     * Update some fields on the user
     */
    async updateUserRaw(requestParameters: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserV0>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateUser().'
            );
        }

        if (requestParameters['userUpdateV0'] == null) {
            throw new runtime.RequiredError(
                'userUpdateV0',
                'Required parameter "userUpdateV0" was null or undefined when calling updateUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v0/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateV0ToJSON(requestParameters['userUpdateV0']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserV0FromJSON(jsonValue));
    }

    /**
     * This will update the user in the current user\'s domain.  The user requires the permission `users:update` to be able to call this endpoint. If the user being updated is the same as the authenticated user, the user additionally requires the permission users:update-self to call this endpoint  Error Codes:  - HTTP 403    - 1102 => the user does not have the required permissions to call this endpoint. - HTTP 422    - 6000 => the search policy specified does not exist.    - 1002 => nothing to update.
     * Update some fields on the user
     */
    async updateUser(requestParameters: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserV0> {
        const response = await this.updateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

export enum FEEDBACK_MOODS {
  veryAngry = 'feedback-very-angry',
  angry = 'feedback-angry',
  happy = 'feedback-happy',
  veryHappy = 'feedback-very-happy',
}

export enum FEEDBACK_MOOD_VALUE {
  veryAngry = 1,
  angry = 2,
  happy = 3,
  veryHappy = 4,
}

export enum FEEDBACK_FEATURES {
  search = 'search',
  download = 'download',
  login = 'login',
  others = 'others',
}

export class Feedback {
  tenant: string;
  email: string;
  score: FEEDBACK_MOOD_VALUE | null;
  category: FEEDBACK_FEATURES | null;
  comment?: string;

  constructor() {
    this.tenant = '';
    this.email = '';
    this.score = null;
    this.category = null;
  }
}

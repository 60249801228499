<template>
  <p
    class="CharactersCounter"
    :class="{ 'CharactersCounter-error': value && value.length > maxlength }"
  >
    {{ `${value ? value.length : 0} / ${maxlength} ${$t('validation.characters')}` }}
  </p>
</template>

<script setup lang="ts">
export type Props = {
  value?: string | null;
  maxlength: number;
};

withDefaults(defineProps<Props>(), {
  value: '',
});
</script>

<style scoped lang="scss">
.CharactersCounter {
  color: black(60);
  font-size: 12px;
  line-height: 1.2;
  margin: 0;
  padding: 4px 4px 0;

  @include setTransition(color);

  &-error {
    color: red(600);
  }
}
</style>

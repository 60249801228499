import {
  AccessPoliciesApi,
  ArchiveApi,
  AuthApi,
  DiscoveryApi,
  DomainApi,
  OperationLogsApi,
  SearchPoliciesApi,
  StatsApi,
  UsersApi,
} from '@/api/apis';
import { Configuration } from '@/api/runtime';

const apiStoreSetup = () => {
  const config = computed(() => {
    const tenant = useTenantStore();
    return new Configuration(tenant.apiConfigParameters);
  });

  const accessPoliciesApi = computed(() => new AccessPoliciesApi(config.value)); // prettier-ignore
  const archiveApi        = computed(() => new ArchiveApi       (config.value)); // prettier-ignore
  const authApi           = computed(() => new AuthApi          (config.value)); // prettier-ignore
  const discoveryApi      = computed(() => new DiscoveryApi     (config.value)); // prettier-ignore
  const domainApi         = computed(() => new DomainApi        (config.value)); // prettier-ignore
  const operationLogsApi  = computed(() => new OperationLogsApi (config.value)); // prettier-ignore
  const searchPoliciesApi = computed(() => new SearchPoliciesApi(config.value)); // prettier-ignore
  const statsApi          = computed(() => new StatsApi         (config.value)); // prettier-ignore
  const usersApi          = computed(() => new UsersApi         (config.value)); // prettier-ignore

  const allApis = {
    accessPoliciesApi,
    archiveApi,
    authApi,
    discoveryApi,
    domainApi,
    operationLogsApi,
    searchPoliciesApi,
    statsApi,
    usersApi,
  };

  return allApis;
};

// Types

export type ApiStore = ReturnType<typeof useApiStore>;

export type ApiModuleName = keyof ReturnType<typeof apiStoreSetup>;

export type ApiModule = ApiStore[ApiModuleName];

// Store

export const useApiStore = defineStore('api', apiStoreSetup);

export default useApiStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useApiStore, import.meta.hot));
}

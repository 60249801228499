import type { Directive } from 'vue';

import * as focusTrap from 'focus-trap';

const VUE_FOCUS_TRAP_SYMBOL = Symbol();

interface VueFocusTrapHTMLElement extends HTMLElement {
  [VUE_FOCUS_TRAP_SYMBOL]: focusTrap.FocusTrap;
}

export const vFocusTrap: Directive<VueFocusTrapHTMLElement, boolean> = {
  mounted(el) {
    el[VUE_FOCUS_TRAP_SYMBOL] = focusTrap.createFocusTrap(el, {
      clickOutsideDeactivates: true,
      initialFocus: false,
      fallbackFocus: el,
    });
  },
  async updated(el, binding) {
    if (binding.value !== binding.oldValue) {
      if (binding.value) {
        await nextTick();
        el[VUE_FOCUS_TRAP_SYMBOL].activate();
      } else {
        el[VUE_FOCUS_TRAP_SYMBOL].deactivate();
      }
    }
  },
  beforeUnmount(el) {
    el[VUE_FOCUS_TRAP_SYMBOL].deactivate();
  },
};

export default vFocusTrap;
